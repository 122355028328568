import './services.location';
app.controller('OrdersController', [
  '$scope',
  '$http',
  'locationService',
  function ($scope, $http, locationService) {
    $scope.orders = [];
    $scope.state = {
      loadingItems: true,
    };

    $scope.pagination = {
      currentPage: 1,
      total: 0,
      itemsPerPage: 24,
    };

    $scope.loadItems = function (page) {
      $scope.state.loadingItems = true;

      $http({
        method: 'GET',
        url: '/api/orders?page=' + page,
      }).then(function (res) {
        var data = res.data;
        $scope.state.loadingItems = false;
        $scope.pagination.currentPage = page;
        $scope.orders = data.data.items;
        $scope.pagination.total = data.data.total;
      });
    };

    if (parseInt(locationService.getQueryParams().page)) {
      $scope.pagination.currentPage = parseInt(
        locationService.getQueryParams().page,
      );
    }

    $scope.loadItems($scope.pagination.currentPage);

    $scope.onPageChanged = function (page) {
      window.location = window.location.origin + '/orders?page=' + page;
    };
  },
]);
