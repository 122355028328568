app.service('taxService', [
  function () {
    this.toPercent = function (value) {
      return value ? parseFloat((value * 100).toFixed(5)) : 0;
    };

    this.getTaxRateText = function (data) {
      if (!data.min && !data.max) {
        return null;
      }
      if (data.min === data.max) {
        return this.toPercent(data.min) + '%';
      }
      return this.toPercent(data.min) + '% ~ ' + this.toPercent(data.max) + '%';
    };
  },
]);
