import './service.setting';
import './factorys.intl-tel-class';
import './service.intl-tel-input';
import './constants.credit-card';
import './services.recaptcha';
import './services.user';
import './services.sms.subscription';
import './services.benchat';
import './constants.custom-validator';
import './services.pnotify';
import './services.mobile-phone';
import './services.feature';
import './services.merchant';
import {
  cloneDeep,
  flow,
  cond,
  stubTrue,
  intersection,
  isEmpty,
} from 'lodash-es';
import {
  SUPPORTED_COUNTRIES_ALL,
  ALL_SUPPORTED_COUNTRIES_STRING,
  GENERAL_FIELD_TYPE,
  BOOLEAN_STATUS,
} from '../../constants/userSetting';
import { PREFERRED_COUNTRIES } from '../../utils/intlTel';
import { onTabActive } from '../features/member-center';
import {
  getFilteredBirthday,
  getJsMonth,
  validateBirthday,
  getFormattedBirthday,
} from '../../utils/birthday';
import { BIRTHDAY_ERROR, BIRTHDAY_FORMAT } from '../constants/user';
import { QUOTA_LIMIT_ERROR_TYPE } from '../../shop/constants/quota_limit';

const REWARD_ON_GENERAL_FIELDS = {
  name: 'users.membership.member_info.name',
  email: 'users.membership.member_info.email',
  mobile_phone: 'users.membership.member_info.mobile',
  gender: 'users.membership.member_info.gender',
  birthday: 'users.membership.member_info.birthday',
};

app.controller('UsersEditController', [
  '$rootScope',
  '$scope',
  '$http',
  'merchantService',
  '$filter',
  'mainConfig',
  'featureService',
  'mobilePhoneService',
  'pnotifyService',
  '$uibModal',
  'imageServiceEndpoint',
  'customValidator',
  'benchatService',
  'smsSubscriptionService',
  'userService',
  'staticResourceHost',
  'RecaptchaService',
  'slFeatureService',
  '$element',
  'shopline_payment_instrument_polling_endpoint',
  'merchant_has_connected_to_whatsapp',
  'creditCard',
  'intlTelInputService',
  'IntlTelClass',
  'settingService',
  function (
    $rootScope,
    $scope,
    $http,
    merchantService,
    $filter,
    mainConfig,
    featureService,
    mobilePhoneService,
    pnotifyService,
    $uibModal,
    imageServiceEndpoint,
    customValidator,
    benchatService,
    smsSubscriptionService,
    userService,
    staticResourceHost,
    RecaptchaService,
    slFeatureService,
    $element,
    shopline_payment_instrument_polling_endpoint,
    merchant_has_connected_to_whatsapp,
    creditCard,
    intlTelInputService,
    IntlTelClass,
    settingService,
  ) {
    $scope.BIRTHDAY_ERROR = BIRTHDAY_ERROR;
    $scope.BIRTHDAY_FORMAT = BIRTHDAY_FORMAT;

    $scope.hasCountryCode = slFeatureService.hasFeature('country_code');

    $scope.enabledFeature = {
      whatsAppAcceptMarketing:
        slFeatureService.hasFeature('whatsapp_accept_marketing') &&
        merchant_has_connected_to_whatsapp,
    };
    $scope.imageServiceEndpoint = imageServiceEndpoint;

    $scope.user_setting = settingService.getUserSettingFromMainConfig();

    $scope.shouldMobilePhoneConfirmed = () =>
      $scope.user_setting?.sms_verification?.status;

    // This is for SMS only
    $scope.sms = { isChecked: false };
    $scope.benchatSubscriptions = [];
    $scope.listOfLayer1Map = {};
    $scope.listOfLayer2Map = {};
    $scope.listOfLayer3Map = {};

    /**
     * TODO: move following function to service
     * @return {string[] | undefined} - Array of ISO 3316-2 2-digits, lower case for specific countries, undefined for all countries
     */
    function getMobilePhoneCountriesFromUserSettings(userSettings) {
      const countries =
        userSettings?.sms_verification?.supported_countries ??
        SUPPORTED_COUNTRIES_ALL;
      if (countries.length === 0) {
        return [mainConfig.merchantData.base_country_code];
      }
      if (countries.includes(ALL_SUPPORTED_COUNTRIES_STRING)) {
        // In intl-tel-input, onlyCountries in undefined is "all countries"
        return undefined;
      }
      return countries.reduce((prev, code) => {
        if (typeof code === 'string') {
          const lowerCaseCode = code.toLowerCase();
          prev.push(lowerCaseCode);
        }
        return prev;
      }, []);
    }

    /** @type {string[] | undefined} */
    const allowedCountryCallingCodes = getMobilePhoneCountriesFromUserSettings(
      $scope.user_setting,
    );

    /**
     * TODO: move following function to service
     */
    function getMobilePhoneDefaultCountry() {
      const preferredCountries = PREFERRED_COUNTRIES;
      const onlyCountries = allowedCountryCallingCodes;
      const requestCountryCode = mainConfig.requestCountry.toLowerCase();

      if (
        !Array.isArray(onlyCountries) ||
        onlyCountries.includes(requestCountryCode)
      ) {
        return requestCountryCode;
      }

      const selectedPreferredCountries = intersection(
        preferredCountries,
        onlyCountries,
      );

      if (selectedPreferredCountries.length > 0) {
        return selectedPreferredCountries[0];
      }
      return onlyCountries.reduce(
        (prev, item) => (prev.localeCompare(item) < 0 ? prev : item),
        onlyCountries[0],
      );
    }

    var timeZoneOffset = new Date().getTimezoneOffset() / -60;

    $scope.subscription_tags = {
      'marketing.news': false,
    };

    $scope.phoneNumberRegex = /^\+?[()\-#.\s\d]+$/;
    $scope.emailRegex = customValidator.EMAIL_REGEX;

    $scope.mobileForm = {
      mobilePhone: '',
      countryCallingCode: '',
      mobileModified: false,
      mobileInvalid: false,
      countryCallingCodeNotAllowed: false,
      mobileInvalidBeforeSubmission: false,
      mobileInvalidAfterSubmission: false,
      mobileSuccessMessages: [],
      mobileErrorMessages: [],
    };

    $scope.birthdayForm = {
      year: null,
      month: null,
      date: null,
      error: null,
    };

    const phoneInstanceMap = {
      mobile: null,
      phone: null,
      addressPhones: [],
    };

    const checkMobileIsConfirmed = () => {
      // if setting is OFF
      if (!$scope.shouldMobilePhoneConfirmed()) {
        return true;
      }
      const originalMobilePhone = $scope.user.mobile_phone || '';
      const currentMobilePhone = $scope.mobileForm.mobilePhone || '';
      return originalMobilePhone === currentMobilePhone;
    };

    const fallbackPhoneCountryCode = (originalData) => {
      const cloneData = cloneDeep(originalData);
      const currentPhoneCountryCode =
        phoneInstanceMap.phone?.getCountry?.('number');
      // if user.phone is empty, then user.phone_country_code will be set to null during submission
      if (cloneData.phone_country_code === null && currentPhoneCountryCode) {
        cloneData.phone_country_code = currentPhoneCountryCode;
      }
      return cloneData;
    };

    const fallbackMobilePhone = (originalData) => {
      const cloneData = cloneDeep(originalData);
      const previousMobilePhone = cloneData.mobile_phone || '';
      const previousCountryCallingCode =
        cloneData.country_calling_code ||
        intlTelInputService.toDialCode(mainConfig.requestCountry.toLowerCase());
      const currentMobilePhone = $scope.mobileForm.mobilePhone || '';
      // mobile phone confirmed state has changed
      if (
        cloneData.is_mobile_phone_confirmed !==
          $scope.user.is_mobile_phone_confirmed &&
        currentMobilePhone !== previousMobilePhone
      ) {
        cloneData.is_mobile_phone_confirmed = false;
      }
      // original mobile phone has value and current is empty
      if (currentMobilePhone === '' && previousMobilePhone) {
        phoneInstanceMap.mobile.setCountry(
          'number',
          previousCountryCallingCode,
        );
        phoneInstanceMap.mobile.setPhone('complete', previousMobilePhone);
      }
      if (!$scope.mobileForm.countryCallingCode) {
        phoneInstanceMap.mobile.setCountry(
          'number',
          previousCountryCallingCode,
        );
      }
      return cloneData;
    };

    const forceSetMobileFormFromResponse = (userData) => {
      const { mobile_phone, country_calling_code } = userData;
      phoneInstanceMap.mobile.setCountry(
        'number',
        country_calling_code ||
          intlTelInputService.toDialCode(getMobilePhoneDefaultCountry()),
      );
      phoneInstanceMap.mobile.setPhone('complete', mobile_phone);
      return userData;
    };

    $scope.hasMobilePhone = () =>
      Boolean(
        phoneInstanceMap.mobile?.getPhone('national') ??
          $scope.mobileForm.mobilePhone,
      );

    $scope.hasConfirmedMobilePhone = () =>
      $scope.user?.mobile_phone && $scope.user?.is_mobile_phone_confirmed;

    $scope.updateMobileInvalid = () => {
      $scope.mobileForm.mobileInvalid = !$scope.verifyMobileFormat();
      $scope.user.is_mobile_phone_confirmed = false;
      $scope.mobileForm.mobileModified = true;
      $scope.mobileForm.mobileErrorMessages = [];
      $scope.mobileForm.countryCallingCodeNotAllowed = false;
      $scope.mobileForm.mobileInvalidBeforeSubmission = false;
      $scope.mobileForm.mobileInvalidAfterSubmission = false;
    };

    $scope.shouldShowFillPhoneButton = () => {
      const hasUserPhone = Boolean(
        phoneInstanceMap.phone?.getPhone('national') ?? $scope.user?.phone,
      );
      return !$scope.hasMobilePhone() && hasUserPhone;
    };

    $scope.$on('mobile-step-change', function (event, step) {
      $scope.mobileSignInStep = step;
    });

    const initBirthday = () => {
      const {
        year: userBirthYear,
        month: userBirthMonth,
        date: userBirthDate,
      } = getFilteredBirthday({
        birthdayFormat: $scope.user_setting.birthday_format,
        year: $scope.user.birth_year,
        month: $scope.user.birth_month,
        date: $scope.user.birth_day,
      });

      $scope.birthdayForm.year = userBirthYear;
      $scope.birthdayForm.month = userBirthMonth;
      $scope.birthdayForm.date = userBirthDate;
      const { birthday_format: birthdayFormat, minimum_age_limit: minAge } =
        $scope.user_setting;
      $scope.hasValidBirthday =
        validateBirthday({
          birthdayFormat,
          year: userBirthYear,
          month: getJsMonth(userBirthMonth),
          date: userBirthDate,
          minAge,
        }) === null;
      $scope.birthdayString = getFormattedBirthday(
        userBirthYear,
        getJsMonth(userBirthMonth),
        userBirthDate,
      );
    };

    $scope.getTiers = function () {
      $http({
        method: 'GET',
        url: '/api/membership_tiers',
      }).then(function (res) {
        $scope.tiers = res.data;
      });
    };

    const waitForElement = (selector) => {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            observer.disconnect();
            resolve(document.querySelector(selector));
          }
        });

        observer.observe(document.querySelector('.MemberCenter'), {
          childList: true,
          subtree: true,
        });
      });
    };

    $scope.getUserInfo = function (action) {
      $scope.loadingItems = true;
      $http({
        method: 'GET',
        url: '/api/users/' + $rootScope.currentUser._id,
        params: { 'includes[]': ['membership_tier_gap', 'member_info_reward'] },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then(function (res) {
        $scope.loadingItems = false;
        $scope.user = res.data.data;
        $scope.originalEmail = $scope.user.email;
        $scope.userBackup = angular.copy($scope.user);
        $scope.memberInfoRewardInfo = res.data.data.member_info_reward;
        $scope.setSubscriptionTags();
        $scope.$broadcast('memberCenterUser.fetch', $scope.user);

        initBirthday();

        // test pipeline feat(controllers.users.edit.js): Do not use user's phones as mobile phone
        $scope.mobileForm.mobilePhone = $scope.user.mobile_phone || '';
        // mobileForm.countryCallingCode will be updated on init-mobile-number
        $scope.mobileForm.countryCallingCode = $scope.user.country_calling_code;
        // After the mobile phone validation, the mobile_phone and country_calling_code is also saved
        $scope.mobileForm.mobileModified = false;

        if (!slFeatureService.hasFeature('modularize_address_format')) {
          $scope.user.delivery_addresses.forEach(function (addr) {
            if ($scope.shouldUseOmsAddress(addr.country) && !addr.logistic_code)
              addr.state = null;
            addr.states = undefined;
            $scope.fetchStateData(addr);
          });
        }

        if (action == 'sms_code_success') {
          $scope.mobileForm.mobileSuccessMessages = [
            $filter('translate')('membership.profile.mobile.number.verified'),
          ];
        }

        waitForElement('[data-toggle="popover"]').then((element) => {
          angular.element(element).popover();
        });

        // get benchat subscriptions info
        benchatService
          .getSubscriptions($scope.user._id)
          .then(function (result) {
            return ($scope.benchatSubscriptions = result.data.subscriptions);
          });
      });
    };

    $scope.getUserInfo();
    $scope.getTiers();
    $scope.setSubscriptionTags = function () {
      angular.forEach($scope.subscription_tags, function (value, key) {
        if ($scope.user.subscribed_email_types.indexOf(key) >= 0) {
          $scope.subscription_tags[key] = true;
        } else {
          $scope.subscription_tags[key] = false;
        }
      });
    };
    $scope.setUserSubscriptionTags = function () {
      angular.forEach($scope.subscription_tags, function (value, key) {
        if (value) {
          if ($scope.user.subscribed_email_types.indexOf(key) < 0) {
            $scope.user.subscribed_email_types.push(key);
          }
        } else {
          if ($scope.user.subscribed_email_types.indexOf(key) >= 0) {
            $scope.user.subscribed_email_types =
              $scope.user.subscribed_email_types.filter(function (type) {
                return type !== key;
              });
          }
        }
      });
    };

    $scope.successMessages = [];
    $scope.errorMessages = [];
    $scope.sendVerificationEmailreachedRateLimit = false;

    $http({
      method: 'GET',
      url: '/api/merchants/' + merchantService.merchantId + '/countries',
    }).then(function (res) {
      $scope.countries = res.data;
    });

    $scope.onDismissMessages = function (type, index) {
      if (type == 'error') {
        $scope.errorMessages.splice(index, 1);
      } else if (type == 'success') {
        $scope.successMessages.splice(index, 1);
      }
    };

    $scope.onDismissMobileMessages = function (type, index) {
      if (type == 'error') {
        $scope.mobileForm.mobileErrorMessages.splice(index, 1);
      } else if (type == 'success') {
        $scope.mobileForm.mobileSuccessMessages.splice(index, 1);
      }
    };

    $scope.onCancelEdit = function () {
      $scope.userForm.submitting = true;
      $scope.user = angular.copy($scope.userBackup);
      initBirthday();
      $scope.setSubscriptionTags();
      $scope.userForm.submitting = false;
    };
    $scope.onDeliveryAddressAdd = function () {
      $scope.user.delivery_addresses.push({
        country: mainConfig.requestCountry,
      });
      $scope.user.delivery_addresses.forEach(function (addr) {
        if (addr.country === mainConfig.requestCountry) {
          $scope.fetchStateData(addr);
        }
      });
    };
    $scope.onDeliveryAddressDelete = function (index) {
      $scope.user.delivery_addresses.splice(index, 1);
      phoneInstanceMap.addressPhones.splice(index, 1);
    };

    $scope.handleBirthdayChange = () => {
      const { birthday_format: birthdayFormat, minimum_age_limit: minAge } =
        $scope.user_setting;
      const birthdayRequired =
        $scope.user_setting.general_fields.find(
          (field) => field.type === GENERAL_FIELD_TYPE.BIRTHDAY,
        )?.options.profile.required === BOOLEAN_STATUS.TRUE;
      const { year, month, date } = $scope.birthdayForm;
      $scope.birthdayForm.error = validateBirthday({
        birthdayFormat,
        year,
        month: getJsMonth(month),
        date,
        minAge,
        nullable: !birthdayRequired,
      });
    };

    $scope.hasMemberInfoReward = () =>
      slFeatureService.hasFeature('member_info_reward') &&
      $scope.memberInfoRewardInfo;

    $scope.getMemberInfoRewardDescription = () => {
      const {
        custom_fields,
        member_points,
        promotion_ids,
        reward_on,
        user_credits,
      } = $scope.memberInfoRewardInfo;
      const generalFields = reward_on.map((reward) =>
        $filter('translate')(REWARD_ON_GENERAL_FIELDS[reward]),
      );
      const customerFields =
        custom_fields
          ?.filter((field) => field.member_info_reward === 'true')
          ?.map((field) =>
            $filter('translateModel')(field.name_translations),
          ) || [];
      const rewards = [];
      if (user_credits) {
        rewards.push(
          $filter('translate')('users.membership.member_info.user_credits', {
            count: user_credits,
          }),
        );
      }
      if (member_points) {
        rewards.push(
          $filter('translate')('users.membership.member_info.member_points', {
            count: member_points,
          }),
        );
      }
      if (promotion_ids?.length > 0) {
        rewards.push(
          $filter('translate')('users.membership.member_info.coupons', {
            count: promotion_ids.length,
          }),
        );
      }
      const symbol = $filter('translate')(
        'users.membership.member_info.symbol',
      );
      return $filter('translate')('users.membership.member_info.hint', {
        fields: generalFields.concat(customerFields).join(symbol),
        rewards: rewards.join(symbol),
      });
    };

    function displayWarningMessage(messageKey) {
      pnotifyService.notify($filter('translate')(messageKey), {
        customClass: 'error',
        icon: 'fa fa-exclamation-triangle',
      });
    }

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      $scope.userForm.errorMessage = null;

      if (!verifyEmail()) {
        notifyError('membership.profile.email_subscription.error');
        $scope.userForm.submitting = false;
        return;
      }
      if (!$scope.verifyMobilePhone()) {
        notifyError('membership.profile.sms_subscription.error');
        $scope.userForm.submitting = false;
        return;
      }

      if (
        $scope.userForm.$invalid ||
        (!$scope.hasValidBirthday && $scope.birthdayForm.error)
      ) {
        $scope.userForm.submitting = false;
        return;
      }
      $scope.userForm.user_email.$setValidity('emailUnique', true);

      if (!$scope.shouldMobilePhoneConfirmed()) {
        // For error in init, mobileInvalid is false
        if (!$scope.verifyMobileFormat()) {
          $scope.userForm.submitting = false;
          $scope.mobileForm.mobileInvalidBeforeSubmission = true;
          return;
        }
        $scope.user.mobile_phone = $scope.mobileForm.mobilePhone || null;
        $scope.user.country_calling_code = $scope.user.mobile_phone
          ? $scope.mobileForm.countryCallingCode
          : null;
      }

      const subscriptions = $scope.benchatSubscriptions;
      const topics = ['MARKETING'];

      if (
        featureService.hasRolloutFeature('broadcast_to_unsubscribers_sms') &&
        smsSubscriptionService.getActiveSubscriptions(topics, subscriptions)
          .length > 0
      ) {
        $scope.user.exclude_sms_platform = false;
      }

      $scope.successMessages = [];
      $scope.errorMessages = [];
      $scope.setUserSubscriptionTags();

      $scope.user.delivery_addresses = $scope.user.delivery_addresses.map(
        function (deliveryAddress) {
          if (deliveryAddress.postcode) {
            deliveryAddress.postcode = deliveryAddress.postcode.trim();
          }
          return deliveryAddress;
        },
      );

      if ($scope.hasCountryCode) {
        $scope.user.phone_country_code = $scope.user.phone
          ? phoneInstanceMap.phone.getCountry('number')
          : null;

        $scope.user.delivery_addresses = $scope.user.delivery_addresses.map(
          (deliveryAddress, index) => ({
            ...deliveryAddress,
            recipient_phone:
              phoneInstanceMap.addressPhones[index].getPhone('national'),
            recipient_phone_country_code:
              phoneInstanceMap.addressPhones[index].getCountry('number'),
          }),
        );
      } else {
        $scope.user.phone_country_code = null;
        $scope.user.delivery_addresses = $scope.user.delivery_addresses.map(
          (deliveryAddress) => ({
            ...deliveryAddress,
            recipient_phone_country_code: null,
          }),
        );
      }

      if ($scope.user.email) {
        $scope.user.email = $scope.user.email.toLowerCase();
      }

      $http({
        method: 'PUT',
        url: `/api/users/${$scope.user._id}`,
        data: {
          user: angular.extend(
            {},
            $scope.user,
            {
              birth_year: $scope.birthdayForm.year,
              birth_month: $scope.birthdayForm.month,
              birth_day: $scope.birthdayForm.date,
              delivery_addresses: $scope.user.delivery_addresses.map(
                function (addr) {
                  return _.omit(addr, 'states');
                },
              ),
            },
            { time_zone_offset: timeZoneOffset },
          ),
          benchatFields: renderBenchatFields(),
          recaptchable: true,
          includes: ['membership_tier_gap'],
        },
      })
        .then(function (res) {
          const data = res.data;
          $scope.userForm.submitting = false;
          if (data?.result) {
            let successMessage = $filter('translate')('users.save.success');
            // check if email has changed
            if (
              $scope.user_setting.email_verification.status &&
              $scope.user.email &&
              $scope.originalEmail != $scope.user.email
            ) {
              if ($scope.user_setting.login_with_verification?.status) {
                successMessage = $filter('translate')(
                  'users.save.email_verification.success',
                );

                if (data?.data?.trial_email_quota_exceeded) {
                  displayWarningMessage(
                    'verification.email.trial_merchant.reach_limit.failure',
                  );
                } else {
                  openEmailVerificationSendedDialog();
                }
              } else if (
                !$scope.user_setting.login_with_verification?.status &&
                data?.data?.trial_email_quota_exceeded
              ) {
                displayWarningMessage(
                  'verification.email.trial_merchant.reach_limit.failure',
                );
              } else {
                pnotifyService.notify(
                  $filter('translate')('users.save.email_verification.send'),
                  {},
                );
              }
            }
            if (data.data.info_reward_triggered) {
              pnotifyService.notify(
                $filter('translate')('users.membership.member_info.sent'),
                {},
              );
            }

            // for change mobile without confirmation we need to show error message
            if (checkMobileIsConfirmed()) {
              $scope.successMessages.push(successMessage);
            } else {
              $scope.errorMessages = [
                $filter('translate')('users.error.not_save_mobile_phone'),
              ];
            }

            $scope.user = flow(
              cond([
                [$scope.shouldMobilePhoneConfirmed, fallbackMobilePhone],
                [stubTrue, forceSetMobileFormFromResponse],
              ]),
              fallbackPhoneCountryCode,
            )(data.data);
            initBirthday();
            $scope.mobileForm.mobileModified = false;
            $scope.originalEmail = $scope.user.email;
            $scope.userBackup = angular.copy($scope.user);

            $scope.userForm.submitted = false;
            $scope.user.delivery_addresses.forEach(function (addr) {
              $scope.fetchStateData(addr);
            });
          } else {
            if (data.data.email && data.data.email[0] === 'is already taken') {
              $scope.userForm.user_email.$setValidity('emailUnique', false);
            }
            // mobile_phone is used
            if (data.data?.base?.[0]) {
              $scope.mobileForm.mobileErrorMessages = [
                $filter('translate')('session.mobile_center.check_phone.error'),
              ];
            }
            // Other mobile_phone errors
            if (
              data.data?.mobile_phone_with_country_calling_code?.[0] ||
              data.data?.country_calling_code?.[0]
            ) {
              $scope.mobileForm.mobileInvalidAfterSubmission = true;
            }
          }
        })
        .catch(function (data) {
          $scope.errorMessages = data.data.error;
          $scope.user.email = $scope.originalEmail;
          $scope.userForm.submitting = false;
        })
        .finally(function () {
          $scope.user = fallbackPhoneCountryCode($scope.user);
          RecaptchaService.reset('profile-save-recaptcha');
        });
    };

    $scope.included = function (generalFields, customFields) {
      var needToInclude = function (fields) {
        var isIncluded = false;
        fields.map(function (field) {
          if (field.options.profile.include == 'true') isIncluded = true;
        });
        return isIncluded;
      };
      return (
        (generalFields && needToInclude(generalFields)) ||
        (customFields && needToInclude(customFields))
      );
    };

    $scope.checkColumntTypeReadable = function (column_type) {
      if (featureService.hasRolloutFeature('member_customfield'))
        return column_type === 'readable';
    };

    $scope.onCountryChange = function (addr) {
      addr.city = undefined;
      addr.address_1 = undefined;
      addr.address_2 = undefined;
      addr.state = undefined;
      addr.postcode = undefined;
      addr.region_code = undefined;
      addr.key = undefined;
      $scope.fetchStateData(addr);
    };

    $scope.getPostcodeRegex = function (addr) {
      switch (addr.country) {
        case 'MY':
        case 'TH':
          return /^\d{5}$/;
        case 'SG':
          return /^\d{6}$/;
        default:
          return /.*/;
      }
    };

    $scope.hasCustomSateTranslationCountries = [
      'JP',
      'TH',
      'DE',
      'FR',
      'ID',
      'CA',
      'US',
      'VN',
    ];
    $scope.shouldUseOmsAddress = function (country) {
      return (
        country === 'MY' && slFeatureService.hasFeature('dynamic_shipping_rate')
      );
    };

    $scope.hasModularizeAddress = function () {
      return slFeatureService.hasFeature('modularize_address_format');
    };

    $scope.fetchStateData = function (addr) {
      if ($scope.shouldShowThreeLayerAddress(addr.country))
        return getFirstLayerOptions(addr);
      if (shouldFetchStateData(addr)) {
        if (_.include($scope.hasCustomSateTranslationCountries, addr.country)) {
          fetchCustomStateTranslation(addr);
        } else {
          $http({
            method: 'GET',
            url:
              '/api/merchants/' +
              merchantService.merchantId +
              '/country_states',
            params: { country_code: addr.country.toLowerCase() },
          }).then(function (res) {
            addr.states = res.data;
          });
        }
      }
    };

    function getFirstLayerOptions(addr) {
      var country = addr.country.toLowerCase();
      var layer1JsonUrl =
        staticResourceHost + 'web/v1/translations/districts_' + country;
      $http
        .get(layer1JsonUrl + '_v2.json?t=' + Date.now(), {
          withCredentials: false,
        })
        .then(function (res) {
          $scope.listOfLayer1Map[country] = generateListOfFirstLayer(res.data);
          getRemainLayerOptions(addr);
        });
    }

    function generateListOfFirstLayer(datas) {
      return _.map(datas, function (data) {
        return {
          data: data.data,
          displayName: $filter('translateModel')(data.data),
        };
      });
    }

    function resetAddrLayer2(addr) {
      addr.layer2 = undefined;
      addr.city = undefined;
    }

    function resetAddrLayer3(addr) {
      addr.layer3 = undefined;
      addr.state = undefined;
    }

    $scope.onLayer1Change = function (addr) {
      getRemainLayerOptions(addr);
      resetAddrLayer2(addr);
      resetAddrLayer3(addr);
    };

    var fetchCustomStateTranslation = function (addr) {
      var jsonFiles = {
        JP: 'ja.json',
        TH: 'th.json',
        DE: 'de.json',
        FR: 'fr.json',
        ID: 'id.json',
        CA: 'ca.json',
        US: 'us.json',
        VN: 'vn.json',
      };
      var jsonUrl =
        staticResourceHost +
        'web/v1/translations/districts_' +
        jsonFiles[addr.country];
      $.getJSON(jsonUrl, function (data) {
        addr.states = data
          .filter(function (record) {
            return record.type === 'state';
          })
          .map(function (state) {
            return state.data;
          });
        $scope.$apply();
      });
    };

    const renderBenchatFields = function () {
      const getValueByName = (name) =>
        document.querySelector(`[name="benchatFields[subscriptions][${name}]"]`)
          ?.value ?? '[]';
      // The value will be string when query from DOM directly
      return {
        subscriptions: {
          line: getValueByName('line'),
          facebook: getValueByName('facebook'),
        },
      };
    };

    $scope.onStateChange = function (addr) {
      var state = _.find(addr.states, function (state) {
        return state.key === addr.key;
      });

      addr.state = $filter('translateModel')(state);
    };

    $scope.handleOmsStateChange = function (addr) {
      var targetOmsState = addr.states.find(function (state) {
        return state.name === addr.state;
      });
      addr.logistic_code = targetOmsState.logistic_code;
    };

    function shouldFetchStateData(addr) {
      return (
        (['US', 'UM', 'MY', 'JP'].indexOf(addr.country) > -1 &&
          featureService.hasRolloutFeature(
            ('address_format_' + addr.country).toLowerCase(),
          )) ||
        (['TH', 'DE', 'FR', 'ID', 'CA'].indexOf(addr.country) > -1 &&
          featureService.hasRolloutFeature('address_format_batch2'))
      );
    }

    $scope.verifyMobileFormat = function () {
      const mobilePhone = $scope.mobileForm.mobilePhone || '';
      // Not fill out mobile phone
      if (mobilePhone === '') {
        return true;
      }
      const errorMessages = intlTelInputService.validatePhone(
        phoneInstanceMap.mobile.element,
        {
          validateTarget: {
            mobilePhoneValidation: true,
          },
        },
      );
      return errorMessages.length === 0;
    };

    const getValidMobilePhoneData = () => {
      if ($scope.shouldMobilePhoneConfirmed()) {
        const {
          is_mobile_phone_confirmed,
          country_calling_code,
          mobile_phone,
        } = $scope.user ?? {};
        return {
          isValid: is_mobile_phone_confirmed,
          countryCallingCode: country_calling_code,
          mobilePhone: mobile_phone,
        };
      }
      const { countryCallingCode, mobilePhone, mobileModified } =
        $scope.mobileForm;
      return {
        isValid: !mobileModified,
        countryCallingCode,
        mobilePhone,
      };
    };

    $scope.getValidMobilePhone = () => {
      const INVALID_PHONE = '';
      const { isValid, countryCallingCode, mobilePhone } =
        getValidMobilePhoneData();
      if (isValid && countryCallingCode && mobilePhone) {
        const phone = intlTelInputUtils?.formatNumber(
          mobilePhone,
          intlTelInputService.toAbbr(countryCallingCode),
          intlTelInputUtils.numberFormat.E164,
        );
        return phone?.replace(/^\+/, '') ?? INVALID_PHONE;
      }
      return INVALID_PHONE;
    };

    $scope.fillPhoneToMobilePhone = () => {
      const phone = $scope.user.phone;
      const phoneCountryCode = $scope.user.phone_country_code;
      if (phoneCountryCode) {
        const phoneCountryCodeISO =
          phoneInstanceMap.phone?.getCountry('abbr') ??
          // for country_code off
          intlTelInputService.toAbbr(phoneCountryCode);
        if (
          Array.isArray(allowedCountryCallingCodes) &&
          !allowedCountryCallingCodes.includes(phoneCountryCodeISO)
        ) {
          $scope.mobileForm.countryCallingCodeNotAllowed = true;
          return;
        }
        phoneInstanceMap.mobile.setCountry('abbr', phoneCountryCodeISO);
      } else {
        // setCountry can not set falsy value
        phoneInstanceMap.mobile.reset();
      }
      phoneInstanceMap.mobile.setPhone('complete', phone);
      $scope.mobileForm.countryCallingCode = phoneCountryCode;
      $scope.updateMobileInvalid();
    };

    $scope.onEmailChange = function () {
      $scope.userForm.user_email.$setValidity('emailUnique', true);
      if ($scope.user.email === '') {
        $scope.user.email = null;
      }
    };

    $scope.showValidateUserEmailResult = false;

    $scope.updateUserEmailAndSendVerificationEmail = () => {
      $http({
        method: 'PUT',
        url: '/api/users/' + $scope.user._id,
        data: {
          user: { email: $scope.user.email },
          skip_email_verification: true,
          recaptchable: true,
        },
      }).then(function (res) {
        var data = res.data;

        if (data.result) {
          userService
            .sendVerificationEmail($scope.user._id)
            .then(function () {
              openEmailVerificationSendedDialog();
            })
            .catch(function (error) {
              const err = error?.data || {};
              if (
                err.error_code ===
                QUOTA_LIMIT_ERROR_TYPE.TRIAL_EMAIL_QUOTA_EXCEEDED
              ) {
                displayWarningMessage(
                  'verification.email.trial_merchant.reach_limit.failure',
                );
              } else {
                $scope.errorMessages.push(error.data.error);
              }

              $scope.sendVerificationEmailreachedRateLimit =
                error.data.reached_rate_limit;
            });
        } else {
          if (data.data.email && data.data.email[0] === 'is already taken') {
            $scope.userForm.user_email.$setValidity('emailUnique', false);
            $scope.showValidateUserEmailResult = true;
          }
        }
      });
    };

    $scope.verifyPhoneSubmit = function () {
      $scope.mobileForm.mobileInvalid = !$scope.verifyMobileFormat();

      if ($scope.mobileForm.mobileInvalid) {
        return;
      }
      const mobilePhone = $scope.mobileForm.mobilePhone || '';
      if (
        $rootScope.currentUser.mobile_phone === mobilePhone &&
        $rootScope.currentUser.mobile_phone_confirmed_at
      ) {
        $scope.mobileForm.mobileErrorMessages = [
          $filter('translate')('user.sign_up.check_phone.error'),
        ];
        return;
      }

      $scope.mobileForm.mobileSubmitting = true;
      const params = {
        mobilePhone,
        countryCallingCode: $scope.mobileForm.countryCallingCode,
      };

      mobilePhoneService.checkUserExistenceByPhone(params).then(
        function () {
          $scope.mobileForm.mobileSubmitting = false;
          $scope.mobileForm.mobileErrorMessages = [
            $filter('translate')('session.mobile_center.check_phone.error'),
          ];
        },
        function (error) {
          if (error.status === 404) {
            $scope.$broadcast('mobile-step-change', 'check_mobile');
            $scope.mobileForm.mobileErrorMessages = [];
          } else {
            $scope.mobileForm.mobileErrorMessages = [error.data.error_messages];
          }
          $scope.mobileForm.mobileSubmitting = false;
        },
      );
    };

    $scope.shouldShowFomattedAdrress = function (country) {
      return (
        (featureService.hasRolloutFeature('address_format_us') &&
          ['US', 'UM'].indexOf(country) > -1) ||
        (featureService.hasRolloutFeature('address_format_my') &&
          country === 'MY') ||
        (featureService.hasRolloutFeature('address_format_jp') &&
          country === 'JP') ||
        (featureService.hasRolloutFeature('address_format_batch2') &&
          ['DE', 'FR', 'ID', 'CA'].indexOf(country) > -1)
      );
    };

    $scope.shouldShowThreeLayerAddress = function (country) {
      var countryLowercase = country.toLowerCase();
      var targetCountries = ['vn', 'th'];
      if (!targetCountries.includes(countryLowercase)) return false;
      return (
        featureService.hasRolloutFeature(
          'address_format_' + countryLowercase,
        ) || featureService.hasRolloutFeature('address_format_batch2')
      );
    };
    $scope.shouldShowThreeLayerPostcode = function (country) {
      var countryLowercase = country.toLowerCase();
      var targetCountries = ['th'];

      return (
        $scope.shouldShowThreeLayerAddress &&
        targetCountries.includes(countryLowercase)
      );
    };

    function getRemainLayerOptions(addr) {
      if (!addr.layer1 || !addr.country) return;
      var targetLayer1 = $scope.listOfLayer1Map[
        addr.country.toLowerCase()
      ].find(function (layer1) {
        return layer1.data.key === addr.layer1;
      });
      addr.address_2 = targetLayer1.displayName;

      var remainLayerJsonUrl =
        staticResourceHost +
        'web/v1/translations/' +
        addr.country.toLowerCase() +
        '_districts/' +
        targetLayer1.data.key +
        '.json?t=' +
        Date.now();
      $http
        .get(remainLayerJsonUrl, {
          withCredentials: false,
        })
        .then(function (res) {
          $scope.listOfLayer2Map[addr.layer1] = generateListOfSecondLayer(
            res.data,
          );
          if (addr.layer2) {
            saveLayer2AsCity(addr);
            $scope.listOfLayer3Map[addr.layer2] =
              generateListOfThirdLayer(addr);
          }
          if (addr.layer3) {
            saveLayer3AsState(addr);
          }
        });
    }

    function saveLayer2AsCity(addr) {
      var targetLayer2 = $scope.listOfLayer2Map[addr.layer1].find(
        function (layer2) {
          return layer2.key === addr.layer2;
        },
      );
      addr.city = targetLayer2.displayName;
    }

    function saveLayer3AsState(addr) {
      var targetLayer3 = $scope.listOfLayer3Map[addr.layer2].find(
        function (layer3) {
          return layer3.key === addr.layer3;
        },
      );
      addr.state = targetLayer3.displayName;
    }

    function generateListOfSecondLayer(data) {
      return _.map(data.districts, function (district) {
        return {
          key: district.key,
          displayName: $filter('translateModel')(district),
          wards: district.wards,
        };
      });
    }

    $scope.onLayer2Change = function (addr) {
      resetAddrLayer3(addr);
      saveLayer2AsCity(addr);
      $scope.listOfLayer3Map[addr.layer2] = generateListOfThirdLayer(addr);
    };

    $scope.onLayer3Change = function (addr) {
      saveLayer3AsState(addr);
    };

    function generateListOfThirdLayer(addr) {
      if (!addr || !addr.layer1 || !addr.layer2) return;
      var targetLayer2 = $scope.listOfLayer2Map[addr.layer1].find(
        function (layer2) {
          return layer2.key === addr.layer2;
        },
      );
      return _.map(targetLayer2.wards, function (ward) {
        return {
          key: ward.key,
          displayName: $filter('translateModel')(ward),
        };
      });
    }

    function openEmailVerificationSendedDialog() {
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.email-verification.html'),
        controller: 'UsersEditEmailVerificationDialogController',
        windowClass: 'email-verification-modal-window',
        resolve: {
          userEmail: function () {
            return $scope.user.email;
          },
        },
      });
    }

    onTabActive($element, () => {
      history.replaceState(
        null,
        null,
        `/users/${mainConfig.currentUser._id}/edit${location.search}`,
      );
    });

    $scope.addCreditCardIsDisabled = function () {
      return $scope.creditCardList?.length === creditCard.CARD_LIST_LIMIT;
    };

    $scope.onCreditCardAdd = function () {
      if ($scope.addCreditCardIsDisabled()) return;
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog-add-credit-card.html'),
        windowClass: 'credit-card-modal-window',
        backdropClass: 'credit-card-modal-backdrop',
        controller: [
          '$scope',
          '$uibModalInstance',
          'moneyService',
          'mainConfig',
          function (
            $addModelScope,
            $uibModalInstance,
            moneyService,
            mainConfig,
          ) {
            const currencyCode =
              mainConfig.merchantData.base_country_code === 'HK'
                ? 'USD'
                : mainConfig.merchantData.base_currency_code;
            $addModelScope.label = moneyService.toMoney({
              symbol: currencyCode,
              cents: moneyService.dollarsToCents(1, currencyCode),
            }).label;
            $addModelScope.close = function () {
              $uibModalInstance.close();
            };
            $addModelScope.isLoading = true;
            let shoplinePaymentV2Content = null;

            $addModelScope.onSdkInit = (value) => {
              shoplinePaymentV2Content = value;
              $addModelScope.isLoading = false;
              $addModelScope.$digest();
            };
            $addModelScope.submit = function () {
              if ($addModelScope.isLoading) return;
              shoplinePaymentV2Content.validate().then(function (valid) {
                if (!valid) return;
                $addModelScope.isLoading = true;
                shoplinePaymentV2Content
                  .getPaySession()
                  .then(function (paySessionRes) {
                    let data = {
                      polling_id:
                        mainConfig.currentUser._id + new Date().getTime(),
                      pay_session: paySessionRes,
                    };
                    $http({
                      method: 'POST',
                      url: '/api/shopline_payment/payment_instruments',
                      data,
                    })
                      .then(function (paymentInstrumentRes) {
                        shoplinePaymentV2Content
                          .pay(paymentInstrumentRes.data.next_action)
                          .then(function (payRes) {
                            if (payRes && payRes.paymentError) {
                              pnotifyService.notify(
                                $filter('translate')(
                                  'user.add_credit_card.fail',
                                ),
                                {
                                  customClass: 'error',
                                  icon: 'fa fa-exclamation-triangle',
                                },
                              );
                            }
                          })
                          .catch(function () {
                            pnotifyService.notify(
                              $filter('translate')('user.add_credit_card.fail'),
                              {
                                customClass: 'error',
                                icon: 'fa fa-exclamation-triangle',
                              },
                            );
                          })
                          .finally(function () {
                            $addModelScope.isLoading = false;
                            $addModelScope.close();
                          });
                      })
                      .catch(function () {
                        $addModelScope.isLoading = false;
                        $addModelScope.close();
                        pnotifyService.notify(
                          $filter('translate')('user.add_credit_card.fail'),
                          {
                            customClass: 'error',
                            icon: 'fa fa-exclamation-triangle',
                          },
                        );
                      });
                  });
              });
            };
          },
        ],
      });
    };

    $scope.onCreditCardDelete = function (item) {
      if (item.status !== 'SUCCESSED') return;
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog-delete-credit-card.html'),
        controller: [
          '$scope',
          '$uibModalInstance',
          'slFeatureService',
          function ($deleteModalScope, $uibModalInstance, slFeatureService) {
            $deleteModalScope.slFeatureService = slFeatureService;
            $deleteModalScope.isLoading = false;
            $deleteModalScope.creditCardItem = item;
            $deleteModalScope.close = function () {
              $uibModalInstance.close();
            };
            $deleteModalScope.submit = function () {
              if ($deleteModalScope.isLoading) return;
              $deleteModalScope.isLoading = true;
              $http({
                method: 'DELETE',
                url: `/api/shopline_payment/payment_instruments/${item.id}`,
              })
                .then(function () {
                  $deleteModalScope.close();
                  pnotifyService.notify(
                    $filter('translate')('user.delete_credit_card.success'),
                    {},
                  );
                  getCreditCardList();
                })
                .catch(function () {
                  $deleteModalScope.close();
                  pnotifyService.notify(
                    $filter('translate')('user.delete_credit_card.fail'),
                    {
                      customClass: 'error',
                      icon: 'fa fa-exclamation-triangle',
                    },
                  );
                })
                .finally(function () {
                  $deleteModalScope.isLoading = false;
                });
            };
          },
        ],
      });
    };

    $scope.renderStatusText = function (status) {
      if (status === 'SUCCESSED')
        return $filter('translate')('user.credit_card_list.delete');
      return $filter('translate')('user.credit_card_list.processing');
    };

    $scope.formatBrand = function (brand) {
      const defaultCreditCard =
        'https://shoplineimg.com/assets/footer/card_default.png';
      return creditCard.BRAND_ICON_MAP[brand] || defaultCreditCard;
    };

    $scope.getPhoneErrorMessage = (fieldName) => {
      if (!$scope.userForm.submitted || !$scope.userForm[fieldName]?.$invalid) {
        return '';
      }
      if ($scope.userForm[fieldName].$error.required) {
        return $filter('translate')('form.validation.required');
      }
      if ($scope.userForm[fieldName].$error.pattern) {
        return $filter('translate')('form.validation.pattern');
      }
      if ($scope.userForm[fieldName].$error.minlength) {
        return $filter('translate')('form.validation.minlength');
      }
      if ($scope.userForm[fieldName].$error.maxlength) {
        return $filter('translate')('form.validation.maxlength');
      }
      return '';
    };

    function getCreditCardList() {
      $http({
        method: 'GET',
        url: '/api/shopline_payment/payment_instruments',
      })
        .then(function (res) {
          $scope.creditCardList = res.data;
        })
        .catch((error) => {
          if (mainConfig.env !== 'production') {
            console.error(error);
          }
        });
    }

    getCreditCardList();

    function checkPollingId() {
      function getS3Url(id) {
        // because of using $http will happen cors error so change to use fetch
        fetch(`${shopline_payment_instrument_polling_endpoint}${id}`)
          .then(function (res) {
            return res.text();
          })
          .then(function (res) {
            // res === 'SUCCESS' and res === 'FAIL' only if status code equals 200
            if (res === 'SUCCESS' || res === 'FAIL') {
              webhookMap[id]['isGetRes'] = true;
              getCreditCardList();
              if (webhookMap[id]['interval'])
                clearInterval(webhookMap[id]['interval']);
            }
            if (res === 'SUCCESS') {
              pnotifyService.notify(
                $filter('translate')('user.add_credit_card.success'),
                {},
              );
            }
            if (res === 'FAIL') {
              pnotifyService.notify(
                $filter('translate')('user.add_credit_card.fail'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            }
            localStorage.setItem(
              'payment_instrument_webhook_map',
              JSON.stringify(webhookMap),
            );
          });
      }

      const params = new URLSearchParams(window.location.search);
      const webhookMap =
        JSON.parse(localStorage.getItem('payment_instrument_webhook_map')) ||
        {};
      if (!params.has('polling_id')) {
        const webhookMapList = Object.keys(webhookMap);
        const res = webhookMapList.every(
          (item) => webhookMap[item]['isGetRes'] === true,
        );
        if (res) localStorage.removeItem('payment_instrument_webhook_map');
        return;
      }
      if (!webhookMap[params.get('polling_id')]) {
        webhookMap[params.get('polling_id')] = {
          isGetRes: false,
          interval: null,
        };
      }
      const webhookMapList = Object.keys(webhookMap);
      webhookMapList.forEach((item) => {
        if (!webhookMap[item]['isGetRes']) {
          getS3Url(item);
          webhookMap[item]['interval'] = setInterval(
            () => getS3Url(item),
            5000,
          );
        }
      });
    }

    checkPollingId();

    $scope.$on('init.mobile.number', (event, { element }) => {
      const config = IntlTelClass.getIntlTelConfig(
        $scope.user.mobile_phone,
        $scope.user.country_calling_code,
      );
      config.usePreferredCountriesWithAllowedCountry = true;
      config.allowedCountry = allowedCountryCallingCodes;
      if (isEmpty($scope.user.mobile_phone)) {
        config.defaultCountry = getMobilePhoneDefaultCountry();
      }
      const instance = new IntlTelClass(element, config);
      $scope.mobileForm.countryCallingCode = instance.getCountry('number');
      instance.setOnCountryChange(() => {
        $scope.mobileForm.countryCallingCode = instance.getCountry('number');
        $scope.updateMobileInvalid();
      });
      instance.setOnFomattedPhoneChange((event, formattedPhone) => {
        if ($scope.mobileForm.mobilePhone !== formattedPhone) {
          $scope.mobileForm.mobilePhone = formattedPhone;
        }
      });
      phoneInstanceMap.mobile = instance;
    });

    $scope.$on('init.phone.number', (event, { element }) => {
      const instance = new IntlTelClass(
        element,
        IntlTelClass.getIntlTelConfig(
          $scope.user.phone,
          $scope.user.phone_country_code,
        ),
      );
      $scope.user.phone_country_code = instance.getCountry('number');
      instance.setOnCountryChange(() => {
        $scope.user.phone_country_code = instance.getCountry('number');
      });
      instance.setOnFomattedPhoneChange((event, formattedPhone) => {
        if ($scope.user.phone !== formattedPhone) {
          $scope.user.phone = formattedPhone;
        }
      });
      phoneInstanceMap.phone = instance;
    });

    $scope.$on('init.recipient.phone.number', (event, { element, attrs }) => {
      const [, index] = attrs.name.split('addr_recipient_phone_');
      const deliveryAddress = $scope.user.delivery_addresses[index] || {};
      phoneInstanceMap.addressPhones[index] = new IntlTelClass(
        element,
        IntlTelClass.getIntlTelConfig(
          deliveryAddress.recipient_phone,
          deliveryAddress.recipient_phone_country_code,
        ),
      );
    });

    $scope.getBirthdayFaq = () => {
      if ($scope.user_setting.birthday_format === BIRTHDAY_FORMAT.MMDD) {
        return '';
      }

      const content = $filter('translate')('users.birthday.faq');
      const template = `
        <div class="popover" role="tooltip">
          <h3 class="popover-title"></h3>
          <div class="popover-content"></div>
        </div>
      `;
      return `
        <a
          animation="true"
          data-trigger="hover"
          data-toggle="popover"
          data-placement="right"
          data-content="${content}"
          data-template='${template}'
        >
          <i class="fa fa-lg fa-question-circle member-center-icon"></i>
        </a>
      `;
    };

    $scope.handleEmailSubscriptionChange = () => {
      if (!verifyEmail()) {
        $scope.subscription_tags['marketing.news'] = false;
        notifyError('membership.profile.email_subscription.error');
      }
      $http({
        method: 'PUT',
        url: `/api/users/${$scope.user._id}`,
        data: {
          user: {
            subscribed_email_types: $scope.subscription_tags['marketing.news']
              ? ['marketing.news']
              : [],
          },
        },
      });
    };

    function verifyEmail() {
      return !$scope.subscription_tags['marketing.news'] || $scope.user.email;
    }

    $scope.verifyMobilePhone = () => {
      const mobilePhone = $scope.shouldMobilePhoneConfirmed()
        ? $scope.user.mobile_phone
        : $scope.mobileForm.mobilePhone;
      return !$scope.sms.isChecked || mobilePhone;
    };

    $scope.smsCheckboxClickDecorator = (callback) => {
      if (!$scope.verifyMobilePhone()) {
        $scope.sms.isChecked = false;
        notifyError('membership.profile.sms_subscription.error');
        return;
      }
      callback();
    };

    function notifyError(translateKey) {
      pnotifyService.notify($filter('translate')(translateKey), {
        customClass: 'error',
        icon: 'fa fa-exclamation-triangle',
      });
    }
  },
]);
