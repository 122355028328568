import './services.cart';
import { last, uniqBy } from 'lodash-es';

function findWithName(levelAddresses, nameKey, targetName) {
  return levelAddresses.find((address) =>
    Object.values(address[nameKey]).some((name) => name === targetName),
  );
}

app.directive('storePickUpOptions', [
  'cartService',
  'mainConfig',
  '$filter',
  function (cartService, mainConfig, $filter) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.store_pick_up_options.html'),
      scope: {
        viewOnly: '=',
        onStoreSelected: '<',
        deliveryOptionId: '@',
        deliveryData: '=',
        validateItem: '=',
        storePickupOptions: '=?',
        pickUpConfig: '=?',
        addresses: '=?',
        disabled: '=?',
      },
      link: function (scope) {
        const errorMessageMap = {
          [cartService.ERROR_CODE.PRODUCT_INSUFFICIENT_STOCK]: {
            key: 'checkout.delivery_option.out_of_stock',
            payload: {},
          },
          REQUIRED: {
            key: 'form.validation.required',
            payload: {
              field_name: $filter('translate')(
                'order.delivery_data.location_name',
              ),
            },
          },
        };

        function getValidateErrors(validateItem) {
          return uniqBy(validateItem?.items, 'code')
            .map((item) => errorMessageMap[item.code])
            .filter(Boolean);
        }

        // states start
        scope.errors = getValidateErrors(scope.validateItem);
        scope.isRequiredError = scope.errors.includes(
          errorMessageMap['REQUIRED'],
        );
        scope.hasSelected = scope.deliveryData?.location_name || false;
        scope.language = mainConfig.localeData.loadedLanguage.code;
        scope.fields = [];
        scope.pickupOption = scope.storePickupOptions?.find(
          (option) => option.id === scope.deliveryOptionId,
        );
        scope._addresses =
          scope.pickupOption?.option?.addresses || scope.addresses;
        scope._pickUpConfig =
          scope.pickupOption?.option?.config_option || scope.pickUpConfig;

        scope.options = {};
        scope.selectedStore = null;
        scope.selectField = {};
        scope.storeOptions = [];
        // states end

        var getTranslateLabel = function (fields, index) {
          var field = Object.values(fields).find(function (value) {
            return value[index].label;
          });
          return field ? field[index].label : '';
        };

        // Initialize Selections Start
        if (
          scope._pickUpConfig.level_fields &&
          scope._pickUpConfig.level_fields.length !== 0
        ) {
          scope._pickUpConfig.level_fields.forEach(
            function (levelField, index, levelFields) {
              if (index === 0) {
                scope.options[levelField] = scope._addresses;
                if (scope.hasSelected) {
                  const levelData = findWithName(
                    scope._addresses,
                    'name_translations',
                    scope.deliveryData.store_address_detail[levelField],
                  );
                  scope.selectField[levelField] = levelData;
                }
              } else {
                scope.options[levelField] = [];
                if (scope.hasSelected) {
                  const upperLevelKey = levelFields[index - 1];
                  const currentLevelAddresses =
                    scope.selectField[upperLevelKey].next_level;
                  const levelData = findWithName(
                    currentLevelAddresses,
                    'name_translations',
                    scope.deliveryData.store_address_detail[levelField],
                  );
                  scope.selectField[levelField] = levelData;
                  scope.options[levelField] = currentLevelAddresses || [];
                }
              }
            },
          );

          if (scope.hasSelected) {
            const storeOptions =
              scope.selectField[last(scope._pickUpConfig.level_fields)]
                ?.next_level;

            scope.selectedStore = findWithName(
              storeOptions,
              'store_name',
              scope.deliveryData.location_name,
            );
            scope.storeOptions = storeOptions;
          }
          Object.keys(scope.options).forEach(function (key) {
            scope._pickUpConfig.dropdown_fields_v2[scope.language].forEach(
              function (field, index) {
                if (field.field === key) {
                  scope.fields.push({
                    field: field.field,
                    label:
                      field.label ||
                      getTranslateLabel(
                        scope._pickUpConfig.dropdown_fields_v2,
                        index,
                      ),
                  });
                }
              },
            );
          });
        } else {
          scope.storeOptions = scope._addresses;
          if (scope.hasSelected) {
            scope.selectedStore = findWithName(
              scope._addresses,
              'store_name',
              scope.deliveryData.location_name,
            );
          }
        }
        // Initialize Selections End

        scope.getLabel = function (option, key) {
          if (!option) return '';
          return $filter('translateModel')(option[key]);
        };

        scope.getStoreLabel = function (option) {
          var storeAddress = scope.getLabel(option, 'store_address');
          return (
            scope.getLabel(option, 'store_name') +
            (storeAddress ? ' - ' + storeAddress : '')
          );
        };

        scope.onLevelChange = function (option, index) {
          if (!option) return;

          var nextLevelIndex = index + 1;
          scope.storeOptions = [];
          scope.selectedStore = null;
          if (scope._pickUpConfig.level_fields.length > nextLevelIndex) {
            scope._pickUpConfig.level_fields.forEach(
              function (field, levelIndex) {
                if (levelIndex === nextLevelIndex) {
                  scope.options[field] = option.next_level;
                } else if (levelIndex > nextLevelIndex) {
                  scope.options[field] = [];
                }
              },
            );
          } else {
            scope.storeOptions = option.next_level;
          }
        };

        scope.shouldShowFullWidth = function (index) {
          return scope.fields.length % 2 === 1 && index === 0;
        };

        scope.formatDeliveryData = function (store, fields) {
          const deliveryData = {
            location_name: scope.getLabel(store, 'store_name'),
            store_address: scope.getLabel(store, 'store_address'),
            store_address_detail: {
              level_1: scope.getLabel(fields['level_1'], 'name_translations'),
              level_2: scope.getLabel(fields['level_2'], 'name_translations'),
              level_3: scope.getLabel(fields['level_3'], 'name_translations'),
            },
            target_channel_id: store.channel_id,
          };
          return deliveryData;
        };

        scope.onStoreChange = function () {
          if (typeof scope.onStoreSelected === 'function') {
            const deliveryData = scope.formatDeliveryData(
              scope.selectedStore,
              scope.selectField,
            );
            scope.onStoreSelected(deliveryData, scope.deliveryOptionId);
          }
        };

        // listeners
        scope.$watch('validateItem', (newData) => {
          scope.errors = getValidateErrors(newData);
          scope.isRequiredError = scope.errors.includes(
            errorMessageMap['REQUIRED'].key,
          );
        });
      },
    };
  },
]);
