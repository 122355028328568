import './services.time_slot';
app.directive('deliveryDatePicker', [
  'timeSlotService',
  function (timeSlotService) {
    const componentTemplate = `
      <div class="form-group date-picker" ng-hide="hidden">
        <div class="date-picker-title">{{"delivery_options.fields.select_date" | translate}}</div>
        <div class="input-group-btn">
          <input
            type="text"
            name="order[delivery_data][scheduled_delivery_date]"
            class="form-control"
            datepicker-options="options"
            uib-datepicker-popup="yyyy/MM/dd"
            ng-model="deliveryDate"
            ng-change="onChange(deliveryDate)"
            is-open="pickerOpened"
            placeholder="YYYY/MM/DD"
            show-button-bar="false"
            ng-required="isRequired()"
          /><button type="button" class="btn btn-default" ng-click="open($event)"><i class="fa fa-calendar"></i></button>
        </div>
      </div>
    `;

    return {
      restrict: 'E',
      replace: true,
      template: componentTemplate,
      scope: {
        specificDeliveryDate: '=',
        maxDeliveryDate: '=',
        excludedWeekdays: '=',
        excludedDates: '=',
        deliveryTimeRequired: '=',
        deliveryOptionConfig: '=',
        deliveryDate: '=',
      },
      link: function (scope, element) {
        scope.pickerOpened = false;
        scope.hidden = false;
        scope.deliveryDate = null;

        scope.onChange = (value) => {
          scope.deliveryDate = value;
        };

        function init() {
          if (timeSlotService.isFeatureEnabled(scope.deliveryOptionConfig)) {
            const { delivery_time_slot_enable, delivery_time_slot_type } =
              scope.deliveryOptionConfig;

            const isDatePickerEnabled =
              delivery_time_slot_enable &&
              timeSlotService.DATE_SLOT_ENABLED.includes(
                delivery_time_slot_type,
              );

            scope.hidden = !isDatePickerEnabled;
            return;
          }

          if (!scope.specificDeliveryDate && scope.specificDeliveryDate !== 0) {
            scope.hidden = true;
            return;
          }
        }

        init();

        var minDate = new Date();
        minDate.setDate(minDate.getDate() + scope.specificDeliveryDate || 0);

        if (scope.maxDeliveryDate) {
          var maxDate = new Date();
          maxDate.setDate(
            maxDate.getDate() +
              scope.specificDeliveryDate +
              scope.maxDeliveryDate +
              1,
          );
        }

        var getExcludedDates = function (date) {
          return _.result(
            _.find(scope.excludedDates, {
              date: dayjs(date).format('YYYY/MM/DD'),
            }),
            'date',
          );
        };

        scope.open = function ($event) {
          $event.stopPropagation();
          scope.pickerOpened = true;
        };

        function dateDisabled(obj) {
          var date = obj.date;
          var mode = obj.mode;
          if (mode !== 'day') return false;
          return (
            (scope.excludedWeekdays &&
              scope.excludedWeekdays.indexOf(date.getDay()) >= 0) ||
            dayjs(date).isSameOrBefore(minDate, 'day') ||
            (maxDate && dayjs(date).isSameOrAfter(maxDate, 'day')) ||
            getExcludedDates(date)
          );
        }

        scope.options = { showWeeks: false, dateDisabled: dateDisabled };

        scope.isRequired = function () {
          return !!scope.deliveryTimeRequired;
        };
        scope.$watch('deliveryDate', function (value) {
          if (
            value === undefined ||
            (value !== null &&
              dateDisabled({ date: new Date(value), mode: 'day' }))
          ) {
            element.find('input').attr('invalid-date', true);
          } else {
            element.find('input').removeAttr('invalid-date');
          }
        });
      },
    };
  },
]);
