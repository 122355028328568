app.service('ezshipService', [
  '$rootScope',
  '$http',
  'mainConfig',
  '$filter',
  function ($rootScope, $http, mainConfig, $filter) {
    this.getEzshipErrorMessage = function (orderStatus) {
      var msg;
      switch (orderStatus) {
        case 'E00':
          msg = $filter('translate')('ezship.error.invalid_input');
          break;
        case 'E01':
          msg = $filter('translate')('ezship.error.account_not_exist');
          break;
        case 'E02':
          msg = $filter('translate')('ezship.error.no_permission');
          break;
        case 'E03':
          msg = $filter('translate')('ezship.error.no_easybag');
          break;
        case 'E04':
          msg = $filter('translate')('ezship.error.invalid_store');
          break;
        case 'E05':
          msg = $filter('translate')('ezship.error.invalid_amount');
          break;
        case 'E06':
          msg = $filter('translate')('ezship.error.invalid_email');
          break;
        case 'E07':
          msg = $filter('translate')('ezship.error.invalid_mobile');
          break;
        case 'E10':
          msg = $filter('translate')('ezship.error.invalid_name');
          break;
        case 'E98':
        case 'E99':
          msg = $filter('translate')('ezship.error.system_error');
          break;
      }
      return msg;
    };
  },
]);
