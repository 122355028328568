import './services.money-services';
import './services.sl-payment';
import './services.logger';
app.directive('memberCenterCreditCardShoplinePaymentV2', [
  'logger',
  'mainConfig',
  'slPaymentService',
  'moneyService',
  function (logger, mainConfig, slPaymentService, moneyService) {
    return {
      restrict: 'A',
      scope: {
        onSdkInit: '&',
      },
      link: function (scope) {
        try {
          const currency = moneyService.toMoney({
            cents: moneyService.dollarsToCents(
              1,
              mainConfig.merchantData.base_currency_code,
            ),
            currency_iso: mainConfig.merchantData.base_currency_code,
          });
          const amount =
            currency.currency_iso === 'TWD'
              ? currency.cents * 100
              : currency.cents;

          const paymentConfig = {
            ...slPaymentService.getDefaultPaymentConfig({}),
            amount,
            paymentMethod: 'CreditCard',
            customerToken: '',
            paymentInstrument: {
              bindCard: {
                enable: true,
                protocol: {
                  mustAccept: true,
                  textType: {
                    paymentAgreement: true,
                  },
                  defaultSwitchStatus: false,
                  switchVisible: true,
                },
              },
            },
          };

          slPaymentService
            .renderSDK({
              paymentConfig,
              elementId: '#member-credit-card-shopline-payment-v2-form',
            })
            .then((data) => {
              scope.onSdkInit({ value: data });
            });
        } catch (e) {
          logger.error(e);
        }
      },
    };
  },
]);
