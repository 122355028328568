app.directive('collapseBottom', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        content: '@',
        expandHint: '@',
        defaultCollapsed: '=',
      },
      templateUrl: require('../../../../../../public/themes/shared/components/templates.collapse-bottom.html'),
      link: function (scope, element) {
        scope.isExpanded = false;
        const MAX_CONTENT_HEIGHT = 100;

        scope.initCollapseBottom = function () {
          const scrollHeight = getScrollHeight();
          scope.showButton = scrollHeight > MAX_CONTENT_HEIGHT;
        };

        scope.onCollapseOpen = function () {
          scope.showButton = false;
          scope.isExpanded = true;
        };

        scope.$watch(
          function () {
            const contentScrollHeight = getScrollHeight();
            return contentScrollHeight;
          },
          function () {
            if (scope.defaultCollapsed === false) {
              scope.showButton = false;
              scope.isExpanded = true;
              return;
            }
            scope.isExpanded = false;
            scope.showButton = false;
            $timeout(scope.initCollapseBottom, 500);
          },
        );

        function getScrollHeight() {
          const contentScrollHeight = element.find(
            '.collapse-bottom__content',
          )[0];
          return contentScrollHeight?.scrollHeight || 0;
        }
      },
    };
  },
]);
