app.service('creditCardServices', [
  function () {
    this.creditCardDateFormat = function (value) {
      var date = value;

      if (value.indexOf('/') !== -1) {
        var dateAray = value.split('/');
        date = dateAray[0].slice(0, 2) + '/' + dateAray[1].slice(0, 2);
      } else if (value.length > 2) {
        date = value.slice(0, 2) + '/' + value.slice(2);
      }

      return date;
    };

    return this;
  },
]);
