import './services.tracker';
import './services.merchant';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../constants/signupLoginEventsTracking';

app.controller('UsersWelcomeController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  'merchantService',
  '$location',
  '$filter',
  'mainConfig',
  'trackerService',
  'redirectTo',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    merchantService,
    $location,
    $filter,
    mainConfig,
    trackerService,
    redirectTo,
  ) {
    setTimeout(function () {
      var path = redirectTo;
      if (!angular.isUndefined(path) && path != '') {
        $window.location.href = path;
      } else {
        $window.location.href = '/';
      }
    }, 5000);

    const trackThirdPartySignUpMethod = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.toString()) {
        return;
      }

      const socialPlatform = urlParams.get('platform');
      const loginOrSignUp = urlParams.get('type');

      if (loginOrSignUp === 'signup' && socialPlatform) {
        trackerService.track({
          type: trackerService.generalEventType.MemberSignUpSucceed,
          data: {
            event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
            property: PROPERTY_FOR_USER_FLOW.sign_up_option,
            value: socialPlatform,
          },
        });
      }
    };

    trackThirdPartySignUpMethod();

    $scope.seconds = 5;
    trackerService.track({
      type: trackerService.generalEventType.REGISTRATION,
    });
    trackerService.fbSignup($rootScope.currentUser._id);

    var timer = setInterval(function () {
      if ($scope.seconds != 0) {
        $scope.$apply(($scope.seconds -= 1));
      } else {
        clearInterval(timer);
      }
    }, 1000);
  },
]);
