app.directive('intlTelInputAsyncEvent', [
  function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        if (attrs.intlTelInputAsyncEvent)
          scope.$emit(attrs.intlTelInputAsyncEvent, { element, attrs });
        else scope.$emit('intlTelInputAsyncEvent', { element, attrs });
      },
    };
  },
]);
