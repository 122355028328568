export const BUTTON_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled',
};

export const CART_STEPS = {
  DELIVERY: 1,
  DESTINATION: 2,
  SUMMARY: 3,
};

export const NEXT_STEP_EVENT_TYPE = {
  NEXT: 'next',
  FOCUS: 'focus',
};
