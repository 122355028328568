app.directive('slLoadingSpinner', [
  function () {
    return {
      templateUrl: require('../../../../../public/themes/shared/templates.sl_loading_spinner.html'),
      restrict: 'EA',
      scope: {
        size: '@',
        color: '@',
      },
      link: function (scope) {
        scope.spinnerSize = scope.size || 24;
        scope.spinnerColor = scope.color || '#5087e6';
      },
    };
  },
]);
