import './services.location';
import './services.pnotify';
import './services.recaptcha';
import './services.mobile-phone';
import './constants.custom-validator';
import { pick } from 'lodash-es';
import { SIGNUP_METHOD } from '../../constants/userSetting';
import { QUOTA_LIMIT_ERROR_TYPE } from '../constants/quota_limit';

app.controller('PasswordsNewController', [
  '$window',
  '$scope',
  '$http',
  '$filter',
  'mainConfig',
  'resource',
  'mobileSignInStep',
  'signUpMethod',
  'customValidator',
  'mobilePhoneService',
  'RecaptchaService',
  'pnotifyService',
  'locationService',
  function (
    $window,
    $scope,
    $http,
    $filter,
    mainConfig,
    resource,
    mobileSignInStep,
    signUpMethod,
    customValidator,
    mobilePhoneService,
    RecaptchaService,
    pnotifyService,
    locationService,
  ) {
    $scope.resource = resource.data;
    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.mobileSignInStep = mobileSignInStep;
    $scope.emailMobileRegex = customValidator.EMAIL_MOBILE_REGEX;

    const queryParams = locationService.getQueryParams();
    const memberRelatedParams = pick(queryParams, [
      'registered_by',
      'registered_by_channel_id',
      'member_referral_code',
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_content',
      'utm_term',
    ]);

    if (signUpMethod === SIGNUP_METHOD.EMAIL) {
      $scope.$watch('resource.email', function (newVal, oldVal) {
        if (newVal != oldVal) {
          $scope.userForm.email.$setValidity('general', true);
        }
      });
    }

    $scope.$on('mobile-step-change', function (event, step) {
      if (step === undefined) {
        $scope.resource.mobilePhone = undefined;
        $scope.resource.mobilePhoneOrEmail = undefined;
        $scope.userForm.submitted = false;
      }

      $scope.mobileSignInStep = step;
    });

    $scope.goBack = function ($event) {
      RecaptchaService.reset('forgot-pw-recaptcha');
      $event.preventDefault();
      $scope.userForm.errorMessages = null;
      $window.history.back();
    };

    var checkUserExistenceByPhone = function () {
      mobilePhoneService
        .checkUserExistenceByPhone({
          mobilePhone: $scope.resource.mobilePhone,
          countryCallingCode: $scope.resource.countryCallingCode,
        })
        .then(
          function () {
            $scope.userForm.errorMessages = null;
            $scope.$broadcast('mobile-step-change', 'check_mobile');
          },
          function () {
            $scope.userForm.errorMessages = [
              $filter('translate')('session.forget_passowrd.check_phone.error'),
            ];
          },
        )
        .finally(function () {
          $scope.userForm.submitting = false;
        });
    };

    var resetPassword = function () {
      $http({
        method: 'POST',
        url: '/api/users/password',
        data: {
          user: $scope.resource,
          recaptchable: true,
          ...memberRelatedParams,
        },
      })
        .then(function (res) {
          $scope.userForm.submitting = false;
          $scope.userForm.errorMessages = null;
          $scope.userForm.successMessages = [res.data.message];
        })
        .catch(function (res) {
          $scope.errorMsg = $filter('translate')(
            'verification.email.trial_merchant.reach_limit.failure',
          );
          const data = res?.data || {};
          if (
            data.error_code ===
            QUOTA_LIMIT_ERROR_TYPE.TRIAL_EMAIL_QUOTA_EXCEEDED
          ) {
            pnotifyService.notify($scope.errorMsg, {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            });
          } else {
            $scope.userForm.errorMessages = data.error_messages || [
              data.message,
            ];
          }

          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('forgot-pw-recaptcha');
        });
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }
      $scope.userForm.submitting = true;
      $scope.userForm.submitted = true;
      $scope.userForm.errorMessages = null;
      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }

      switch (signUpMethod) {
        case SIGNUP_METHOD.MOBILE:
        case SIGNUP_METHOD.EMAIL_AND_MOBILE: {
          // currently emailMobileRegex not handle all number
          if (
            customValidator.EMAIL_REGEX.test($scope.resource.mobilePhoneOrEmail)
          ) {
            $scope.resource.email = $scope.resource.mobilePhoneOrEmail;
            resetPassword();
          } else {
            $scope.resource.mobilePhone = $scope.resource.mobilePhoneOrEmail;
            checkUserExistenceByPhone();
          }
          break;
        }
        case SIGNUP_METHOD.EMAIL: {
          resetPassword();
          break;
        }
        default: {
          break;
        }
      }
    };
  },
]);
