app.directive('checkoutShoplinePayment', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope) {
        return $.getScript(
          'https://cdn.checkout.com/js/framesv2.min.js',
          function () {
            var Frames = window.Frames;
            var form = document.getElementById('payment-form');
            var iconRoute =
              'https://s3-ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/assets/checkout_payment/';
            var cardInfoError = form.getAttribute('card-info-error');
            var inputError = form.getAttribute('input-error');
            var init = true;
            Frames.init({
              publicKey: form.getAttribute('public-key'),
              localization: {
                cardNumberPlaceholder: form.getAttribute('card-number-text'),
                cvvPlaceholder: form.getAttribute('cvc-text'),
                expiryMonthPlaceholder: 'MM',
                expiryYearPlaceholder: 'YY',
              },
            });
            var logos = {
              'card-name': {
                src: 'card-name',
                alt: 'card name log',
              },
              'card-number': {
                src: 'card',
                alt: 'card number logo',
              },
              'expiry-date': {
                src: 'exp-date',
                alt: 'expiry date logo',
              },
              cvv: {
                src: 'cvv',
                alt: 'cvv logo',
              },
            };
            Frames.addEventHandler(
              Frames.Events.FRAME_VALIDATION_CHANGED,
              onValidationChanged,
            );
            function onValidationChanged(event) {
              var e = event.element;
              if (event.isValid || event.isEmpty) {
                if (e === 'card-number' && !event.isEmpty) {
                  showPaymentMethodIcon();
                }
                setDefaultIcon(e);
                clearError(e);
              } else {
                if (e === 'card-number') {
                  clearPaymentMethodIcon();
                }
                setDefaultErrorIcon(e);
                setErrorMessage(e);
                init = false;
              }
            }
            function clearError(el) {
              var selector = '.error-message__' + el;
              var message = document.querySelector(selector);
              var inputContainer = document.querySelector(
                '.' + el + '-container .input-container',
              );
              message.textContent = '';
              inputContainer && inputContainer.classList.remove('error');
            }
            function showPaymentMethodIcon(parent, paymentMethod) {
              if (parent) parent.classList.add('show');
              var logo = document.getElementById('logo-payment-method');
              if (paymentMethod) {
                var name = paymentMethod.toLowerCase();
                logo.setAttribute('src', iconRoute + name + '.svg');
                logo.setAttribute('alt', paymentMethod || 'payment method');
              }
              logo.style.removeProperty('display');
            }
            function clearPaymentMethodIcon(parent) {
              if (parent) parent.classList.remove('show');
              var logo = document.getElementById('logo-payment-method');
              logo.style.setProperty('display', 'none');
            }
            function setErrorMessage(el) {
              var selector = '.error-message__' + el;
              var message = document.querySelector(selector);
              var inputContainer = document.querySelector(
                '.' + el + '-container .input-container',
              );
              message.textContent = errorText(el);
              inputContainer.classList.add('error');
            }
            function setDefaultIcon(el) {
              var selector = 'icon-' + el;
              var logo = document.getElementById(selector);
              logo.setAttribute('src', iconRoute + logos[el].src + '.svg');
              logo.setAttribute('alt', logos[el].alt);
            }
            function setDefaultErrorIcon(el) {
              var selector = 'icon-' + el;
              var logo = document.getElementById(selector);
              logo.setAttribute(
                'src',
                iconRoute + logos[el].src + '-error.svg',
              );
              logo.setAttribute('alt', logos[el].alt);
            }
            Frames.addEventHandler(
              Frames.Events.CARD_VALIDATION_CHANGED,
              cardValidationChanged,
            );
            function cardValidationChanged(event) {
              if (!init) {
                Object.keys(event.isElementValid).forEach(function (element) {
                  var elementName = element;
                  if (element === 'cardNumber') {
                    elementName = 'card-number';
                  } else if (element === 'expiryDate') {
                    elementName = 'expiry-date';
                  }
                  if (event.isElementValid[element]) {
                    setDefaultIcon(elementName);
                    clearError(elementName);
                  } else {
                    setDefaultErrorIcon(elementName);
                    setErrorMessage(elementName);
                  }
                });
              }
            }
            Frames.addEventHandler(
              Frames.Events.CARD_TOKENIZATION_FAILED,
              onCardTokenizationFailed,
            );
            function onCardTokenizationFailed() {
              scope.errors.checkout = cardInfoError;
              scope.renderErrors();
            }
            Frames.addEventHandler(
              Frames.Events.CARD_TOKENIZED,
              onCardTokenized,
            );
            function onCardTokenized(event) {
              scope.cardData = event;
              scope.handleShoplinePaymentCheckout();
            }
            Frames.addEventHandler(
              Frames.Events.PAYMENT_METHOD_CHANGED,
              paymentMethodChanged,
            );
            function paymentMethodChanged(event) {
              var paymentMethod = event.paymentMethod;
              var container = document.querySelector(
                '.icon-container.payment-method',
              );
              if (!paymentMethod) {
                clearPaymentMethodIcon(container);
              } else {
                showPaymentMethodIcon(container, paymentMethod);
              }
            }

            scope.$on('cardholdername.invalid', function () {
              setErrorMessage('card-name');
              setDefaultErrorIcon('card-name');
            });

            var cardHolderName = document.querySelector(
              '.card-name-container #cardholdersname',
            );
            cardHolderName.addEventListener('input', resetCardHolderField);
            function resetCardHolderField() {
              var regex = /[0-9]+/;
              if (regex.test(this.value) == true) {
                this.value = this.value.replace(/[0-9]/g, '');
              }

              if (this.value == '') {
                setErrorMessage('card-name');
                setDefaultErrorIcon('card-name');
              } else {
                setDefaultIcon('card-name');
                clearError('card-name');
              }
            }

            var errorText = function (el) {
              if (el === 'card-name') {
                var fieldName = document.querySelector(
                  '.card-name-container .title-text',
                );
                return $filter('translate')('form.validation.required', {
                  field_name: fieldName.textContent,
                });
              } else {
                return inputError;
              }
            };
          },
        );
      },
    };
  },
]);
