import './services.logger';
import { BIND_CARD_REDIRECT_QUERY_PARAMS } from '../constants/productSubscriptionSlpaymentCreditCardUpdate';

app.directive('slCreditCardButton', [
  '$rootScope',
  '$uibModal',
  '$filter',
  '$q',
  'order',
  '$timeout',
  'logger',
  function ($rootScope, $uibModal, $filter, $q, order, $timeout, logger) {
    return {
      restrict: 'C',
      link: function (scope, element) {
        var loadScript = function (u) {
          var d, deferred, o, s, t;
          d = document;
          t = 'script';
          o = d.createElement(t);
          s = d.getElementsByTagName(t)[0];
          o.src = '//' + u;
          deferred = $q.defer();
          o.addEventListener(
            'load',
            function () {
              deferred.resolve();
            },
            false,
          );
          s.parentNode.insertBefore(o, s);
          return deferred.promise;
        };

        var openStripeCreditCardFormModal = function (newScope) {
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/shared/order/templates.stripe_credit_card_update_form.modal.html'),
            scope: newScope,
            keyboard: false,
            backdrop: 'static',
            controller: [
              '$http',
              '$scope',
              '$window',
              '$uibModalInstance',
              function ($http, $scope, $window, $uibModalInstance) {
                $scope.isStripeLoading = true;
                $scope.creditCardForm = {
                  holdername: '',
                };
                loadScript('js.stripe.com/v3/').then(function () {
                  $scope.isStripeLoading = false;

                  var stripe = {};
                  if (order.stripe_user_id)
                    stripe.api = Stripe(order.stripe_publishable_key, {
                      stripeAccount: order.stripe_user_id,
                    });
                  else stripe.api = Stripe(order.stripe_publishable_key);
                  stripe.elements = stripe.api.elements();

                  var card = {
                    number: {
                      type: 'cardNumber',
                      elementId: '#stripe-card-number',
                      options: {
                        placeholder: '',
                      },
                    },
                    expiry: {
                      type: 'cardExpiry',
                      elementId: '#stripe-card-expiry',
                      options: {
                        placeholder: 'MM/YY',
                      },
                    },
                    cvc: {
                      type: 'cardCvc',
                      elementId: '#stripe-card-cvc',
                      options: {
                        placeholder: '',
                      },
                    },
                  };

                  _.each(card, function (data, key) {
                    stripe[key] = stripe.elements.create(
                      data.type,
                      data.options,
                    );
                    stripe[key].mount(data.elementId);
                    stripe[key].on('focus', function () {
                      $(data.elementId)
                        .closest('.form-group')
                        .removeClass('has-error');
                    });
                    stripe[key].on('change', function (event) {
                      if (event.error) {
                        $(data.elementId)
                          .closest('.form-group')
                          .addClass('has-error');
                      }
                    });
                  });

                  var validateForm = function () {
                    $('.Modal-credit-card')
                      .find('.StripeElement--empty,.StripeElement--invalid')
                      .each(function () {
                        $(this).closest('.form-group').addClass('has-error');
                      });
                    return (
                      $scope.stripeCreditCardForm.$valid &&
                      $('.Modal-credit-card').find('.form-group.has-error')
                        .length === 0
                    );
                  };

                  $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                  };

                  $scope.submitConfirm = function () {
                    $scope.stripeCreditCardForm.submitted = true;
                    $scope.stripeCreditCardForm.loading = true;
                    $scope.stripeCreditCardForm.errorMessage = '';

                    if (!validateForm()) {
                      $scope.stripeCreditCardForm.loading = false;
                      return;
                    }

                    // stripe charge process
                    stripe.api
                      .createPaymentMethod({
                        type: 'card',
                        card: stripe.number,
                        billing_details: {
                          name: $scope.creditCardForm.holdername,
                        },
                      })
                      .then(function (result) {
                        if (result.error) {
                          logger.error(
                            'create stripe payment method failed:',
                            result.error,
                          );
                        } else {
                          return $http({
                            method: 'POST',
                            url: '/stripe/payment_requests/request_payment_intent',
                            data: {
                              payment_intent: {
                                order_id: $scope.order._id,
                                payment_method_id: result.paymentMethod.id,
                                three_d_secure:
                                  result.paymentMethod.card.three_d_secure_usage
                                    .supported,
                              },
                            },
                          });
                        }
                      })
                      .then(function (result) {
                        $scope.stripePaymentIntentId =
                          result.data.payment_intent.id;
                        var clientSecret =
                          result.data.payment_intent &&
                          result.data.payment_intent.client_secret;
                        if (clientSecret) {
                          return stripe.api.confirmCardPayment(clientSecret);
                        }
                      })
                      .then(function () {
                        var payment_intent_data = {
                          payment_intent: { id: $scope.stripePaymentIntentId },
                        };
                        return $http({
                          method: 'POST',
                          url:
                            '/api/order_payments/' +
                            order.order_payment_id +
                            '/charge',
                          data: payment_intent_data,
                        });
                      })
                      .then(function () {
                        $window.location.reload();
                      })
                      .catch(function (err) {
                        if (err.data && err.data.message) {
                          $scope.stripeCreditCardForm.errorMessage =
                            err.data.message;
                        } else {
                          console.log('stripe repay process err:', err);
                        }
                      })
                      .finally(function () {
                        $scope.stripeCreditCardForm.loading = false;
                        $scope.$apply();
                      });
                  };
                });
              },
            ],
          });
        };

        var openCreditCardFormModal = function (newScope) {
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/update.credit-card.modal.html'),
            scope: newScope,
            keyboard: false,
            backdrop: 'static',
            windowClass: 'Credit-card-modal',
            controller: [
              '$scope',
              '$uibModalInstance',
              '$http',
              '$window',
              'creditCardServices',
              'tappayService',
              'staticImageHost',
              function (
                $scope,
                $uibModalInstance,
                $http,
                $window,
                creditCardServices,
                tappayService,
                staticImageHost,
              ) {
                $scope.staticImageHost = staticImageHost;
                $scope.creditCard = {};
                $scope.creditCardForm = {};

                $scope.onExpiryDateKeyup = function ($event) {
                  var value = $event.currentTarget.value;
                  //mobile input do nothing because input focus to last
                  if (value.indexOf('/') != -1) {
                    var dateAray = value.split('/');
                    if (dateAray[0].length <= 2 && dateAray[1].length <= 2) {
                      return;
                    }
                  }

                  $scope.creditCard.expiry_date =
                    creditCardServices.creditCardDateFormat(value);
                };

                $scope.onCVCKeyup = function ($event) {
                  var cvcVal = $event.currentTarget.value;
                  var keyVal = cvcVal.substr(cvcVal.length - 1, 1);
                  var hideCvcVal = $scope.creditCard.cvc || '';

                  //Judgment to remove cvc value use keyboard 'delete' or 'backspace', if get last value '·' or '' is false
                  if (keyVal != '·' && keyVal != '') {
                    if (cvcVal.length <= 4) {
                      hideCvcVal += keyVal;
                    }
                  } else {
                    hideCvcVal = hideCvcVal.substr(0, hideCvcVal.length - 1);
                  }

                  $scope.creditCard.cvcVal = hideCvcVal.replace(/./g, '·');
                  $scope.creditCard.cvc = hideCvcVal;
                };

                $scope.onNonDigitalKeypress = function ($event) {
                  var keyCode = $event.keyCode || $event.charCode;
                  //Ignore delete, backspace, left and right keys
                  if (
                    /[^\d]/.test(String.fromCharCode(keyCode)) ||
                    _.contains([8, 37, 39, 46], keyCode)
                  ) {
                    $event.preventDefault();
                  }
                };

                $scope.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };

                $scope.submitConfirm = function () {
                  $scope.creditCardForm.submitted = true;
                  $scope.creditCardForm.loading = true;
                  $scope.creditCardForm.errorMessage = '';

                  if (!$scope.creditCardForm.$valid) {
                    $scope.creditCardForm.loading = false;
                    return;
                  }

                  if (
                    _.contains(
                      [
                        'taishin',
                        'sinopac',
                        'new_sinopac',
                        'taishin_subscription',
                        'new_sinopac_subscription',
                      ],
                      $scope.order.payment_gateway,
                    )
                  ) {
                    tappayService
                      .getPrime()
                      .then(function (result) {
                        $scope.tappayResult = { tappay_result: result };
                        if ($scope.creditCard.holdername) {
                          _.extend($scope.tappayResult, $scope.creditCard);
                        }
                        chargeProcess();
                      })
                      .catch(function (error) {
                        const tappayFieldsStatus =
                          tappayService.getTappayFieldsStatus();
                        logger.error(
                          '[Order][TappayService] getPrime error occurred:',
                          { getPrime: error, tappayFieldsStatus },
                        );
                      });
                  } else {
                    chargeProcess();
                  }

                  function chargeProcess() {
                    $http({
                      method: 'POST',
                      url:
                        '/api/order_payments/' +
                        order.order_payment_id +
                        '/charge',
                      data: $scope.tappayResult || $scope.creditCard,
                    })
                      .then(
                        function (res) {
                          if (res.data.data.payment_url) {
                            $window.location.href = res.data.data.payment_url;
                          } else if (res.status === 200) {
                            $window.location.reload();
                          }
                        },
                        function (res) {
                          $scope.creditCardForm.errorMessage = res.data.message;
                        },
                      )
                      .finally(function () {
                        $scope.creditCardForm.submitted = false;
                        $scope.creditCardForm.loading = false;
                      });
                  }
                };

                $timeout(() => {
                  window.TPDirect.card.setup({
                    fields: {
                      number: {
                        element: '#payment-credit-card-number',
                      },
                      expirationDate: {
                        element: '#payment-credit-card-holder-expiry',
                      },
                      ccv: {
                        element: '#payment-credit-card-holder-cvc',
                      },
                    },
                    styles: {
                      input: {
                        color: '#555',
                      },
                      '.valid': {
                        color: 'green',
                      },
                      '.invalid': {
                        color: 'red',
                      },
                    },
                  });
                });
              },
            ],
          });
        };

        const openSlpFormModal = (newScope) => {
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/shared/order/templates.slp_credit_card_update_form.modal.html'),
            scope: newScope,
            backdrop: 'static',
            controller: 'SlpRepayFormModalController',
          });
        };

        const openSlpUpdateCardForProductSubscriptionFormModal = (newScope) => {
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/shared/order/templates.product_subscription_slp_credit_card_update.html'),
            scope: newScope,
            backdrop: 'static',
            controller: 'SlpUpdateCardForProductSubscriptionModalController',
          });
        };

        const getBindCardUrlParams = () => {
          const urlParams = new URLSearchParams(window.location.search);
          const status = urlParams.get(BIND_CARD_REDIRECT_QUERY_PARAMS.STATUS);
          const authorization_token = urlParams.get(
            BIND_CARD_REDIRECT_QUERY_PARAMS.AUTHORIZATION_TOKEN,
          );

          // enter order page by email link, cannot directly get urlPollingId by params
          // redirect URL might look like this:
          // authorization_token=hwggZSliZgCEkpptwxPCzg%3Fpolling_id%3D662f47b64ca2ca0a007d83d71719195868157&
          // after decodeURIComponent:
          // ?authorization_token=hwggZSliZgCEkpptwxPCzg?polling_id=662f47b64ca2ca0a007d83d71719195868157&appId=670&referenceOrderId=70abfc4e54549007cc3f&status=FAILED&tradeOrderId=10010062429011811138834104320
          const urlPollingId = authorization_token
            ? new URLSearchParams(authorization_token.split('?')[1]).get(
                BIND_CARD_REDIRECT_QUERY_PARAMS.POLLING_ID,
              )
            : urlParams.get(BIND_CARD_REDIRECT_QUERY_PARAMS.POLLING_ID);

          return { urlPollingId, status };
        };

        element.on('click', function () {
          var newScope = scope.$new();
          newScope.order = order;

          if (!$rootScope.currentUser) {
            newScope.modal = {
              title: $filter('translate')('order.payments.login.confirm'),
            };

            $uibModal.open({
              templateUrl: require('../../../../../public/themes/v1/default/views/confirm.modal.html'),
              scope: newScope,
              controller: [
                '$scope',
                '$uibModalInstance',
                '$window',
                function ($scope, $uibModalInstance, $window) {
                  $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                  };

                  $scope.submitConfirm = function () {
                    $uibModalInstance.close();
                    $window.location.href =
                      '/users/sign_in' + $window.location.search;
                  };
                },
              ],
            });

            return;
          }

          if (order.product_subscription_paid_card_changeable) {
            openSlpUpdateCardForProductSubscriptionFormModal(newScope);
            return;
          }

          switch (order.payment_gateway) {
            case 'stripe':
              openStripeCreditCardFormModal(newScope);
              break;
            case 'shopline_payment': {
              const { urlPollingId, status } = getBindCardUrlParams();
              // If both urlPollingId and status are present, click then do not call open modal
              if (urlPollingId && status) return;
              openSlpFormModal(newScope);
              break;
            }
            default:
              openCreditCardFormModal(newScope);
              break;
          }
        });

        const checkRedirectUrlStatus = () => {
          if (!$rootScope.currentUser) return;
          const newScope = scope.$new();
          newScope.order = order;
          const { urlPollingId, status } = getBindCardUrlParams();

          if (!urlPollingId || !status) return;
          openSlpUpdateCardForProductSubscriptionFormModal(newScope);
        };
        checkRedirectUrlStatus();
      },
    };
  },
]);
