app.service('browserService', [
  '$window',
  function ($window) {
    return {
      isInAppBrowser: function () {
        var userAgent = $window.navigator.userAgent;
        var regex = 'FBAN|FBAV|Line|Instagram|MicroMessenger';
        return userAgent.match(regex);
      },

      isSafariMobileBrowser: function () {
        var userAgent = $window.navigator.userAgent;
        return userAgent.match(/Safari/) && userAgent.match(/iPad|iPhone/);
      },
      isMobile: function () {
        return window.matchMedia('(max-width: 480px)').matches;
      },
      isPad: function () {
        return window.matchMedia('(max-width: 767px)').matches;
      },
    };
  },
]);
