app.directive('deliveryFormThreeLayerOption', [
  '$rootScope',
  '$timeout',
  '$compile',
  '$filter',
  'staticResourceHost',
  '$http',
  function (
    $rootScope,
    $timeout,
    $compile,
    $filter,
    staticResourceHost,
    $http,
  ) {
    return {
      restrict: 'A',
      scope: {
        country: '@',
        shouldShowPostcode: '@',
      },
      link: function (scope, element) {
        $timeout(function () {
          var country = scope.country.toLowerCase();
          var postcodeCountries = ['th'];
          scope.shouldShowPostcode = postcodeCountries.includes(country);
          _.range(1, 4).forEach(function (n) {
            attachLayerNgSkeleton(n);
          });

          function attachLayerNgSkeleton(layerNumber) {
            var $layer = element.find(
              '.sl-delivery-address-layer-' + layerNumber,
            );
            var layerN = 'layer' + layerNumber;

            $layer
              .attr('ng-model', layerN)
              .attr(
                'ng-options',
                layerN +
                  ' as ' +
                  layerN +
                  '.displayName for ' +
                  layerN +
                  ' in listOfLayer' +
                  layerNumber +
                  ' track by ' +
                  layerN +
                  '.displayName',
              )
              .attr('ng-change', 'onLayer' + layerNumber + 'Change()')
              .attr('ng-disabled', '!listOfLayer' + layerNumber + '.length');

            $compile($layer)(scope);
          }

          function resetLayers() {
            scope.layer2 = null;
            scope.layer3 = null;
            scope.listOfLayer2 = null;
            scope.listOfLayer3 = null;
            $('#order-delivery-layer2').val('');
            $('#order-delivery-layer3').val('');
          }
          scope.onLayer1Change = function () {
            resetLayers();
            getRemainLayerOptions();
            $rootScope.$broadcast('tax.fee.address.changed');
            $('#order-delivery-layer1').val(scope.layer1.data.key);
          };

          scope.onLayer2Change = function () {
            scope.listOfLayer3 = generateListOfThirdLayer();
            $rootScope.$broadcast('tax.fee.address.changed');
            $('#order-delivery-layer2').val(scope.layer2.key);
          };

          scope.onLayer3Change = function () {
            $('#order-delivery-layer3').val(scope.layer3.key);
          };

          function generateListOfFirstLayer(datas) {
            return _.map(datas, function (data) {
              return {
                data: data.data,
                displayName: $filter('translateModel')(data.data),
              };
            });
          }

          function generateListOfSecondLayer(data) {
            return _.map(data.districts, function (district) {
              return {
                key: district.key,
                displayName: $filter('translateModel')(district),
                wards: district.wards,
              };
            });
          }

          function generateListOfThirdLayer() {
            if (!scope.layer2 || !scope.layer2.wards) return;
            return _.map(scope.layer2.wards, function (ward) {
              return {
                key: ward.key,
                displayName: $filter('translateModel')(ward),
              };
            });
          }

          function getFirstLayerOptions() {
            var layer1JsonUrl =
              staticResourceHost + 'web/v1/translations/districts_' + country;
            $http
              .get(layer1JsonUrl + '_v2.json?t=' + Date.now(), {
                withCredentials: false,
              })
              .then(function (res) {
                scope.listOfLayer1 = generateListOfFirstLayer(res.data);
              });
          }

          function getRemainLayerOptions() {
            if (!scope.layer1 || !scope.layer1.data || !scope.layer1.data.key)
              return;
            var remainLayerJsonUrl =
              staticResourceHost +
              'web/v1/translations/' +
              country +
              '_districts/' +
              scope.layer1.data.key +
              '.json?t=' +
              Date.now();
            $http
              .get(remainLayerJsonUrl, {
                withCredentials: false,
              })
              .then(function (res) {
                scope.listOfLayer2 = generateListOfSecondLayer(res.data);
              });
          }

          getFirstLayerOptions();
        });
      },
    };
  },
]);
