app.service('familyMartService', [
  '$http',
  function ($http) {
    const FAMILY_MART_E_MAP = 'https://mfme.map.com.tw/default.asp';

    const FAMILY_MART_DELIVERIES = [
      'tw_fm_c2c_pay',
      'tw_fm_c2c_nopay',
      'tw_fm_b2c_pay',
      'tw_fm_b2c_nopay',
      'emap_only_fm',
    ];

    const isFamilyMart = (region_type) => {
      return FAMILY_MART_DELIVERIES.includes(region_type);
    };

    const getDomainToken = () => {
      return $http({
        method: 'GET',
        url: '/domain_token',
      });
    };

    return {
      FAMILY_MART_E_MAP,
      isFamilyMart,
      getDomainToken,
    };
  },
]);
