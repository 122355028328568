app.service('efoxPayService', [
  'mainConfig',
  function (mainConfig) {
    var initScript = function () {
      $.getScript(
        mainConfig.universalPaymentSDKUrl +
          '/pay/paysdk/' +
          mainConfig.efoxPayVersion +
          '/index.js',
      );
    };
    this.pay = null;

    var initPay = function (data) {
      var defaultConfig = {
        env: mainConfig.env === 'production' ? 'prod' : 'dev',
      };
      var initialData = Object.assign({}, data, defaultConfig);
      this.pay = new efoxPay.default('', initialData);
    };

    var getToken = function () {
      if (this.pay) return this.pay.getToken();
    };

    initScript();

    return {
      initPay: initPay,
      getToken: getToken,
    };
  },
]);
