import './services.pnotify';
app.directive('formAttachmentUpload', [
  'pnotifyService',
  '$filter',
  '$http',
  function (pnotifyService, $filter, $http) {
    return {
      restrict: 'E',
      scope: {
        formId: '@',
        itemId: '@',
        label: '@',
        isRequired: '@',
      },
      require: ['^form'],
      templateUrl: require('../../../../../public/themes/v1/default/views/form.attachment-upload.html'),
      link: function ($scope, element, attrs, ctrls) {
        var formController = ctrls[0];
        var reset = function () {
          $scope.hasError = false;
          $scope.attachments = [];
          $scope.attachmentIcons = [];
          $scope.attachmentNames = [];
        };

        reset();
        $scope.$on('form.response.submit', function () {
          reset();
        });

        $scope.$watch(function () {
          return validate();
        });

        $scope.$watch('hasError', function () {
          if ($scope.hasError === true) {
            $scope.errorMessage = $filter('translate')(
              'form.validation.required',
              { field_name: $scope.label },
            );
            return;
          }
        });

        var validate = function () {
          if (
            (formController.$submitted || formController.submitted) &&
            $scope.isRequired === 'true'
          ) {
            $scope.hasError = $scope.attachments.length === 0;
            formController.$invalid = $scope.attachments.length === 0;
          } else {
            $scope.hasError = false;
          }
        };

        $scope.openFile = function () {
          var input = element.find('input');
          input.trigger('click');
        };

        $scope.deleteAttachment = function (media) {
          var index = $scope.attachments.indexOf(media);

          $scope.attachments.splice(index, 1);
          $scope.attachmentIcons.splice(index, 1);
          $scope.attachmentNames.splice(index, 1);
        };

        $scope.onFileSelect = function ($files) {
          $scope.flash = [];
          if ($files.length == 0) {
            pnotifyService.notify(
              $filter('translate')('media.validation.type'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
            return;
          }

          if ($files.length > 1) {
            pnotifyService.notify(
              $filter('translate')('media.validation.file_limit.one'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
            return;
          }

          $scope.attachmentUploading = true;

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            $scope.uploadAttachment(file);
          }
        };

        $scope.uploadAttachment = function (attachment) {
          if (attachment.size / 1024 / 1024 > 2) {
            pnotifyService.notify(
              $filter('translate')(
                'media.validation.error.file_attachment_size',
              ),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
            $scope.attachmentUploading = false;
            return;
          }

          $http({
            method: 'POST',
            url:
              '/forms/' +
              $scope.formId +
              '/items/' +
              $scope.itemId +
              '/upload_attachment',
            headers: { 'Content-Type': undefined },
            data: { data: attachment },
            transformRequest: function (data) {
              var formData = new FormData();
              angular.forEach(data, function (value, key) {
                formData.append(key, value);
              });
              return formData;
            },
          })
            .then(function (res) {
              var data = res.data;
              $scope.attachments.push(data.data);

              var attachmentIcon = $scope.getAttachmentIcon(attachment.type);
              $scope.attachmentIcons.push(attachmentIcon);

              $scope.attachmentNames.push(attachment.name);
            })
            .catch(function () {
              pnotifyService.notify(
                $filter('translate')(
                  'media.validation.error.file_attachment_size',
                ),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            })
            .finally(function () {
              $scope.attachmentUploading = false;
            });
        };

        $scope.getAttachmentIcon = function (type) {
          // refs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
          switch (type) {
            case 'application/pdf':
              return 'fa fa-file-pdf-o';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'fa fa-file-text-o';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'text/csv':
              return 'fa fa-file-excel-o';
            case 'image/gif':
            case 'image/jpeg':
            case 'image/png':
              return 'fa fa-file-image-o';
            default:
              return '';
          }
        };
      },
    };
  },
]);
