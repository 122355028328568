import './services.logger';
app.directive('checkoutShoplinePaymentCashier', [
  '$filter',
  '$http',
  'mainConfig',
  'logger',
  'cart',
  'currentLocale',
  function ($filter, $http, mainConfig, logger, cart, currentLocale) {
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope) {
        return $.getScript(
          mainConfig.shoplinePaymentCashierSDKUrl,
          function () {
            var NEEDED_3D_SECURE_CODE = '20205';
            var SIGNED_RESPONSE_CODE = '20600';
            var SUCCESS_RESPONSE_CODE = '10000';
            var form = document.getElementById(
              'checkout-shopline-payment-cashier-form',
            );
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 'pad'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                    navigator.userAgent,
                  )
                ? 'phone'
                : 'pc';

            var errorHandler = function (event, err) {
              scope.btnPlaceOrder.stop();
              scope.state.isCheckoutLoading = false;
              scope.errors.checkout = $filter('translate')(
                'checkout.payments_transaction_error',
              );
              scope.renderErrors();
              logger.error(event.msg || event, err || event);
            };

            var payment = cart.payment_method;
            var channelInfo =
              payment.config_data.shopline_payment_payment === 'alipay'
                ? {
                    chId: 'Alipay', // [new][AlipayHK必传] 渠道ID,
                    payMethod:
                      deviceType === 'phone' ? 'AlipayWap' : 'AlipayQrCode', // [new][AlipayHK必传] 支付方法, AlipayQrCode/AlipayWap
                  }
                : {};
            var getLocaleCode = function () {
              switch (currentLocale) {
                case 'zh-hant':
                  return 'zh-TW';
                case 'zh-cn':
                  return 'zh-CN';
                default:
                  return 'en';
              }
            };

            scope.shoplinePaymentCashierElement = new paymentElement.default(
              'checkout-shopline-payment-cashier-form',
              {
                loopTime: 6000, // 轮询时间
                env: form.getAttribute('sl-payments-env'), // [必传]当前环境 dev、prev、prod
                deviceTag: deviceType, // pc、phone、pad
                appId: form.getAttribute('app-id'), // [必传]网关分配给业务的appId
                merchant: {
                  // 商家信息
                  merchantId: mainConfig.merchantData.sl_payment_merchant_id, // [必传]商户Id
                  merchantSiteCountry:
                    mainConfig.merchantData.base_country_code, // [必传]所在国家
                  settlementCurrency:
                    mainConfig.merchantData.base_currency_code, // [必传]结算币种
                  mcc: form.getAttribute('mcc'), // [选传]商家码
                },
                themeData: {
                  version: 'v1',
                },
                lang: {
                  formComponent: {
                    holderName: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.holdername',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.holdername',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.holdername',
                          ),
                        },
                      ),
                      tips: $filter('translate')('payment.cc.holdername.hint'),
                    },
                    cardNumber: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.credit_card_number',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.credit_card_number',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.credit_card_number',
                          ),
                        },
                      ),
                      notSupportTips: $filter('translate')(
                        'checkout.card_type_not_support_error',
                      ),
                    },
                    expiryDate: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.expiry_date',
                      ),
                      expiryMonthPlaceholder: 'MM',
                      expiryYearPlaceholder: 'YY',
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.expiry_date',
                          ),
                        },
                      ),
                    },
                    cvv: {
                      labelTitle: $filter('translate')(
                        'order.payment_data.cvc',
                      ),
                      placeholder: $filter('translate')(
                        'order.payment_data.cvc',
                      ),
                      errorTips: $filter('translate')(
                        'form.validation.required',
                        {
                          field_name: $filter('translate')(
                            'order.payment_data.cvc',
                          ),
                        },
                      ),
                      tips: $filter('translate')('payment.cc.cvc.hint'),
                    },
                  },
                },
                footer:
                  mainConfig.merchantData.base_country_code === 'TW'
                    ? $filter('translate')('payment.cc.tw.cashier.footer')
                    : $filter('translate')('payment.cc.cashier.footer'),
                channelInfo: channelInfo,
                language: getLocaleCode(),
              },
            );
            scope.shoplinePaymentCashierElement.clientEventBus.addEventListener(
              'init',
              function (event) {
                if (event.code === SUCCESS_RESPONSE_CODE) {
                  logger.log(event);
                } else {
                  logger.error(event.msg || event, event);
                }
              },
            ); // for debug and can remove in the future
            scope.shoplinePaymentCashierElement.clientEventBus.addEventListener(
              'update',
              function (event) {
                if (event.code === SIGNED_RESPONSE_CODE && event.resp) {
                  $http({
                    method: 'POST',
                    url: '/api/shopline_payment/sign',
                    data: {
                      signData: event.resp.signData,
                      signToken: event.resp.signToken,
                    },
                  })
                    .then(function (res) {
                      if (res.data.result) {
                        scope.shoplinePaymentCashierElement.orderAction(
                          res.data.data,
                        );
                      } else {
                        errorHandler(event);
                      }
                    })
                    .catch(function (err) {
                      errorHandler(event, err);
                    });
                } else if (event.code === NEEDED_3D_SECURE_CODE) {
                  if (event.resp.data.action) {
                    // for adyen now
                    var shoplinePaymentCashier3DForm =
                      document.createElement('form');
                    shoplinePaymentCashier3DForm.method = 'post';
                    shoplinePaymentCashier3DForm.action =
                      event.resp.data.redirectUrl;

                    var mdInput = document.createElement('input');
                    mdInput.name = 'MD';
                    mdInput.value = event.resp.data.action.data.md;
                    shoplinePaymentCashier3DForm.appendChild(mdInput);

                    var paReqInput = document.createElement('input');
                    paReqInput.name = 'PaReq';
                    paReqInput.value = event.resp.data.action.data.paReq;
                    shoplinePaymentCashier3DForm.appendChild(paReqInput);

                    var termUrlInput = document.createElement('input');
                    termUrlInput.name = 'TermUrl';
                    termUrlInput.value = event.resp.data.action.data.termUrl;
                    shoplinePaymentCashier3DForm.appendChild(termUrlInput);
                    document.body.appendChild(shoplinePaymentCashier3DForm);
                    shoplinePaymentCashier3DForm.submit();
                  } else if (event.resp.data.redirectUrl) {
                    window.location.href = event.resp.data.redirectUrl;
                  } else {
                    errorHandler(event);
                  }
                } else if (event.code === SUCCESS_RESPONSE_CODE) {
                  if (
                    scope.shoplinePaymentCashierOrderData &&
                    scope.shoplinePaymentCashierOrderData.redirect_to
                  ) {
                    window.location.href =
                      scope.shoplinePaymentCashierOrderData.redirect_to;
                  }
                } else {
                  errorHandler(event);
                }
              },
            );
          },
        );
      },
    };
  },
]);
