const { isFunction } = require('lodash-es');

app.directive('tabButtons', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../../public/themes/shared/components/templates.tab-buttons.html'),
      scope: {
        value: '=',
        buttons: '<',
        onTabChange: '&',
        disabled: '=',
      },
      link: function (scope) {
        scope.onTabClick = function (value) {
          if (scope.disabled) return;
          scope.value = value;

          if (isFunction(scope.onTabChange)) {
            scope.onTabChange({ value });
          }
        };
      },
    };
  },
]);
