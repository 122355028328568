app.directive('safeHtml', [
  '$sanitize',
  function ($sanitize) {
    const componentTemplate = `
      <span ng-bind-html="safeHtml"></span>
    `;

    return {
      restrict: 'E',
      replace: true,
      template: componentTemplate,
      scope: {
        htmlString: '@',
      },
      link: function (scope) {
        scope.safeHtml = $sanitize(scope.htmlString);
      },
    };
  },
]);
