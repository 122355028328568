import './services.feature';
import './multi_checkout/services.multi-checkout';
import './services.orders';
import './services.member.orders';
import { onTabActive } from '../features/member-center';

app.controller('MemberOrdersController', [
  '$scope',
  '$location',
  '$rootScope',
  'memberOrdersService',
  'ordersService',
  '$element',
  'multiCheckoutService',
  '$uibModal',
  'featureService',
  function (
    $scope,
    $location,
    $rootScope,
    memberOrdersService,
    ordersService,
    $element,
    multiCheckoutService,
    $uibModal,
    featureService,
  ) {
    // init
    $scope.orderItems = [];
    $scope.ordersService = ordersService;
    $scope.searchParams = $location.search();
    $scope.checkoutObject = {};
    $scope.multiCheckoutEnabled = multiCheckoutService.isEnabled();

    $scope.pagination = {
      total: 0,
      itemsPerPage: 0,
      current: 0,
    };
    $scope.state = {
      loadingItems: false,
    };

    $scope.errors = {
      loadMoreError: false,
    };

    function getOrderItemsRequest() {
      const newPage = $scope.pagination.current + 1;

      return $scope.multiCheckoutEnabled
        ? memberOrdersService.getCheckoutObjects(newPage)
        : memberOrdersService.getOrders(newPage);
    }

    $scope.getOrders = function () {
      $scope.errors.loadMoreError = false;

      if (
        $scope.pagination.current > $scope.maxPage ||
        $scope.state.loadingItems
      ) {
        return;
      }

      $scope.state.loadingItems = true;

      getOrderItemsRequest()
        .then(function (response) {
          const { items, total, limit } = response.data.data;
          const currentItemIds = $scope.orderItems.map((item) => item._id);
          const newItems = items.filter(
            (item) => !currentItemIds.includes(item._id),
          );

          $scope.orderItems = [...$scope.orderItems, ...newItems];
          $scope.pagination.current += 1;
          $scope.pagination.total = total;
          $scope.pagination.itemsPerPage = limit;
          $scope.maxPage = Math.ceil(
            $scope.pagination.total / $scope.pagination.itemsPerPage,
          );
        })
        .catch(function () {
          $scope.errors.loadMoreError = true;
        })
        .finally(function () {
          $scope.state.loadingItems = false;
          if ($scope.searchParams.orderId) {
            $rootScope.scrollTo($($scope.searchParams.orderId));
            jQuery.sidr('open', 'cart-panel');
          }
        });
    };

    $scope.orderClass = function (orderId) {
      if (orderId === $scope.searchParams.orderId) {
        return 'order-hover active';
      } else {
        return 'order-hover';
      }
    };

    $scope.getOrders();

    $scope.getReturnDetailUrl = function (order) {
      if (order.created_by === 'pos') {
        return `/orders/${order.return_order_ids[0]}`;
      }
      return featureService.hasFeature('return_order_revamp')
        ? `/orders/${order._id}#return-orders`
        : `/return_orders/${_.last(order.return_order_ids)}`;
    };

    $scope.showPaymentDetail = function (checkoutObject) {
      const modelScope = $scope.$new();
      modelScope.checkoutObjectId = checkoutObject._id;

      $uibModal.open({
        templateUrl: require('../../../../../public/themes/shared/multi_checkout/templates.checkout-object-detail.html'),
        scope: modelScope,
        controller: 'MemberCenterCheckoutObjectDetailController',
        windowClass: 'checkout-object-detail__modal',
        backdrop: 'true',
      });
    };

    onTabActive($element, () => {
      history.replaceState(null, null, `orders${location.search}`);
    });
  },
]);
