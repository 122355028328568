import './services.checkout.js.coffee'
import './services.logger'
import './multi_checkout/services.multi-checkout'
import './services.cart'

import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { COLLAPSE_STATUS } from '../constants/components/collapse-block-status';

app.directive("checkoutCartPromotions", [
  '$rootScope'
  '$timeout'
  '$http'
  '$compile'
  'checkoutService'
  'logger'
  '$injector'
  'multiCheckoutService'
  'cartService'
  (
    $rootScope
    $timeout
    $http
    $compile
    checkoutService
    logger
    $injector
    multiCheckoutService
    cartService
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        scope.promotionCollapseStatus = COLLAPSE_STATUS.EXPANDED;
        scope.$on CHECKOUT_EVENTS.CART.PROMOTIONS.RELOAD, () ->
          element.addClass "checkout-section-loading"
          checkoutService.requestPartial('cart', 'promotions')
            .then (res) ->
              element.html($compile(presetCollapsed(res.data))(scope))
            .catch (error) ->
              logger.log("Unable to load cart promotions")
            .finally () ->
              element.removeClass "checkout-section-loading"
              scope.$emit CHECKOUT_EVENTS.CART.CONTENT.LOADED

        element.on "destroy", (() -> element.off()) # Unbind events
        element.on "click", ".btn-remove-coupon", (event) ->
          $container = angular.element(event.currentTarget).closest(".promotion")
          code = $container.attr("data-coupon-code")
          return if _.isEmpty(code)
          $rootScope.$broadcast CHECKOUT_EVENTS.CART.COUPON.REMOVE, code

        presetCollapsed = (htmlString) ->
          return htmlString unless showCollapseBlock()

          $content = $('<div>' + htmlString + '</div>');
          $content.find('collapse-block').attr('collapse-status', scope.promotionCollapseStatus);

          return $content.html();

        showCollapseBlock = () ->
          return multiCheckoutService.isEnabled() && $injector.has('showCollapseBlock') && $injector.get('showCollapseBlock');

        scope.onPromotionCollapseChange = ({ collapseStatus }) ->
          scope.promotionCollapseStatus = collapseStatus

        # on initialize
        $timeout(() ->
          scope.$emit CHECKOUT_EVENTS.CART.CONTENT.LOADED
        , 100)

        scope.removeCustomDiscount = (item_id) ->
          cartService.removeCustomDiscountItem(item_id).then () ->
            $rootScope.$broadcast CHECKOUT_EVENTS.CART.PROMOTIONS.CHANGED
    }
])
