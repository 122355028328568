app.service('quickSingUpService', [
  '$http',
  '$cookies',
  function ($http, $cookies) {
    var path = '/users/sign_up/quick/';

    this.signUp = function (phone, countryCode, subscriptionTags) {
      return $http({
        method: 'POST',
        url: path,
        data: {
          user: {
            mobile_phone: phone,
            country_calling_code: countryCode,
            subscribed_email_types: subscriptionTags,
          },
          fbc: $cookies.get('_fbc'),
          fbp: $cookies.get('_fbp'),
          recaptchable: true,
        },
      });
    };

    this.update = function (id, name, password) {
      return $http({
        method: 'PUT',
        url: path + id,
        data: { user: { name: name, password: password } },
      });
    };
  },
]);
