import './services.tcat_seven_eleven';
import './services.orders';
app.directive('checkoutDeliverySevenEleven', [
  '$compile',
  '$filter',
  'ordersService',
  'tcatSevenElevenService',
  ($compile, $filter, ordersService, tcatSevenElevenService) => {
    return {
      restrict: 'A',
      scope: {
        getFormData: '&',
      },
      link: function (scope, element, attrs) {
        scope.pickStore = function () {
          const data = scope.getFormData();
          ordersService
            .saveSessionData(data.order, {
              save_fields_phone: data.saveFields.phone,
              save_fields: data.saveFields,
              order: {
                custom_fields_translations:
                  data.order.custom_fields_translations,
              },
            })
            .then(() => {
              if (attrs.deliveryType === 'crossBorder') {
                $('#seven_eleven_cross_border_selector').submit();
                return;
              }

              if (
                tcatSevenElevenService.isTcatSevenElevenEmapType(
                  attrs.regionType,
                )
              ) {
                $('#tcat_seven_eleven_map_selector').submit();
                return;
              }

              $('#seven_eleven_map_selector').submit();
            });
        };

        if (element.length === 1) {
          element.find('.btn-pick-store').attr('ng-click', 'pickStore()');
          element.html($compile(element.html())(scope));
        }
      },
    };
  },
]);
