import './services.efox-pay';
app.directive('asiabillPayment', [
  'efoxPayService',
  function (efoxPayService) {
    return {
      restrict: 'E',
      scope: '=',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.asiabill_bill_address.html'),
      link: function (scope) {
        scope.processAsiabillPay = function (orderData) {
          var billingData = {
            city: scope.asiabillBillingCity,
            address: scope.asiabillBillingAddress,
            zip: scope.asiabillBillingPostcode,
          };
          var fieldData = Object.assign({}, orderData, billingData);
          var initialData = {
            sdkName: 'asiaBill',
            fieldData: fieldData,
          };
          efoxPayService.initPay(initialData);
          efoxPayService.getToken();
        };
      },
    };
  },
]);
