import { uniqBy } from 'lodash-es';

app.service('timeSlotService', [
  '$http',
  '$filter',
  'mainConfig',
  'slFeatureService',
  function ($http, $filter, mainConfig, slFeatureService) {
    return {
      DATE_SLOT_ENABLED: ['date', 'date_time_slots'],
      TIME_SLOT_ENABLED: ['time_slots', 'date_time_slots'],
      getTimeSlotSelectItems: function (timeSlots, options = {}) {
        const { ignoreAvailable = false } = options;
        return uniqBy(timeSlots, 'group_key').map((slot) => ({
          key: slot._id,
          groupKey: slot.group_key,
          disabled: ignoreAvailable ? false : !slot.is_available,
          description: $filter('translateModel')(slot.translations),
        }));
      },
      isFeatureEnabled: function (config_data = {}) {
        if (!slFeatureService.hasFeature('delivery_time_with_quantity')) {
          return false;
        }
        const { delivery_time_slot_enable } = config_data;
        return delivery_time_slot_enable !== undefined;
      },
      getAvailableTimeSlots: function (deliveryOptionId, date) {
        return $http({
          method: 'GET',
          url: `/api/v1/${mainConfig.merchantData.handle}/delivery_options/${deliveryOptionId}/delivery_time_slots`,
          params: { date },
        });
      },
    };
  },
]);
