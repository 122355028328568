export const SECTION_STATUS = {
  ACTIVE: 'active',
  ERROR: 'error',
  DEFAULT: 'default',
};

export const COLLAPSE_STATUS = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
};
