import './service.promotion';
app.controller('CheckoutCouponListDialogController', [
  '$scope',
  '$uibModalInstance',
  'promotionService',
  'slFeatureService',
  'staticResourceHost',
  function (
    $scope,
    $uibModalInstance,
    promotionService,
    slFeatureService,
    staticResourceHost,
  ) {
    $scope.hasCouponV2 = slFeatureService.hasFeature('coupon_v2');
    $scope.staticResourceHost = staticResourceHost;

    $scope.state = {
      loadingItems: false,
    };
    $scope.pagination = {
      limit: 4,
      page: 0,
      maxPage: 0,
    };
    $scope.coupons = [];

    var rawOffset = new Date().getTimezoneOffset() / -60;
    $scope.timezoneOffset =
      rawOffset < 0 ? rawOffset : '+' + Math.abs(rawOffset);

    var cartPromotionElement = angular.element('.cart-promotions')[0];
    var couponElements = cartPromotionElement
      ? cartPromotionElement.querySelectorAll('.promotion.coupon')
      : [];
    var appliedCodes = _.map(couponElements, function (e) {
      return angular.element(e).attr('data-coupon-code');
    });

    const timeFormat = 'YYYY/MM/DD HH:mm';
    const viTimeFormat = 'DD/MM/YYYY HH:mm';
    const getValidCoupons = function () {
      $scope.state.loadingItems = true;
      promotionService
        .getCoupons('valid')
        .then(function (res) {
          const resData = res.data.data;
          $scope.coupons.total = resData.total;
          const newCoupons = _.chain(resData.items)
            .reject(function (coupon) {
              return appliedCodes.includes(coupon.codes[0]);
            })
            .map(function (coupon) {
              return Object.assign({}, coupon, {
                formattedTime: dayjs(coupon.end_at).format(
                  $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                    ? viTimeFormat
                    : timeFormat,
                ),
              });
            })
            .value();
          $scope.coupons = $scope.coupons.concat(newCoupons);
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
      // TODO: Add catch error handle
    };
    getValidCoupons();

    $scope.shouldShowLoading = function () {
      return $scope.state.loadingItems;
    };

    $scope.cancelModal = function (coupon) {
      $uibModalInstance.close(coupon);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.getIsEmpty = () =>
      !$scope.state.loadingItems &&
      ($scope.coupons.total === 0 || $scope.coupons.length === 0);
  },
]);
