app.service('memberCreditsService', [
  '$http',
  function ($http) {
    var limit = 24;

    this.getCreditInfo = function (page) {
      return $http({
        url: '/api/user_credits',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          page: page,
          limit: limit,
        },
      });
    };
  },
]);
