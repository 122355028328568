app.directive('onFinishRenderFilters', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope) {
        if (scope.$last === true) {
          $timeout(function () {
            scope.$emit('ngRepeatFinished');
          });
        }
      },
    };
  },
]);
