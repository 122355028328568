import './services.recaptcha';
import './constants.custom-validator';
import './services.fb';
import './services.benchat';
import './services.pnotify';
import './services.feature';
app.controller('SessionsNewController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'featureService',
  'pnotifyService',
  'benchatService',
  'flash',
  'staticImageHost',
  'fbService',
  'customValidator',
  'RecaptchaService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    featureService,
    pnotifyService,
    benchatService,
    flash,
    staticImageHost,
    fbService,
    customValidator,
    RecaptchaService,
  ) {
    $scope.handleSimplifiedCircleBtnClick = function (type) {
      var $userForm = $('form[name="userForm"]');
      $scope.activeType = type;
      $userForm.show();
    };

    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.emailMobileRegex = customValidator.EMAIL_MOBILE_REGEX;
    $scope.staticImageHost = staticImageHost;

    $scope.resource = resource.data;
    $scope.resource.owner_id = mainConfig.merchantData._id;

    const queryString = $location.search();
    if (queryString['ui'] === 'simplified') {
      if (featureService.hasFeature('mobile_signup_p2')) {
        if (queryString['login_method'] === 'mobile') {
          $scope.simplifiedUiLoginMethod = 'mobile';
        } else {
          $scope.simplifiedUiLoginMethod = 'email';
        }
      } else {
        $scope.simplifiedUiLoginMethod = 'email';
      }
    } else {
      $scope.simplifiedUiLoginMethod = null;
    }

    $scope.clickFBLogin = function (appId, version) {
      if (fbService.isMessengerInAppBrowser()) {
        return window.location.replace('/users/auth/facebook');
      }
      fbService.login(appId, version).then(function () {
        window.location.replace('/');
      });
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.showUnVerifiedMessage = false;
      $scope.userForm.submitting = true;
      $scope.userForm.submitted = true;
      $scope.userForm.errorMessage = null;

      if ($scope.userForm.$invalid) {
        $scope.userForm.submitting = false;
        return;
      }
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptData = urlQueries.get('encrypt_data');

      var method = 'POST';
      var path = '/api/users/sign_in';
      $http({
        method: method,
        url: path,
        data: {
          user: $scope.resource,
          encrypt_data: encryptData,
          recaptchable: true,
        },
      })
        .then(function (res) {
          var redirect_to = res.data.redirect_to;
          if (window.location.href == redirect_to) {
            /*
              Not sure if it's necessary, maybe we can remove this block
              Firefox reload won't add referer header, ref: https://bugzilla.mozilla.org/show_bug.cgi?id=1324860
            */
            window.location.reload();
          } else {
            // If form button type is submit, this line might not be executed, ref: https://stackoverflow.com/questions/15759020/window-location-href-doesnt-redirect
            window.location.href = redirect_to;
          }
        })
        .catch(function (res) {
          var data = res.data;

          $scope.userForm.errorMessage =
            $scope.simplifiedUiLoginMethod &&
            data.code === 'InvalidEmailMobilePasswordError'
              ? $filter('translate')(
                  `users.signin.error.invalid.${$scope.simplifiedUiLoginMethod}`,
                )
              : data.message;

          if (data.error) {
            if (data.reached_rate_limit)
              $scope.userForm.errorMessage = data.error;
            $scope.userForm.showUnVerifiedMessage = !data.reached_rate_limit;
            $scope.unConfirmedEmail = data.unconfirmed_email;
          }

          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('sign-in-recaptcha');
        });
    };

    $scope.line_url = function (channel) {
      return benchatService.getLineLoginUri(channel.platformChannelId);
    };

    $scope.facebookLoginUrl = function () {
      let url = '/users/auth/facebook';
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptDataParams = urlQueries.get('encrypt_data');
      if (encryptDataParams) {
        // URLSearchParams will decode params once so we need to encode to handle uri contains plus symbol case
        // as fb redirect_uri
        const encryptData = $window.encodeURIComponent(encryptDataParams);
        url += `?encrypt_data=${encryptData}`;
      }

      return url;
    };

    $scope.togglePassword = function () {
      const element = angular.element(document);
      const password = element.find('input[name=password]');
      const type = password.attr('type') === 'password' ? 'text' : 'password';
      password.attr('type', type);
      $('.eye-toggle').toggle();
      $('.eye-toggle-slash').toggle();
    };

    $scope.getQueryString = function () {
      return new URLSearchParams($location.search()).toString();
    };
  },
]);
