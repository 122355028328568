app.service('slLogisticsYtoService', [
  '$http',
  function ($http) {
    var self = this;
    self.deliveryOptionKeys = [
      'sl_logistics_yto_home',
      'sl_logistics_yto_home_cod',
      'sl_logistics_yto_store',
      'sl_logistics_yto_store_cod',
      'sl_logistics_yto_special_home',
      'sl_logistics_yto_special_home_cod',
      'sl_logistics_yto_special_store',
      'sl_logistics_yto_special_store_cod',
    ];

    self.homeDeliveryKeys = [
      'sl_logistics_yto_home',
      'sl_logistics_yto_home_cod',
      'sl_logistics_yto_special_home',
      'sl_logistics_yto_special_home_cod',
    ];
    self.storeDeliveryKeys = [
      'sl_logistics_yto_store',
      'sl_logistics_yto_store_cod',
      'sl_logistics_yto_special_store',
      'sl_logistics_yto_special_store_cod',
    ];

    self.getCityList = function (region_type) {
      return $http.get('/api/sl_logistics/station/children_list', {
        params: {
          region_type: region_type,
          level: 2,
        },
      });
    };

    self.getDistrictList = function (region_type, province, city) {
      return $http.get('/api/sl_logistics/station/children_list', {
        params: {
          region_type: region_type,
          province: province,
          city: city,
          level: 1,
        },
      });
    };

    self.getStoreList = function (params) {
      return $http.get('/api/sl_logistics/station/list', {
        params: {
          region_type: params.region_type,
          province: params.province,
          city: params.city,
          district: params.district,
          page_size: 200,
        },
      });
    };
  },
]);
