app.controller('UsersEditEmailVerificationDialogController', [
  '$scope',
  '$uibModalInstance',
  'userEmail',
  function ($scope, $uibModalInstance, userEmail) {
    $scope.userEmail = userEmail;

    $scope.confirm = function () {
      $uibModalInstance.close({});
    };
  },
]);
