import dayjs from 'dayjs';
import { range, rangeRight } from 'lodash-es';
import { BIRTHDAY_FORMAT } from '../constants/user';
import { getMaxDate, getJsMonth } from '../../utils/birthday';

const EMPTY_OPTION = '-';

app.directive('birthdayPicker', [
  () => {
    return {
      templateUrl: require('../../../../../public/themes/v1/default/views/birthday-picker.html'),
      restrict: 'E',
      scope: {
        birthdayFormat: '=',
        // ngModel should contain { year: number, month: number, date: number }
        ngModel: '=',
        ngDisabled: '=',
        ngChange: '&',
        hideArrow: '=',
      },
      link: function (scope) {
        if (scope.ngModel === undefined) {
          return;
        }

        let maxDate = 31;

        scope.yearOptions = [EMPTY_OPTION].concat(
          rangeRight(1900, dayjs().year() + 1),
        );
        scope.monthOptions = [EMPTY_OPTION].concat(range(1, 12 + 1));
        scope.getDateOptions = () =>
          [EMPTY_OPTION].concat(range(1, maxDate + 1));

        scope.shouldShow = {
          year: false,
          date: false,
        };

        const initShouldShow = () => {
          switch (scope.birthdayFormat) {
            case BIRTHDAY_FORMAT.YYYYMM:
              scope.shouldShow.year = true;
              scope.shouldShow.date = false;
              break;
            case BIRTHDAY_FORMAT.MMDD:
              scope.shouldShow.year = false;
              scope.shouldShow.date = true;
              break;
            case BIRTHDAY_FORMAT.YYYYMMDD:
            default:
              scope.shouldShow.year = true;
              scope.shouldShow.date = true;
              break;
          }
        };

        scope.$watch('birthdayFormat', () => {
          initShouldShow();
        });

        const updateMaxDate = () => {
          maxDate = getMaxDate(
            scope.ngModel.year,
            getJsMonth(scope.ngModel.month),
          );
        };

        const getProcessedValue = (val) => (val === EMPTY_OPTION ? null : val);

        scope.$watch('ngModel.year', (newValue) => {
          scope.ngModel.year = getProcessedValue(newValue);
          updateMaxDate();
          scope.ngChange(scope.ngModel);
        });
        scope.$watch('ngModel.month', (newValue) => {
          scope.ngModel.month = getProcessedValue(newValue);
          updateMaxDate();
          scope.ngChange(scope.ngModel);
        });
        scope.$watch('ngModel.date', (newValue) => {
          scope.ngModel.date = getProcessedValue(newValue);
          scope.ngChange(scope.ngModel);
        });
      },
    };
  },
]);
