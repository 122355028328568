import './services.checkout.js.coffee'
import './services.cart'
import './services.logger'

import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { isEmpty } from 'lodash-es'
import { isSupportPaymentMethod } from '@payment/payment-sdk'

app.directive("checkoutCartForm", [
  '$http'
  '$timeout'
  '$compile'
  'checkoutService'
  'cartService'
  'mainConfig'
  '$rootScope'
  'logger'
  'slFeatureService'
  'storePickupOptions'
  'storePickupConfig'
  'storePickupAddresses'
  '$injector'
  '$q'
  (
    $http
    $timeout
    $compile
    checkoutService
    cartService
    mainConfig
    $rootScope
    logger
    slFeatureService
    storePickupOptions
    storePickupConfig
    storePickupAddresses
    $injector
    $q
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        onCartFormLoaded = (() -> scope.$emit(CHECKOUT_EVENTS.CART.FORM.LOADED))
        isAddonGiftAsProduct = slFeatureService.hasFeature('addon_gift_as_product')
        scope.zeekPickupStatus = {}
        scope.storePickupOptions = storePickupOptions
        scope.storePickupConfig = storePickupConfig
        scope.storePickupAddresses = storePickupAddresses

        scope.$on CHECKOUT_EVENTS.CART.FORM.RELOAD, () ->
          checkoutService.requestPartial('cart', 'form')
            .then (res) ->
              scope.$broadcast(CHECKOUT_EVENTS.CART.FORM.RERENDER)
              $timeout((() -> element.html($compile(res.data)(scope))), 0)
            .catch (error) ->
              logger.log "Unable to load cart form"
            .finally (() -> onCartFormLoaded())

        element.on "change", "#order-delivery-country", (event) ->
          $rootScope.$emit('cart.form.country.change')
          updateOption { country: angular.element(event.currentTarget).val() }, { triggerItemsValidation: isAddonGiftAsProduct }

        element.on "change", "#order-delivery-method", (event) ->
          deliveryOptionId = angular.element(event.currentTarget).val();
          $rootScope.$emit('cart.form.delivery.change', deliveryOptionId)
          updateOption { delivery_option_id: deliveryOptionId, delivery_data: {}}, { triggerItemsValidation: true }

        element.on "change", "#order-payment-method", (event) ->
          updateOption { payment_id: angular.element(event.currentTarget).val() }, { triggerItemsValidation: isAddonGiftAsProduct }

        scope.onSfLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onPakpoboxLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onZeekLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onStoreSelected = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false, triggerItemsValidation: true }

        validateCartItem = () ->
          cartService.validate()
            .then (data) ->
              scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, { success: true })
            .catch (response) ->
              scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, {
                success: false, cartValidateItem: response
              })

        updateOption = (data, options) ->
          options = {} if !options?
          _.defaults(options, { rerender: true })

          scope.$emit CHECKOUT_EVENTS.CART.FORM.CHANGING if options.rerender
          checkoutService.requestPartial('cart', 'form', data)
            .then (res) ->
              if options.rerender
                scope.$broadcast(CHECKOUT_EVENTS.CART.FORM.RERENDER)
                $timeout((() -> element.html($compile(res.data)(scope))), 0)
              scope.$emit CHECKOUT_EVENTS.CART.FORM.CHANGED, { rerender: options.rerender }
              if options.triggerItemsValidation
                validateCartItem()
            .catch (error) ->
              logger.log "Unable to update option"
              logger.log data
            .finally (() -> onCartFormLoaded())

        checkSLPaymentMethodsAvailability = () ->
          promises = [isSupportPaymentMethod('ApplePay'), isSupportPaymentMethod('GooglePay')];
          $q.all(promises).then ([applePayRes, googlePayRes]) ->
            $rootScope.isDeviceSupportSLPApplePay = applePayRes
            $rootScope.isDeviceSupportSLPGooglePay = googlePayRes

            hasSLPApplePay = $injector.has('hasSLPApplePay') && $injector.get('hasSLPApplePay')
            hasSLPGooglePay = $injector.has('hasSLPGooglePay') && $injector.get('hasSLPGooglePay')

            if (!$rootScope.isDeviceSupportSLPApplePay && hasSLPApplePay) || (!$rootScope.isDeviceSupportSLPGooglePay && hasSLPGooglePay)
              scope.$emit("checkout.cart.form.reload")
            
        checkSLPaymentMethodsAvailability()

        element.on "destroy", (() -> element.off()) # Unbind events
    }
])
