import './service.setting';
import './services.recaptcha';
import './services.tracker';
import './services.quick.sign_up';
import { validatePhone } from '../../utils/intlTel';

app.controller('QuickSignUp', [
  '$scope',
  '$filter',
  'quickSingUpService',
  'trackerService',
  'RecaptchaService',
  'settingService',
  function (
    $scope,
    $filter,
    quickSingUpService,
    trackerService,
    RecaptchaService,
    settingService,
  ) {
    $scope.policy = false;
    $scope.getInformation = true;
    $scope.phoneValidate = false;
    $scope.errorMessage = '';
    $scope.userSetting = settingService.getUserSettingFromMainConfig();
    $scope.state = {
      isLoading: false,
      submitClicked: false,
    };

    $scope.telChange = function () {
      var mobilePhoneInput = $('.intl-tel-input');
      $scope.phone = mobilePhoneInput
        .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
        .replace(/\D/g, '');

      if (validatePhoneNumber()) {
        $scope.phoneValidate = true;
        $scope.errorMessage = '';
      } else {
        $scope.phoneValidate = false;
        if ($scope.phone) {
          $scope.errorMessage = $filter('translate')(
            'user.quick_signup.error.format',
          );
        }
      }
    };

    $scope.submit = function () {
      $scope.state.submitClicked = true;

      if (!$scope.phoneValidate) {
        return;
      }
      var subscriptionTags = $scope.getInformation ? ['marketing.news'] : [];
      quickSingUpService
        .signUp(
          $scope.phone,
          $('.intl-tel-input').intlTelInput('getSelectedCountryData').dialCode,
          subscriptionTags,
        )
        .then(function (data) {
          trackerService.fbSignup(data.data._id);
          trackerService.track({
            type: trackerService.generalEventType.REGISTRATION,
          });
          window.location.href =
            '/users/sign_up/quick/' +
            data.data._id +
            '/edit?country_calling_code=' +
            $('.intl-tel-input').intlTelInput('getSelectedCountryData')
              .dialCode +
            '&mobile_phone=' +
            $scope.phone;
        })
        .catch(function (error) {
          $scope.serverErrorMessage = error.data.error_messages;
          $('html, body').animate(
            {
              scrollTop: $('.title-text').offset().top + 20,
            },
            200,
          );
        })
        .finally(function () {
          RecaptchaService.reset('quick-sign-up-recaptcha');
        });
    };

    var validatePhoneNumber = function () {
      const country = $('.intl-tel-input').intlTelInput(
        'getSelectedCountryData',
      ).iso2;
      return validatePhone($scope.phone, country).length === 0;
    };
  },
]);
