import './services.recaptcha';
app.controller('RegistrationsEditController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'RecaptchaService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    RecaptchaService,
  ) {
    $scope.resource = resource.data;

    $scope.$watch('resource.current_password', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.current_password.$setValidity('general', true);
      }
    });
    $scope.$watch('resource.password_confirmation', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }
      if (
        $scope.resource.password != $scope.resource.password_confirmation &&
        $scope.userForm.submitted
      ) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm.password_confirmation.$setValidity('general', false);
      }
    });

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting == true) {
        return;
      }
      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      $scope.userForm.errorMessages = null;

      if ($scope.userForm.current_password.$error['general']) {
        $scope.userForm.current_password.$setValidity('general', true);
      }

      if ($scope.resource.password != $scope.resource.password_confirmation) {
        $scope.userForm['password_confirmation']['validationMessage'] = $filter(
          'translate',
        )('form.validation.confirmation');
        $scope.userForm.password_confirmation.$setValidity('general', false);
      } else {
        $scope.userForm.password_confirmation.$setValidity('general', true);
      }
      if ($scope.userForm.$invalid) {
        //console.log("form invalid");
        $scope.userForm.submitting = false;
        return;
      }

      var method = 'PUT';
      var path = '/api/users';
      //var path = '/api/main/v1/users';

      var data_params = {
        password: $scope.resource.password,
        password_confirmation: $scope.resource.password_confirmation,
        current_password: $scope.resource.current_password,
      };

      $http({
        method: method,
        url: path,
        data: {
          user: data_params,
          recaptchable: true,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .then(function (res) {
          $scope.userForm.submitting = false;
          $scope.userForm.successMessages = [res.data.message];
          if ($rootScope.mainConfig.previousUrl) {
            $window.location.href = $rootScope.mainConfig.previousUrl;
          } else {
            $window.location.href = '/';
          }
        })
        .catch(function (res) {
          $scope.userForm.errorMessages = res.data.error_messages;
          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('edit-pw-recaptcha');
        });
    };
  },
]);
