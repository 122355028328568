import './services.tracker';
app.directive('paypalCheckoutBtn', [
  '$window',
  '$q',
  '$http',
  'cart',
  'mainConfig',
  '$filter',
  'trackerService',
  function ($window, $q, $http, cart, mainConfig, $filter, trackerService) {
    var loadScript = function (u) {
      var d, deferred, o, s, t;
      d = document;
      t = 'script';
      o = d.createElement(t);
      s = d.getElementsByTagName(t)[0];
      o.src = 'https://' + u;
      deferred = $q.defer();
      o.addEventListener(
        'load',
        function () {
          deferred.resolve();
        },
        false,
      );
      s.parentNode.insertBefore(o, s);
      return deferred.promise;
    };
    return {
      template:
        '<div id="paypal-checkout-button"' +
        'type="submit"' +
        'class="ladda-button"' +
        'data-style="slide-down"' +
        'ladda="loading"></div>',
      restrict: 'E',
      scope: true,
      link: function ($scope) {
        var paypalEnv =
          $scope.mainConfig.env === 'production' ? 'production' : 'sandbox';

        loadScript('www.paypalobjects.com/api/checkout.min.js').then(
          function () {
            $scope.$emit('checkout.payments.paypal_express.ready');

            paypal.Button.render(
              {
                locale: $filter('translate')('paypal.language'),
                style: {
                  color: 'gold',
                  size: 'responsive',
                  tagline: false,
                  label: 'pay',
                  height: 44,
                  shape: 'rect',
                },
                env: paypalEnv,
                validate: function () {},
                onClick: _.throttle(
                  function () {
                    // Hackaround: hide checkout form when button is clicked
                    // make page cleaner to avoid random scrolling / form disappearing in in-app mobile browser

                    // shoplytics tracking - paypal button onClick
                    trackerService.userAction('Checkout', 'Order');
                    $('#checkout-container').hide();
                  },
                  1000,
                  { trailing: false },
                ),
                payment: _.throttle(
                  function () {
                    return new paypal.Promise(function (resolve, reject) {
                      $scope.onPaypalCharge().then(
                        function (order) {
                          resolve(order.paypal_token);
                          $scope.order_payment_id = order.order_payment_id;
                        },
                        function (error) {
                          $('#checkout-container').show();
                          reject(error);
                        },
                      );
                    });
                  },
                  1000,
                  { trailing: false },
                ),
                onAuthorize: function (data) {
                  return $http({
                    url:
                      '/paypal/order_payments/' +
                      $scope.order_payment_id +
                      '/execute',
                    data: data,
                    method: 'POST',
                  })
                    .then(function (res) {
                      window.location.href = res.data.redirect_to;
                    })
                    .catch(function (res) {
                      $scope.errors.checkout = res.data.message;
                      $scope.onPaypalRenderErrors();
                      $('#checkout-container').show();
                    });
                },
                onCancel: function (data) {
                  data.onPaypalStorefrontCancel = true;
                  data.skipFulfilledCheck = true;
                  return $http({
                    url:
                      '/paypal/order_payments/' +
                      $scope.order_payment_id +
                      '/cancel',
                    data: data,
                    method: 'POST',
                  })
                    .catch(function () {
                      $scope.errors.checkout = 'Error With Payment Gateway';
                      $scope.onPaypalRenderErrors();
                    })
                    .finally(function () {
                      $('#checkout-container').show();
                    });
                },
                onError: function () {
                  $scope.errors.checkout =
                    $scope.errors.checkout || 'Error With Payment Gateway';
                  $scope.onPaypalRenderErrors();
                  $('#checkout-container').show();
                },
              },
              '#paypal-checkout-button',
            );
          },
        );
      },
    };
  },
]);
