
app.service 'tappayService', [
  '$q',
  ($q) ->
    getPrime: ->
      tokenDeferObject = $q.defer()
      # link https://docs.tappaysdk.com/tutorial/zh/web/front.html#get-prime
      TPDirect.card.getPrime (result) ->
        if result.status == 0
          tokenDeferObject.resolve result
        else
          tokenDeferObject.reject result
        return
      tokenDeferObject.promise
    getTappayFieldsStatus: ->
      try
        # link https://docs.tappaysdk.com/tutorial/zh/web/front.html#gettappayfieldsstatus
        return TPDirect.card.getTappayFieldsStatus()
      catch error
        return error
]