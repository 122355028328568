app.controller('EsunController', [
  '$scope',
  '$window',
  '$http',
  function ($scope, $window, $http) {
    //TODO: routeParam for getting order id
    // $scope.handleEsunCallback = function(){
    $http({
      method: 'GET',
      url: '/api' + $window.location.pathname + $window.location.search,
    }).then(function (res) {
      $window.location.href = res.data['redirect_to'];
    });
    // }
  },
]);
