app.service('familyMartFreezeService', [
  '$http',
  function ($http) {
    const FAMILY_MART_FROZEN_DELIVERIES = [
      'sl_logistics_fmt_freeze_pay',
      'sl_logistics_fmt_freeze_nopay',
    ];

    const isFreezeType = (region_type) => {
      return FAMILY_MART_FROZEN_DELIVERIES.includes(region_type);
    };

    const checkFreeTypeWithFormData = (formData, multiCartDeliveries = {}) => {
      const deliveries = Object.values(multiCartDeliveries);
      if (deliveries?.length) {
        return deliveries.some((delivery) =>
          isFreezeType(delivery.delivery_option.region_type),
        );
      }
      return isFreezeType(formData.order.delivery_option.region_type);
    };

    const confirmStationSpace = (payload) => {
      return $http({
        method: 'POST',
        url: 'api/sl_logistics/station_spaces',
        data: payload,
      });
    };

    return {
      isFreezeType,
      confirmStationSpace,
      checkFreeTypeWithFormData,
    };
  },
]);
