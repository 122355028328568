app.service('slLogisticsStationService', [
  '$http',
  function ($http) {
    this.getProvinceList = function (params) {
      return $http.get('/api/sl_logistics/station/children_list', {
        params: {
          company_code: params.companyCode,
          level: 2,
          country_code: params.countryCode,
        },
      });
    };

    this.getCityList = function (params) {
      return $http.get('/api/sl_logistics/station/children_list', {
        params: {
          company_code: params.companyCode,
          province: params.province,
          city: params.city,
          level: 1,
          country_code: params.countryCode,
        },
      });
    };

    this.getStoreList = function (params) {
      return $http.get('/api/sl_logistics/station/list', {
        params: {
          company_code: params.companyCode,
          province: params.province,
          city: params.city,
          district: params.district,
          page_size: 200,
          country_code: params.countryCode,
        },
      });
    };
  },
]);
