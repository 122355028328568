app.directive("checkoutDeliveryHop", [
  '$filter'
  'hop_locations'
  (
    $filter
    hop_locations
  ) ->
    {
      restrict: 'A'
      scope: {}
      link: (scope, element, attrs) ->
        element.on "change", "*[name='order[delivery_data][location_code]']", ((event) -> setLocationName())

        setLocationName = () ->
          $input = $("*[name='order[delivery_data][location_code]']")
          locations = hop_locations
          $("*[name='order[delivery_data][name_translations]']").val JSON.stringify(_.findWhere(locations, id: $input.val()).name_translations)

        # Initialze
        setLocationName()
    }
])