import './services.tracker';
app.directive('paypalCnCheckoutBtn', [
  '$q',
  '$http',
  'mainConfig',
  '$filter',
  'trackerService',
  function ($q, $http, mainConfig, $filter, trackerService) {
    var loadScript = function (u) {
      var d, deferred, o, s, t;
      d = document;
      t = 'script';
      o = d.createElement(t);
      s = d.getElementsByTagName(t)[0];
      o.src = 'https://' + u;
      deferred = $q.defer();
      o.addEventListener(
        'load',
        function () {
          deferred.resolve();
        },
        false,
      );
      s.parentNode.insertBefore(o, s);
      return deferred.promise;
    };
    return {
      template:
        '<div id="paypal-cn-checkout-button"' +
        'type="submit"' +
        'class="ladda-button"' +
        'data-style="slide-down"' +
        'ladda="loading">' +
        '</div>',
      restrict: 'E',
      scope: true,
      link: function ($scope) {
        var currency = $scope.$parent.currentCurrency.iso_code.toUpperCase();
        loadScript(
          'www.paypal.com/sdk/js?client-id=' +
            mainConfig.paypalCnClientId +
            '&currency=' +
            currency +
            '&locale=' +
            $filter('translate')('paypal.language'),
        ).then(function () {
          $scope.$emit('checkout.payments.paypal_cn.ready');

          paypal
            .Buttons({
              style: {
                color: 'gold',
                size: 'responsive',
                tagline: false,
                label: 'pay',
                height: 44,
                shape: 'rect',
              },
              createOrder: function () {
                // shoplytics tracking - paypal button onClick
                trackerService.userAction('Checkout', 'Order');
                return $scope.$parent
                  .processCheckoutPaypalCn()
                  .then(function (res) {
                    var order = res.data.order;
                    $scope.order_payment_id = order.order_payment_id;
                    return order.paypal_token;
                  })
                  .catch(function () {
                    $('#checkout-container').show();
                  });
              },
              onApprove: function (data) {
                $('#checkout-container').hide();
                return $http({
                  url:
                    '/paypal_cn/order_payments/' +
                    $scope.order_payment_id +
                    '/execute',
                  data: data,
                  method: 'POST',
                })
                  .then(function (res) {
                    window.location.href = res.data.redirect_to;
                  })
                  .catch(function (res) {
                    $scope.errors.checkout = res.data.message;
                    $scope.onPaypalRenderErrors();
                    $('#checkout-container').show();
                  });
              },
              onCancel: function (data) {
                data.onPaypalStorefrontCancel = true;
                data.skipFulfilledCheck = true;
                return $http({
                  url:
                    '/paypal/order_payments/' +
                    $scope.order_payment_id +
                    '/cancel',
                  data: data,
                  method: 'POST',
                })
                  .catch(function () {
                    $scope.errors.checkout = 'Error With Payment Gateway';
                    $scope.onPaypalRenderErrors();
                  })
                  .finally(function () {
                    $('#checkout-container').show();
                  });
              },
              onError: function () {
                $scope.errors.checkout =
                  $scope.errors.checkout || 'Error With Payment Gateway';
                $scope.onPaypalRenderErrors();
                $('#checkout-container').show();
              },
            })
            .render('#paypal-cn-checkout-button');
        });
      },
    };
  },
]);
