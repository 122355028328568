app.directive('collapsibleCartPromotions', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'C',
      scope: true,
      link: function (scope, element) {
        var minHeight = 194; // 204 - 10 (padding of cart-promotions-content)
        scope.collapsible = false;
        if (element.height()) {
          init();
        } else {
          // when re-compiled
          $timeout(init, false);
        }

        function init() {
          var promotionContent = element.find('.cart-promotions-content');
          if (
            promotionContent &&
            promotionContent.height() &&
            promotionContent.height() >= minHeight &&
            window.innerWidth < 768
          ) {
            scope.expandedHeight = promotionContent.height();
            element[0].style['max-height'] = minHeight + 'px';
            scope.collapsible = true;
            scope.expanded = false;
          }
          scope.initialized = true;
        }

        scope.toggleCollapse = function () {
          scope.expanded = !scope.expanded;
          if (scope.expanded) {
            element[0].style['max-height'] = scope.expandedHeight + 42 + 'px'; // padding of cart-promotions-content
          } else {
            element[0].style['max-height'] = minHeight + 'px';
          }
        };
      },
    };
  },
]);
