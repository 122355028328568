import './services.money-services';
import './services.product';
import './services.image';
app.controller('ReturnOrdersShowController', [
  '$scope',
  'imageService',
  'productService',
  'returnOrder',
  'moneyService',
  'mainConfig',
  '$rootScope',
  '$timeout',
  function (
    $scope,
    imageService,
    productService,
    returnOrder,
    moneyService,
    mainConfig,
    $rootScope,
    $timeout,
  ) {
    function transformReturnOrder(returnOrder) {
      const result = {
        ...returnOrder,
        total: moneyService.toMoney({
          cents: returnOrder.total.cents,
        }),
      };

      if (returnOrder.order_payment.refund_amount) {
        result.order_payment = {
          ...returnOrder.order_payment,
          refund_amount: moneyService.toMoney({
            cents: returnOrder.order_payment.refund_amount.cents,
          }),
        };
      }

      return result;
    }
    $scope.returnOrder = transformReturnOrder(returnOrder);
    $scope.isRevampedReturnOrder = returnOrder.ref_data?.return_order_revamp;
    $scope.isOrderInspected = returnOrder.inspect_status === 'inspected';
    $scope.shouldShowInspectData = returnOrder.items.some(function (item) {
      return item.order_inspect_item;
    });

    $scope.returnItems = returnOrder.items.reduce(function (orderItems, item) {
      if (
        !item.order_inspect_item ||
        _.isNull(item.order_inspect_item.inspect_accepted_quantity)
      ) {
        orderItems.push(item);
      } else {
        var acceptedItem = angular.copy(item);
        acceptedItem.quantity =
          item.order_inspect_item.inspect_accepted_quantity;
        if (acceptedItem.quantity > 0) {
          acceptedItem.order_inspect_item.inspect_status = 'accepted';
          orderItems.push(acceptedItem);
        }

        var rejectedItem = angular.copy(item);
        rejectedItem.quantity =
          item.quantity - item.order_inspect_item.inspect_accepted_quantity;
        if (rejectedItem.quantity > 0) {
          rejectedItem._id = rejectedItem._id + '_rejected';
          rejectedItem.order_inspect_item.inspect_status = 'rejected';
          orderItems.push(rejectedItem);
        }
      }
      return orderItems;
    }, []);

    $scope.RETURN_ORDER_STATUS_TRANSLATION = {
      pending: 'return_order.status.pending',
      confirmed: 'return_order.status.confirmed',
      completed: 'return_order.status.completed',
      cancelled: 'return_order.status.cancelled',
    };

    $scope.RETURN_ORDER_STATUS_CLASS = {
      pending: 'label-warning',
      confirmed: 'label-default',
      completed: 'label-default',
      cancelled: 'label-default',
    };

    function calculateItemTotalPrice() {
      $scope.returnItems.forEach(function (item) {
        item.itemTotalPrice = moneyService.toMoney({
          cents: item.item_price.cents * item.quantity,
          symbol: item.item_price.currency_symbol,
          symbol_first: mainConfig.merchantData.base_currency.symbol_first,
          currencyIso: item.item_price.currency_iso,
        });
      });
    }

    calculateItemTotalPrice();

    $scope.orderPaymentLabelOptions = {
      pending: 'label-warning',
      refunded: 'label-info',
    };

    $scope.orderDeliveryLabelOptions = {
      pending: 'label-warning',
      returning: 'label-warning',
      returned: 'label-default',
      arrived: 'label-default',
      collected: 'label-default',
      return_collected: 'label-default',
      other: 'label-info',
      no_return: 'label-default',
    };

    $scope.getImgUrl = function (item) {
      return imageService.getMediaImageUrl(item.object_data.media[0], {
        size: '200x',
      });
    };

    $scope.getProductLink = productService.getProductItemLink;
    $scope.isVariant = productService.isItemVariant;
    $scope.showPromotionLabel = productService.showPromotionLabel;

    if (!mainConfig.merchantData.beta_feature_keys.includes('hide_message')) {
      $timeout(function () {
        $('#js-contact-us').on('click', function (e) {
          e.preventDefault();
          $rootScope.showMessageForm();
        });
      }, 0);
    }
  },
]);
