import {
  COLLAPSE_STATUS,
  SECTION_STATUS,
} from '../../constants/components/collapse-section-status';

app.directive('collapseSection', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'E',
      transclude: {
        'header-left': '?headerLeft',
        'header-right': '?headerRight',
        'collapse-content': '?collapseContent',
      },
      templateUrl: require('../../../../../../public/themes/shared/components/templates.collapse-section.html'),
      scope: {
        collapseTitle: '@',
        status: '<',
        isLoading: '<',
        disableCollapse: '<',
        collapseStatus: '=',
      },
      link: function (scope, element) {
        scope.SECTION_STATUS = SECTION_STATUS;
        scope.COLLAPSE_STATUS = COLLAPSE_STATUS;
        scope.tmpMaxHeight = 0;

        scope.setCollapseSection = function () {
          const SECTION_PADDING = 30;
          const scrollHeight = element.find(
            '.collapse-section__body collapse-content',
          )[0].scrollHeight;
          const maxHeight = scrollHeight + SECTION_PADDING;

          if (scope.tmpMaxHeight > maxHeight) {
            return;
          }

          scope.tmpMaxHeight = maxHeight;

          element
            .find('.collapse-section__body collapse-content')
            .css({ 'max-height': `${maxHeight}px` });
        };

        scope.collapseSection = function () {
          scope.collapseStatus = COLLAPSE_STATUS.COLLAPSED;
        };

        scope.expandSection = function () {
          scope.collapseStatus = COLLAPSE_STATUS.EXPANDED;
        };

        $timeout(scope.setCollapseSection, 100);

        const observer = new MutationObserver(() => {
          $timeout(scope.setCollapseSection);
        });

        observer.observe(
          element.find('.collapse-section__body collapse-content')[0],
          { subtree: true, characterData: true, attributes: true },
        );
      },
    };
  },
]);
