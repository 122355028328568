import './services.exec_command';
app.directive('memberReferral', [
  '$rootScope',
  'mainConfig',
  '$uibModal',
  '$filter',
  '$timeout',
  '$http',
  'slFeatureService',
  'execCommandService',
  function (
    $rootScope,
    mainConfig,
    $uibModal,
    $filter,
    $timeout,
    $http,
    slFeatureService,
    execCommandService,
  ) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: require('../../../../../public/themes/shared/users/templates.member_referral.html'),
      link: function (scope) {
        scope.baseUrl = window.location.origin;
        scope.memberReferralKey =
          slFeatureService.hasFeature('customer_referral');
        scope.mainConfig = mainConfig;
        getCampaign();

        scope.$on('memberCenterUser.fetch', function (event, user) {
          scope.user = user;
        });

        function getCampaign() {
          $http({
            method: 'GET',
            url: '/api/merchants/' + mainConfig.merchantId + '/campaigns',
            params: {
              type: 'member_referral',
              current_campaign: true,
            },
          }).then(function (res) {
            if (res.data.total === 1) {
              scope.campaign = res.data.campaigns[0];
            }
          });
        }

        scope.copy = function () {
          execCommandService.copyToClipboard(
            scope.baseUrl + '/?sl-mref=' + scope.user.referral_code,
          );

          new PNotify({
            title: $filter('translate')('member_referral.copy.link.success'),
            addclass: 'success',
            animate: {
              animate: true,
              in_class: 'fadeInDown',
              out_class: 'fadeOut',
            },
            delay: 4000,
            icon: 'fa fa-check-circle',
          });
        };

        scope.generateCode = function () {
          $http({
            method: 'POST',
            url: '/api/users/generate_referral_code',
          }).then(function (res) {
            scope.user = res.data.user;
            new PNotify({
              title: $filter('translate')(
                'member_referral.generate.code.success',
              ),
              addclass: 'success',
              animate: {
                animate: true,
                in_class: 'fadeInDown',
                out_class: 'fadeOut',
              },
              delay: 4000,
              icon: 'fa fa-check-circle',
            });
          });
        };

        scope.openTermModal = function () {
          $rootScope.currentModal = $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.member_referral_rule.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              function ($scope, $uibModalInstance) {
                $scope.confirm = function () {
                  $uibModalInstance.close();
                };

                $scope.termText = $filter('translateModel')(
                  scope.campaign.remarks_translations,
                );

                $timeout(function () {
                  $('.promotion-term-dialog .scroll-area').overlayScrollbars({
                    overflowBehavior: { x: 'hidden' },
                  });

                  $('.promotion-term-modal-backdrop').one('click', function () {
                    $uibModalInstance.dismiss('cancel');
                  });

                  $rootScope.$emit('modal.open');
                });
              },
            ],
            windowClass: 'promotion-term-modal-window',
            backdropClass: 'promotion-term-modal-backdrop',
          });
        };
      },
    };
  },
]);
