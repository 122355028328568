app.directive('paymentExistOrderInfo', [
  function () {
    return {
      restrict: 'E',
      template:
        '<div class="payment-exist-order-hint" ng-bind-html="\'checkout.payments.exist.order.tip\' | translate: { order: duplicateOrder.orderNumber, duplicateOrderLink: duplicateOrderLink,}"></div>',
      scope: {
        duplicateOrder: '=',
      },
      link: function (scope, element) {
        scope.duplicateOrderLink = `/orders/${scope.duplicateOrder.orderId}`;

        scope.$on('$destroy', function () {
          element.off();
        });
      },
    };
  },
]);
