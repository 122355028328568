app.service 'merchantAffiliateService', [
  '$q'
  '$sce'
  '$timeout'
  'mainConfig'
  (
    $q
    $sce
    $timeout
    mainConfig
  ) ->
    {
      trackCheckout: (order) ->
        $q (resolve, reject) ->
          try
            shopComObject = _.find((mainConfig.merchantData.merchant_affiliates || []), ((obj) -> return obj.affiliate_name == 'shop_com'))
            paymentFee = order.order_payment.object_data.payment_fee_dollars;
            amount = (((order.total.dollars * 100) - (order.delivery_option.total.dollars * 100) - (paymentFee * 100)) / 100 ).toString()

            if shopComObject && shopComObject.enabled
              src = sprintf("https://marktamerica.go2cloud.org/aff_l?offer_id=%(offer_id)s&adv_sub=%(order_number)s&amount=%(amount)s", {
                offer_id: shopComObject.configuration.offer_id,
                order_number: order.order_number,
                amount: amount
              })

              iframe = document.createElement('iframe')
              iframe.setAttribute("src", $sce.trustAsResourceUrl(src))
              iframe.setAttribute("scrolling", "no")
              iframe.setAttribute("frameborder", "0")
              iframe.setAttribute("width", "1")
              iframe.setAttribute("height", "1")
              document.body.appendChild(iframe)
              $timeout(resolve, 5000) # Delay for 5 seconds to wait for DOM element ready
            else
              resolve()
          catch err
            console.log err
            reject(err)
    }
]
