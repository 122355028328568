export const GENERAL_DISCOUNT_TYPES = {
  gift: 'gift',
  amount: 'amount',
  percentage: 'percentage',
  freeShipping: 'free_shipping',
  addon: 'addon',
};
export const BUNDLE_PRICING_DISCOUNT_TYPES = {
  bundlePricing: 'bundle_pricing',
  bundlePercentage: 'bundle_percentage',
  bundleAmount: 'bundle_amount',
  bundleGift: 'bundle_gift',
};
export const BUNDLE_GROUP_DISCOUNT_TYPES = {
  bundleGroup: 'bundle_group',
  bundleGroupPercentage: 'bundle_group_percentage',
  bundleGroupAmount: 'bundle_group_amount',
  bundleGroupGift: 'bundle_group_gift',
};
export const BUY_AND_GET_DISCOUNT_TYPES = {
  buyAndGetFree: 'buyandget_free',
  buyAndGetPricing: 'buyandget_pricing',
  buyAndGetPercentage: 'buyandget_percentage',
};
export const SUBSCRIPTION_DISCOUNT_TYPES = {
  subscriptionGift: 'subscription_gift',
  subscriptionAmount: 'subscription_amount',
  subscriptionPercentage: 'subscription_percentage',
};
// TODO: Check the category of the following discount types
export const POINT_DISCOUNT_TYPES = {
  memberPointRedeemGift: 'member_point_redeem_gift',
};
export const REWARD_CAMPAIGN_TYPES = {
  creditReward: 'credit_reward',
};
export const DISCOUNT_TYPES = {
  ...GENERAL_DISCOUNT_TYPES,
  ...BUNDLE_PRICING_DISCOUNT_TYPES,
  ...BUNDLE_GROUP_DISCOUNT_TYPES,
  ...BUY_AND_GET_DISCOUNT_TYPES,
  ...SUBSCRIPTION_DISCOUNT_TYPES,
  ...POINT_DISCOUNT_TYPES,
  ...REWARD_CAMPAIGN_TYPES,
};

// frontend defined value, currently used in coupon-v2
export const DISCOUNT_CATEGORY = {
  discount: 'discount',
  gift: 'gift',
  freeShipping: 'freeShipping',
};
export const DISOUNT_TYPE_TO_DISCOUNT_CATEGORY = {
  // discount
  [DISCOUNT_TYPES.amount]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.percentage]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundlePricing]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundlePercentage]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundleAmount]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundleGroup]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundleGroupPercentage]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.bundleGroupAmount]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.buyAndGetFree]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.buyAndGetPricing]: DISCOUNT_CATEGORY.discount,
  [DISCOUNT_TYPES.buyAndGetPercentage]: DISCOUNT_CATEGORY.discount,
  // gift
  [DISCOUNT_TYPES.gift]: DISCOUNT_CATEGORY.gift,
  [DISCOUNT_TYPES.bundleGift]: DISCOUNT_CATEGORY.gift,
  [DISCOUNT_TYPES.bundleGroupGift]: DISCOUNT_CATEGORY.gift,
  // freeShipping
  [DISCOUNT_TYPES.freeShipping]: DISCOUNT_CATEGORY.freeShipping,
};

const GENERAL_COUPON_TAG_TYPE = {
  firstPurchaseOnly: 'firstPurchaseOnly',
  oneUserMaxUseCount: 'oneUserMaxUseCount',
  firstShopper: 'firstShopper',
  reachLimit: 'reachLimit',
  expired: 'expired',
  used: 'used',
};
const PLATFORM_COUPON_TAG_TYPE = {
  platformOnlineStore: 'platformOnlineStore',
  platformRetailStore: 'platformRetailStore',
  platformSelectedRetailStore: 'platformSelectedRetailStore',
  platformOnlineAndSelectedRetailStore: 'platformOnlineAndSelectedRetailStore',
};
export const COUPON_TAG_TYPE = {
  ...GENERAL_COUPON_TAG_TYPE,
  ...PLATFORM_COUPON_TAG_TYPE,
};

export const DISCOUNT_ON = {
  item: 'item',
  order: 'order',
  category: 'category',
};

export const AVAILABLE_PLATFORM = {
  ec: 'ec',
  retail: 'retail',
};

export const COUPON_TYPE = {
  single: 'single',
  multi: 'multi',
  draw: 'draw',
};

export const COUPON_SCOPE = {
  comingSoon: 'comingSoon',
  valid: 'valid',
  invalid: 'invalid',
};

export const COUPON_ERROR = {
  not_login: 'not_login',
  fulled: 'fulled',
  expired: 'expired',
  not_eligible: 'not_eligible',
  already_taken: 'already_taken',
  unfit_first_purchase: 'unfit_first_purchase',
};

export const USER_COUPON_STATUS = {
  active: 'active',
  used: 'used',
  removed: 'removed',
};
