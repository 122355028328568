import {
  paymentMethodMap,
  getSLpaymentService,
} from '../../service/slPaymentService';
import getDeviceType from '../../utils/getDeviceType';

app.service('slPaymentService', [
  '$http',
  function ($http) {
    return {
      ...getSLpaymentService($http),
      paymentMethodMap,
      getDeviceType,
    };
  },
]);
