app.service('mobilePhoneService', [
  '$http',
  function ($http) {
    this.verifyCode = function (params) {
      return $http({
        method: 'GET',
        url: '/api/mobile_phone_verifications/verify_code',
        params: {
          country_calling_code: params.countryCallingCode,
          mobile_phone: params.mobilePhone,
          code: params.code,
          is_forget_password: params.isForgetPassword,
        },
      });
    };

    this.sendMobileChangeCode = function (data) {
      return $http({
        method: 'POST',
        url: '/api/mobile_phone_verifications/send_mobile_change_code',
        data: {
          country_calling_code: data.countryCallingCode,
          mobile_phone: data.mobilePhone,
          recaptchable: data.recaptchable,
        },
      });
    };

    this.sendQuickSignUpCode = function (data) {
      return $http({
        method: 'POST',
        url: '/api/mobile_phone_verifications/send_quick_sign_up_code',
        data: {
          country_calling_code: data.countryCallingCode,
          mobile_phone: data.mobilePhone,
          recaptchable: data.recaptchable,
        },
      });
    };

    this.sendForgetPasswordCode = function (data) {
      return $http({
        method: 'POST',
        url: '/api/mobile_phone_verifications/send_forget_password_code',
        data: {
          mobile_phone: data.mobilePhone,
          recaptchable: data.recaptchable,
        },
      });
    };

    this.sendSignUpCode = function (data) {
      return $http({
        method: 'POST',
        url: '/api/mobile_phone_verifications/send_sign_up_code',
        data: {
          country_calling_code: data.countryCallingCode,
          mobile_phone: data.mobilePhone,
          recaptchable: data.recaptchable,
        },
      });
    };

    this.checkUserExistenceByPhone = function (params) {
      return $http({
        method: 'GET',
        url: '/api/users/exist',
        params: {
          country_calling_code: params.countryCallingCode,
          mobile_phone: params.mobilePhone,
        },
      });
    };
  },
]);
