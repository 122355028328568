import { PREFERENCE_SCOPE } from '../../constants/addressModule';

app.service('addressPreferenceService', [
  '$http',
  function ($http) {
    const getAddressPreference = (params) =>
      $http({
        method: 'GET',
        url: '/api/address_preferences',
        params: params,
      });

    const getAddressPreferenceData = (params) =>
      getAddressPreference(params).then((preferenceRes) => ({
        preferences: preferenceRes.data.data.preferences,
        preferenceLevelMap: preferenceRes.data.data.preferences
          .sort((a, b) => (a.level && b.level ? a.level - b.level : -1))
          .reduce((accumulate, preference) => {
            accumulate[preference.field_name] = preference.level;
            return accumulate;
          }, {}),
      }));

    const transformDeliveryDataToAddress = (props) => {
      const { country, deliveryData, preferenceData } = props;
      return Object.keys(deliveryData)
        .sort(
          (a, b) =>
            preferenceData.preferenceLevelMap[a] -
            preferenceData.preferenceLevelMap[b],
        )
        .map((key) => {
          let value = deliveryData[key];
          if (country === 'TW' && key === 'address_2') {
            value = `${deliveryData.postcode} ${value}`;
          }
          return {
            key,
            value,
            preference: preferenceData.preferences.find(
              (preference) => preference.field_name === key,
            ),
          };
        });
    };

    return {
      PREFERENCE_SCOPE,
      getAddressPreference,
      getAddressPreferenceData,
      transformDeliveryDataToAddress,
    };
  },
]);
