app.service('memberOrdersService', [
  '$http',
  function ($http) {
    var limit = 24;

    this.getOrders = function (page) {
      return $http({
        method: 'GET',
        url: '/api/orders',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          page: page,
          limit: limit,
        },
      });
    };

    this.getCheckoutObjects = function (page) {
      return $http({
        method: 'GET',
        url: '/api/checkout_objects',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          page: page,
          limit: limit,
        },
      });
    };

    this.getCheckoutObject = function (checkoutObjectId) {
      return $http({
        method: 'GET',
        url: `/api/checkout_objects/${checkoutObjectId}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };
  },
]);
