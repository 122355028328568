app.controller('UsersEditMerchantTiersDialogController', [
  '$scope',
  '$uibModalInstance',
  'merchantTiers',
  'getDiscountTranslate',
  'spendingToMoney',
  'mainConfig',
  '$filter',
  function (
    $scope,
    $uibModalInstance,
    merchantTiers,
    getDiscountTranslate,
    spendingToMoney,
    mainConfig,
    $filter,
  ) {
    $scope.merchantTiers = merchantTiers;
    $scope.merchantName = mainConfig.merchantData.name;
    $scope.getDiscountTranslate = getDiscountTranslate;

    $scope.getConditionHash = function (rules) {
      var expireDate = $filter('translate')(
        'users.membership.expire.unlimited',
      );
      var upgradeRule = _.find(rules, function (rule) {
        return rule.effect_type === 'upgrade';
      });
      var extendRule = _.find(rules, function (rule) {
        return rule.effect_type === 'extend';
      });
      if (upgradeRule.effect_interval.type === 'within_interval') {
        expireDate = $filter('translate')(
          'users.membership.tier.expire.month',
          { month: upgradeRule.effect_interval.time_value },
        );
      }

      return {
        upgradeCondition: getTierCondition(upgradeRule),
        tierExpireDate: expireDate,
        extendCondition: getTierCondition(extendRule),
      };
    };

    function getTierCondition(rule) {
      if (_.isNull(rule) || _.isUndefined(rule)) {
        return null;
      }
      var currencySymbol =
        mainConfig.merchantData.base_currency.alternate_symbol;
      var spending =
        currencySymbol + spendingToMoney(rule.total_spending.cents);

      if (rule.effect_type === 'extend') {
        return $filter('translate')('users.membership.extend_valid_period', {
          months: rule.effect_interval.time_value,
          type: rule.condition_interval.type,
          spending: spending,
        });
      } else {
        if (rule.condition_interval.type === 'within_interval') {
          return $filter('translate')(
            'users.membership.all.spending_within_months',
            {
              months: rule.condition_interval.time_value,
              spending: spending,
            },
          );
        }
        return $filter('translate')(
          'users.membership.all.spending_single_purchase',
          {
            spending: spending,
          },
        );
      }
    }

    $scope.cancelModal = function () {
      $uibModalInstance.close();
    };
  },
]);
