app.directive('taxFeeNotice', [
  '$timeout',
  '$filter',
  function ($timeout, $filter) {
    return {
      restrict: 'E',
      template:
        '<div class="tax-fee-notice" ng-show="showTaxFeeNotice" ng-cloak>' +
        $filter('translate')('tax.price_updated') +
        '</div>',
      link: function (scope) {
        scope.$on('tax.fee.address.changed', function () {
          scope.showTaxFeeNotice = false;
        });

        scope.$on('tax.fee.isUpdated', function () {
          scope.showTaxFeeNotice = true;
        });

        $('.cart-summary').on('show.bs.collapse', function () {
          $timeout(function () {
            scope.showTaxFeeNotice = false;
          });
        });
      },
    };
  },
]);
