import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('dynamicDeliveryFeeNotice', [
  '$filter',
  '$timeout',
  function ($filter, $timeout) {
    return {
      restrict: 'E',
      template:
        '<div class="tax-fee-notice" ng-if="showDynamicDeliveryFeeNotice" ng-cloak>' +
        $filter('translate')('dynamic.delivery.fee.updated.notice') +
        '</div>',
      link: function (scope) {
        scope.$on(CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE, function () {
          scope.showDynamicDeliveryFeeNotice = false;
        });

        scope.$on('delivery.fee.isUpdated', function () {
          if (!$('#cartSummary').hasClass('in'))
            scope.showDynamicDeliveryFeeNotice = true;
        });

        $('.cart-summary').on('show.bs.collapse', function () {
          $timeout(function () {
            scope.showDynamicDeliveryFeeNotice = false;
          });
        });
      },
    };
  },
]);
