import './services.sl-payment';
import { isSupportPaymentMethod } from '@payment/payment-sdk';
import template from '../../../../../public/themes/shared/checkout/templates.shopline_payment_v2_apple_pay_button.html';

app.directive('shoplinePaymentV2ApplePayButton', [
  'cart',
  'slPaymentService',
  function (cart, slPaymentService) {
    return {
      restrict: 'E',
      templateUrl: template,
      scope: {
        onSdkInit: '&',
        onCheckout: '&',
      },
      link: function (scope) {
        scope.showApplePayButton = false;

        scope.onApplePayButtonClicked = function () {
          if (!window.ApplePaySession) return;

          scope.onCheckout({ value: scope.btnCheckout });
        };

        const initButton = () => {
          const paymentMethod =
            slPaymentService.paymentMethodMap[cart.payment_method];

          isSupportPaymentMethod(paymentMethod).then((usable) => {
            if (!usable) {
              scope.$emit('checkout.payments.apple_pay.not_supported');
            } else {
              scope.showApplePayButton = true;
            }
          });

          scope.btnCheckout = Ladda.create(
            angular.element('.apple-pay-button')[0],
          );
        };

        initButton();
      },
    };
  },
]);
