export const ADD_CARD_RESULT_STATUS = {
  FAILED: 'FAILED',
  VALIDATION_FAILED: 'validation failed',
};
export const BIND_CARD_REDIRECT_QUERY_PARAMS = {
  POLLING_ID: 'polling_id',
  STATUS: 'status',
  AUTHORIZATION_TOKEN: 'authorization_token',
};
export const LOCAL_STORAGE_WEBHOOK_ID = 'payment_instrument_webhook_map';

export const CHECK_POLLINGID_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const POLLING_INTERVAL = 5000;
