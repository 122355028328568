import './services.exec_command';
import './services.cart';
app.directive('cartShareBtn', [
  '$filter',
  'cartService',
  'userAgentService',
  '$location',
  '$rootScope',
  'execCommandService',
  function (
    $filter,
    cartService,
    userAgentService,
    $location,
    $rootScope,
    execCommandService,
  ) {
    return {
      restrict: 'E',
      template:
        '<a class="cart-share-btn" ng-click="copyUrl()">' +
        '<i class="fa fa-share-alt" aria-hidden="true"></i>' +
        '</a>',
      link: function (scope) {
        $location.search('shared_cart_items', null);
        scope.copyUrl = function () {
          execCommandService.copyToClipboard(scope.url);
          $('#checkout-info').show();
        };
        var generateShareLink = function (sharedCartItems, referralCode) {
          var url = new URL(window.location.href);
          url.searchParams.set('shared_cart_items', sharedCartItems);

          if (referralCode) {
            url.searchParams.set('sl-ref', referralCode);
          }
          scope.url = url.href;
        };
        $rootScope.$on('cartItemsUpdated', getLink);
        function getLink() {
          cartService.getShareItems().then(function (resp) {
            var sharedCartItems = resp.data.data.cart_items;
            var referralCode = resp.data.data.referral_code;
            generateShareLink(sharedCartItems, referralCode);
            var info = $filter('translate')('cart.copy.info.copied');
            if (referralCode) {
              info =
                info +
                $filter('translate')('cart.copy.info.affiliate_code', {
                  code: referralCode,
                });
            }
            info =
              info +
              $filter('translate')('cart.copy.info.product_count', {
                count:
                  (sharedCartItems && sharedCartItems.split(',').length) || 0,
              });
            $('#checkout-info').hide();
            $('#checkout-info').html(info);
          });
        }
        if (!userAgentService.isMobi()) {
          $('.cart-share-btn').tooltip({
            title: $filter('translate')('cart.share.btn.tooltip'),
          });
        }

        getLink();
      },
    };
  },
]);
