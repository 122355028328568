import './services.orders';
import './services.product_review_comment';
app.controller('productReviewCommentsOrdersController', [
  '$scope',
  'productReviewCommentService',
  'staticResourceHost',
  'mainConfig',
  'slFeatureService',
  'ordersService',
  function (
    $scope,
    productReviewCommentService,
    staticResourceHost,
    mainConfig,
    slFeatureService,
    ordersService,
  ) {
    $scope.slFeatureService = slFeatureService;
    $scope.ordersService = ordersService;
    $scope.pagination = {
      total: 0,
      page: 1,
      offset: 0,
      limit: 20,
    };
    $scope.getOrders = function () {
      productReviewCommentService
        .getUncommentedOrders($scope.pagination)
        .then(function (result) {
          if (result.data.data && result.data.data.items) {
            $scope.completedUncommentedOrders = result.data.data.items;
            $scope.pagination = {
              total: result.data.data.total,
              page: result.data.data.offset / result.data.data.limit + 1,
              limit: result.data.data.limit,
              offset: result.data.data.offset,
            };
            $scope.currentOrderId = $scope.completedUncommentedOrders[0].id;
          } else {
            $scope.completedUncommentedOrders = [];
          }
        });
    };
    $scope.getOrders();

    $scope.setCurrentOrderId = function (orderId) {
      $scope.currentOrderId = orderId;
    };

    $scope.productReviewEnabled =
      productReviewCommentService.productReviewEnabled;
    $scope.amazonProductReviewEnabled =
      productReviewCommentService.amazonProductReviewEnabled;
    $scope.staticResourceHost = staticResourceHost;
    $scope.currentUser = mainConfig.currentUser;
    $scope.productReviewRewardType = null;
    $scope.productReviewRewardRule = {};

    const getProductReviewRewardConfig = () => {
      if (
        !slFeatureService.hasFeature('product_review_reward') ||
        !mainConfig.merchantData.product_setting.enabled_product_review
      ) {
        return;
      }

      return productReviewCommentService
        .getProductReviewRewardConfig()
        .then(({ rewardType, rule }) => {
          $scope.productReviewRewardType = rewardType;
          $scope.productReviewRewardRule = rule;
        });
    };
    getProductReviewRewardConfig();
  },
]);
