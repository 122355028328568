import { RichText } from '@payment/safety-rich-text-parser';

app.directive('checkoutPaymentActivityContent', [
  () => {
    return {
      restrict: 'A',
      link: function (scope, element) {
        const rtParser = new RichText();

        const parsePaymentActivityContent = () => {
          const activityContent = element.data('activity-content');
          const dom = rtParser.autoParseToDom(activityContent);
          element.html(dom);
        };
        parsePaymentActivityContent();
      },
    };
  },
]);
