/**
 * To prevent Safari from canceling API requests when they run concurrently with a URL redirect,
 * a default 0.5-second delay is introduced to help ensure the API request is sent.
 * @param {string} url
 * @param {number} delay - default 500 milliseconds, it's a rough estimate to prevent Safari from canceling API requests.
 * @returns {void}
 */
export const delayRedirect = (url, delay = 500) => {
  setTimeout(() => {
    window.location.href = url;
  }, delay);
};
