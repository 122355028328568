app.directive('starRating', [
  function () {
    return {
      scope: {
        score: '=',
        maxScore: '=',
      },
      template:
        '<span ng-class="star.className" ng-repeat="star in stars track by $index" ng-click="setScore($index + 1)"></span>',
      link: function (scope) {
        scope.stars = Array(scope.maxScore).fill('fa fa-star');

        var updateStars = function () {
          scope.stars.forEach(function (star, index) {
            var className = index < scope.score ? 'fa fa-star' : 'fa fa-star-o';
            scope.stars[index] = { className: className };
          });
        };

        scope.setScore = function (score) {
          scope.score = score;
        };

        scope.$watch('score', updateStars);
      },
    };
  },
]);
