import './service.setting';
import './services.coupons.popup';
import './services.recaptcha';
import './constants.custom-validator';
import './services.slpixel';
import './services.fb';
import './services.mobile-phone';
import './services.benchat';
import './services.feature';
import { getJsMonth, validateBirthday } from '../../utils/birthday';
import { validatePhone } from '../../utils/intlTel';
import { BIRTHDAY_ERROR } from '../constants/user';

app.controller('RegistrationsNewController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'featureService',
  'benchatService',
  '$q',
  'mobilePhoneService',
  'staticImageHost',
  'fbService',
  'slPixelService',
  'customValidator',
  'RecaptchaService',
  '$cookies',
  'memberGetCouponPopupServices',
  'slFeatureService',
  'settingService',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    featureService,
    benchatService,
    $q,
    mobilePhoneService,
    staticImageHost,
    fbService,
    slPixelService,
    customValidator,
    RecaptchaService,
    $cookies,
    memberGetCouponPopupServices,
    slFeatureService,
    settingService,
  ) {
    $scope.BIRTHDAY_ERROR = BIRTHDAY_ERROR;
    const timeZoneOffset = new Date().getTimezoneOffset() / -60;
    const SHOPPER_APP = 'shopper_app';
    const SHOP_CRM = 'shop_crm';

    $scope.benchatSubscriptions = [];
    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.staticImageHost = staticImageHost;
    $scope.featureService = featureService;
    $scope.resource = resource.data;
    $scope.maxlength = 50;
    $scope.isSimplifiedUi = $location.search()['ui'] === 'simplified';
    $scope.fromSource =
      $location.search()['from'] ||
      ($scope.isSimplifiedUi ? SHOPPER_APP : null);

    const isFromShopCrm = $scope.fromSource === SHOP_CRM;

    memberGetCouponPopupServices.openGetCouponPopup({ type: 'login' });

    $scope.isSimplifyMobileLogin = function () {
      const queryString = $location.search();
      return (
        queryString['ui'] === 'simplified' &&
        queryString['login_method'] === 'mobile'
      );
    };

    $scope.genders = ['male', 'female', 'other'].map(function (key) {
      return { id: key, label: $filter('translate')('users.signup.' + key) };
    });

    $scope.birthdayForm = {
      year: null,
      month: null,
      date: null,
      error: null,
    };

    if (featureService.hasFeature('mobile_signup_p2')) {
      $scope.resource.isMobilePhoneSignUp =
        featureService.hasFeature('mobile_signup_only') ||
        $scope.isSimplifyMobileLogin();

      if ($scope.resource.isMobilePhoneSignUp) {
        $scope.resource.email = null;
      }

      $scope.signUpSelections = ['email', 'mobile_phone'].map(function (key) {
        return {
          label: $filter('translate')('users.signup.' + key),
          value: key == 'mobile_phone',
        };
      });
    }

    $scope.resource.owner_id = mainConfig.merchantData._id;

    $scope.$watch('resource.email', function (newVal, oldVal) {
      if (newVal != oldVal && $scope.userForm.email != undefined) {
        $scope.userForm.email.$setValidity('general', true);
      }
    });

    $scope.clickFBLogin = function (appId, version) {
      if (isFromShopCrm) {
        return window.location.replace(`/users/auth/facebook?from=${SHOP_CRM}`);
      } else if (fbService.isMessengerInAppBrowser()) {
        return window.location.replace('/users/auth/facebook');
      }
      fbService.login(appId, version).then(function () {
        window.location.replace('/');
      });
    };

    $scope.$on('mobile-step-change', function (event, step) {
      $scope.mobileSignInStep = step;
    });

    $scope.setWidgetId = function (widgetId) {
      //'Created widget ID: %s', widgetId
      $scope.widgetId = widgetId;
    };

    const getUserSetting = () => {
      $scope.userSetting = settingService.getUserSettingFromMainConfig();
      $scope.birthdayRequired =
        $scope.userSetting.general_fields?.find(
          (field) => field.type === 'birthday',
        )?.options.signup.required === 'true';
    };
    getUserSetting();

    $scope.subscription_tags = {
      'marketing.news': slFeatureService.hasFeature('pretick_accept_marketing'),
    };

    // T&C checkbox
    $scope.state = {
      tncChecked: false,
    };

    $scope.facebook_url = function () {
      let url = '/users/auth/facebook?signup=true';
      if ($scope.subscription_tags['marketing.news']) {
        url += '&marketing=true';
      }
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptDataParams = urlQueries.get('encrypt_data');
      if (encryptDataParams) {
        // URLSearchParams will decode params once so we need to encode to handle uri contains plus symbol case
        // as fb redirect_uri
        const encryptData = $window.encodeURIComponent(encryptDataParams);
        url += `&encrypt_data=${encryptData}`;
      }

      return url;
    };

    $scope.line_url = function (channel) {
      return benchatService.getLineLoginUri(channel.platformChannelId);
    };

    $scope.userForm = {};

    $scope.mobileValidationFormSubmit = function () {
      $scope.userForm.errorMessages = null;
      $scope.resource.mobile_phone = $scope.resource.mobilePhone;
      $scope.resource.country_calling_code = $scope.resource.countryCallingCode;
      $scope.showMobileUnVerifiedMessage = false;

      $http({
        method: 'POST',
        url: `/api/users/register_validation${
          isFromShopCrm ? '?from=shop_crm' : ''
        }`,
        data: { user: $scope.resource },
      }).then(
        function (response) {
          if (response.data && response.data.message) {
            $scope.userForm.successMessages = response.data.message;
            if ($scope.isSimplifiedUi) {
              $scope.userForm.submitting = false;
            }
          } else {
            $scope.$broadcast('mobile-step-change', 'check_mobile');
            $scope.userForm.submitting = false;
            slPixelService.hdTracking('signUp');
          }
        },
        function (response) {
          $scope.userForm.errorMessages = response.data.error_messages;
          $scope.userForm.submitting = false;
        },
      );
    };

    $scope.resetRegistrationFormError = function () {
      $scope.userForm.errorMessages = null;
      $scope.userForm.submitting = false;
    };

    function createMobileRegex(country) {
      return {
        test: function (value) {
          return validatePhone(value || '', country).length === 0;
        },
      };
    }

    var renderBenchatFields = function () {
      var benchatFields = {
        subscriptions: {
          line: [],
          facebook: [],
        },
      };

      if (
        document.getElementsByName('benchatFields[subscriptions][line]')
          .length > 0
      ) {
        benchatFields.subscriptions.line = document.getElementsByName(
          'benchatFields[subscriptions][line]',
        )[0].value;
      }

      if (
        document.getElementsByName('benchatFields[subscriptions][facebook]')
          .length > 0
      ) {
        benchatFields.subscriptions.facebook = document.getElementsByName(
          'benchatFields[subscriptions][facebook]',
        )[0].value;
      }

      return benchatFields;
    };

    $scope.onCountryCodeChange = function (countryData) {
      $scope.countryMobilePattern = createMobileRegex(countryData.iso2);
      $scope.resource.countryCallingCode = countryData.dialCode;
      $scope.resource.countryCode = countryData.iso2;

      const isValid = $scope.countryMobilePattern.test(
        $scope.resource.mobilePhone,
      );
      $scope.userForm.mobilePhone.$setValidity('pattern', isValid);
    };

    $scope.formatMobilePhone = function () {
      const { mobilePhone, countryCode } = $scope.resource;

      if (
        !mobilePhone ||
        !countryCode ||
        validatePhone(mobilePhone, countryCode).length !== 0
      )
        return;

      $scope.resource.mobilePhone = intlTelInputUtils
        .formatNumber(
          '+' + $scope.resource.countryCallingCode + mobilePhone,
          '',
          intlTelInputUtils.numberFormat.NATIONAL,
        )
        .replace(/\D/g, '');
    };

    $scope.handleBirthdayChange = () => {
      const { birthday_format: birthdayFormat, minimum_age_limit: minAge } =
        $scope.userSetting;
      const { year, month, date } = $scope.birthdayForm;
      $scope.birthdayForm.error = validateBirthday({
        birthdayFormat,
        year,
        month: getJsMonth(month),
        date,
        minAge,
        nullable: !$scope.birthdayRequired,
      });
    };

    $scope.registrationFormSubmit = function () {
      $scope.userForm.errorMessages = null;
      $scope.userForm.showUnVerifiedMessage = false;
      $scope.resource.subscribed_email_types = [];

      angular.forEach($scope.subscription_tags, function (value, key) {
        if (value) {
          $scope.resource.subscribed_email_types.push(key);
        }
      });

      const { year, month, date } = $scope.birthdayForm;
      $scope.resource.birth_year = year;
      $scope.resource.birth_month = month;
      $scope.resource.birth_day = date;

      var path = '/api/users';

      if (!$scope.isMobilePhoneSignUp()) {
        $scope.resource.email = $scope.resource.email.toLowerCase();
      }
      $scope.resource.mobile_phone = $scope.resource.mobilePhone;
      $scope.resource.country_calling_code = $scope.resource.countryCallingCode;
      $scope.resource.time_zone_offset = timeZoneOffset;

      var benchatFields = renderBenchatFields();

      const checkSimplified = $location.search()['ui'] == 'simplified';
      const checkNextExist = $location.search()['next'] != '';
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptData = urlQueries.get('encrypt_data');
      const channelId = urlQueries.get('channel_id');
      const from = urlQueries.get('from');

      if (checkSimplified) {
        $scope.userForm.successMessages = [];
      }

      let payload = {
        user: $scope.resource,
        benchatFields: benchatFields,
        recaptchable: true,
        encrypt_data: encryptData,
        channel_id: channelId,
        from,
      };
      if (checkSimplified) payload.ui = 'simplified';
      if (checkNextExist) payload.next = $location.search()['next'];
      if ($scope.fromSource) payload.from = $scope.fromSource;

      return $q(function (resolve, reject) {
        $http({
          method: 'POST',
          url: path,
          data: payload,
        })
          .then(function (res) {
            var data = res.data;
            var redirect_to = data.redirect_to;
            slPixelService.hdTracking('signUp');

            if (redirect_to && redirect_to.indexOf('confirmation') > 0) {
              $window.location.href = redirect_to;
            } else if (redirect_to) {
              var fbc = $cookies.get('_fbc') || '';
              var fbp = $cookies.get('_fbp') || '';
              $window.location.href =
                '/sign_up_confirmation?fbc=' + fbc + '&fbp=' + fbp;
            }

            $scope.userForm.successMessages = [data.message];
            $scope.userForm.submitting = false;
            resolve();
          })
          .catch(function (res) {
            var data = res.data;
            $scope.userForm.errorMessages = data.error_messages;

            if (data.error) {
              if (data.reached_rate_limit)
                $scope.userForm.errorMessages = data.error;
              $scope.userForm.showUnVerifiedMessage = !data.reached_rate_limit;
              $scope.unConfirmedEmail = data.unconfirmed_email;
            }
            $scope.userForm.submitting = false;

            reject();
          });
      });
    };

    $scope.isMobilePhoneSignUp = function () {
      return (
        featureService.hasFeature('mobile_signup_p2') &&
        $scope.resource.isMobilePhoneSignUp
      );
    };

    $scope.formSubmit = function () {
      if (
        $scope.userForm.submitting ||
        $scope.userForm.$invalid ||
        $scope.birthdayForm.error
      ) {
        return;
      }

      if ($scope.isSimplifiedUi && !$scope.state.tncChecked) {
        $('.tnc-overlay').toggle();
        document.activeElement.blur();
        return;
      }

      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      if ($scope.isMobilePhoneSignUp()) {
        $scope.mobileValidationFormSubmit();
      } else {
        $scope
          .registrationFormSubmit()
          .then(_.noop, _.noop)
          .finally(function () {
            RecaptchaService.reset('sign-up-recaptcha');
          });
      }
    };

    $scope.changeSignupSelection = function () {
      if ($scope.isMobilePhoneSignUp()) {
        $scope.resource.email = null;
      } else {
        $scope.resource.mobilePhone = null;
        $scope.resource.countryCallingCode = null;
        $scope.resource.countryCode = null;
      }
    };

    $scope.togglePassword = function () {
      const element = angular.element(document);
      const password = element.find('input[name=password]');
      const type = password.attr('type') === 'password' ? 'text' : 'password';
      password.attr('type', type);
      $('.signup-eye-toggle').toggle();
      $('.signup-eye-toggle-slash').toggle();
    };

    $scope.getQueryString = function () {
      return new URLSearchParams($location.search()).toString();
    };

    $scope.clickTerms = function () {
      window.location.href =
        '/about/terms?with_back_button=true&' + $scope.getQueryString();
    };

    $scope.clickAgree = function () {
      $('.signup-page-checkbox').prop('checked', true);
      $scope.state.tncChecked = true;
      $('.tnc-overlay').toggle();
    };
  },
]);
