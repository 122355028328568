app.service('braintreeService', [
  '$http',
  '$q',
  function ($http, $q) {
    return {
      getClientToken: function (payment_id) {
        // http://www.metaltoad.com/blog/angularjs-promises-from-service-to-template
        var promise = $http.post('/api/orders/braintree_token', {
          payment_id: payment_id,
        });
        var tokenDeferObject = $q.defer();

        promise.then(
          // OnSuccess function
          function (answer) {
            // This code will only run if we have a successful promise.
            tokenDeferObject.resolve(answer.data.token);
          },
          // OnFailure function
          function (reason) {
            // This code will only run if we have a failed promise.
            tokenDeferObject.reject(reason);
          },
        );

        return tokenDeferObject.promise;
      },
      processCard: function (client_token, cardParams) {
        // console.log("client_token:"+client_token);
        var nonceDeferObject = $q.defer();
        var client = new braintree.api.Client({
          clientToken: client_token,
        });
        client.tokenizeCard(
          {
            cardholderName: cardParams.holdername,
            number: cardParams.credit_card_number,
            expirationDate: cardParams.expiry_date,
            cvv: cardParams.cvc,
          },
          function (err, nonce) {
            // Send nonce to your server
            // console.log("nonce:"+nonce);
            // console.log(err);
            if (err) {
              nonceDeferObject.reject(err);
            } else {
              nonceDeferObject.resolve(nonce);
            }
          },
        );
        return nonceDeferObject.promise;
      },
    };
  },
]);
