import './services.tracker';
import './services.pnotify';
import './services.benchat';
import { delay, isEmpty } from 'lodash-es';
import {
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPE_TOPIC_MAP,
  PLATFORM,
  SUBSCRIPTION_STATUS_TYPE,
} from '../../constants/benchat';
import {
  EVENT_NAME_FOR_SUBSCRIPTION,
  EVENT_CATEGORY_FOR_SUBSCRIPTION,
  EVENT_PROPERTY_FOR_SUBSCRIPTION,
  EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION,
} from '../constants/tracker';

const DEFAULT_POLLING_COUNT = 5;

app.directive('facebookMessengerCheckbox', [
  'mainConfig',
  'benchatService',
  '$uibModal',
  '$q',
  '$filter',
  'pnotifyService',
  'trackerService',
  function (
    mainConfig,
    benchatService,
    $uibModal,
    $q,
    $filter,
    pnotifyService,
    trackerService,
  ) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.facebook-messenger-checkbox.html'),
      scope: {
        benchatConfig: '=',
        benchatChannel: '=',
        customerId: '@',
        sessionId: '@',
        notificationType: '@',
        trackerPropertyPath: '@',
      },
      link: function (scope) {
        let pollingCount = DEFAULT_POLLING_COUNT;
        scope.staticResourceHost = mainConfig.staticResourceHost;
        scope.benchatSubscriptions = [];
        scope.state = {
          isSubscriptionLoaded: false,
        };
        scope.customerIdentifier = scope.customerId || scope.sessionId;
        scope.topics = NOTIFICATION_TYPE_TOPIC_MAP[scope.notificationType];
        scope.origin = window.location.origin.replace('http://', 'https://');

        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.getDataRef = () => `${scope.userRef}-${scope.notificationType}`;

        const updateUserRef = function () {
          scope.userRef = benchatService.generateUserRef(
            scope.customerIdentifier,
          );
        };
        const getSubscriptions = function () {
          return benchatService
            .getSubscriptions(
              scope.customerIdentifier,
              scope.topics,
              PLATFORM.FACEBOOK,
            )
            .then(function (result) {
              const subscriptions = result.data.subscriptions;
              if (isEmpty(subscriptions)) {
                return [];
              }
              return subscriptions.filter(
                (subscription) => subscription.isActive,
              );
            });
        };

        const init = function () {
          scope.state.isSubscriptionLoaded = false;
          getSubscriptions().then(function (subscription) {
            scope.state.isSubscriptionLoaded = true;
            scope.benchatSubscriptions = subscription;
            updateUserRef();
          });
        };
        init();

        scope.optOut = function () {
          $q.all(
            scope.benchatSubscriptions.map((subscription) =>
              benchatService.deactivateSubscription(subscription._id),
            ),
          )
            .then(function () {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.success'),
                {},
              );
              init();
            })
            .catch(() => {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.failure'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            });
        };

        const getSubscriptionsResult = () =>
          delay(() => {
            benchatService
              .getSubscriptions(
                scope.customerIdentifier,
                scope.topics,
                PLATFORM.FACEBOOK,
              )
              .then((response) => {
                const subscriptionStatus = benchatService.getSubscribeStatus(
                  response.data.subscriptions,
                  scope.userRef,
                );
                if (
                  subscriptionStatus === SUBSCRIPTION_STATUS_TYPE.LOADING &&
                  pollingCount > 0
                ) {
                  pollingCount -= 1;
                  return getSubscriptionsResult();
                }

                let message = '';
                switch (subscriptionStatus) {
                  case SUBSCRIPTION_STATUS_TYPE.SUCCESS:
                    message = 'subscribe.success';
                    break;
                  case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_DOUBLE_BIND_ERROR:
                    message = 'subscribe.failure.already_bound';
                    break;
                  case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_BIND_ERROR:
                    message = 'subscribe.failure';
                    break;
                  case SUBSCRIPTION_STATUS_TYPE.UNKNOWN_ERROR:
                    message = 'subscribe.facebook.failure';
                    break;
                  // handle if there's no specific subscription status
                  // TODO: dealing with NO_RECORD on SL-42095
                  default:
                    break;
                }
                const isError =
                  subscriptionStatus !== SUBSCRIPTION_STATUS_TYPE.SUCCESS;
                if (isError) {
                  updateUserRef();
                } else {
                  scope.benchatSubscriptions =
                    response.data.subscriptions.filter(
                      (subscription) => subscription.isActive,
                    );
                }
                pollingCount = DEFAULT_POLLING_COUNT;
                scope.state.isSubscriptionLoaded = true;
                // handle user not finishing subscription process
                if (message) {
                  pnotifyService.notify(
                    $filter('translate')(message),
                    isError
                      ? {
                          customClass: 'error',
                          icon: 'fa fa-exclamation-triangle',
                        }
                      : {},
                  );
                }
              });
          }, 1000);

        const onWindowFocus = () => {
          // this's supposed to trigger only once.
          window.removeEventListener('focus', onWindowFocus);

          scope.state.isSubscriptionLoaded = false;
          getSubscriptionsResult();
        };

        scope.openSubscriptionDialog = () => {
          // handle no tracker on old member center
          if (scope.trackerPropertyPath) {
            trackerService.track({
              type: EVENT_NAME_FOR_SUBSCRIPTION.MEMBER_SUBSCRIPTION_BUTTON_CLICK,
              data: {
                event_category:
                  EVENT_CATEGORY_FOR_SUBSCRIPTION.MEMBER_SUBSCRIPTION,
                [EVENT_PROPERTY_FOR_SUBSCRIPTION.SUBSCRIPTION_TYPE]:
                  scope.notificationType === NOTIFICATION_TYPE.ORDER
                    ? EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION.ORDER_NOTIFICATION
                    : EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION.PROMOTION_NOTIFICATION,
                [EVENT_PROPERTY_FOR_SUBSCRIPTION.SUBSCRIPTION_PATH]:
                  EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION[
                    scope.trackerPropertyPath
                  ],
                [EVENT_PROPERTY_FOR_SUBSCRIPTION.THIRD_PARTY]:
                  EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION.FACEBOOK,
              },
            });
          }

          $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.facebook-subscription.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              function ($scope, $uibModalInstance) {
                $scope.closeDialog = () => {
                  $uibModalInstance.close();
                };

                $scope.subscribeToFacebook = () => {
                  const { platformChannelId } = scope.benchatChannel;
                  const ref = scope.getDataRef();
                  const url = benchatService.getMDotMeLink(
                    platformChannelId,
                    ref,
                  );
                  window.open(url);

                  trackerService.track({
                    type: EVENT_NAME_FOR_SUBSCRIPTION.MEMBER_SUBSCRIPTION_POPUP_CLICK,
                    data: {
                      event_category:
                        EVENT_CATEGORY_FOR_SUBSCRIPTION.MEMBER_SUBSCRIPTION,
                      [EVENT_PROPERTY_FOR_SUBSCRIPTION.THIRD_PARTY]:
                        EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION.FACEBOOK,
                    },
                  });

                  $uibModalInstance.close();
                  window.addEventListener('focus', onWindowFocus);
                };
              },
            ],
            windowClass: 'facebook-subscription-dialog-window',
            backdropClass: 'facebook-subscription-dialog-backdrop',
          });
        };
      },
    };
  },
]);
