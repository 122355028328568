import './services.location';
app.controller('LineLiffControlller', [
  '$scope',
  'mainConfig',
  'locationService',
  'imageServiceEndpoint',
  function ($scope, mainConfig, locationService, imageServiceEndpoint) {
    $scope.imageServiceEndpoint = imageServiceEndpoint;
    $scope.error = {};
    $scope.isNewUser = false;
    /* TODO: migrate to v2
    liff.init(
      function () {
      },
      function (err) {
      },
    );
    */
    $scope.handleConfirmClick = function () {
      liff.closeWindow();
    };
  },
]);
