import './services.pnotify';
app.controller('HomeController', [
  'flash',
  'pnotifyService',
  function (flash, pnotifyService) {
    if (flash.verification_message) {
      PNotify.removeAll();
      pnotifyService.notify(flash.verification_message, {});
    }
  },
]);
