app.directive('checkoutTwoCheckoutPayment', [
  'mainConfig',
  function (mainConfig) {
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope) {
        return $.getScript(
          mainConfig.universalPaymentSDKUrl +
            '/pay/paysdk/' +
            mainConfig.efoxPayVersion +
            '/index.js',
          function () {
            var localeMapping = {
              de: 'de',
              en: 'en',
              fr: 'fr',
              ja: 'ja',
              th: 'th',
              'zh-cn': 'zh',
              'zh-hant': 'zy',
              'zh-hk': 'zy',
              'zh-tw': 'zy',
            };

            var form = document.getElementById('payment-form');
            var locale = form.getAttribute('locale');
            var holderName = form.getAttribute('holder-name');
            var publicKey = {
              key: form.getAttribute('public-key'),
            };
            var initialData = {
              domId: 'payment-form',
              sdkName: '2checkout',
              label: {
                userNameLabel: holderName,
              },
              styleOption: {
                commonStyle: {},
                componentStyle: {},
              },
              language: localeMapping[locale],
            };
            var init = function () {
              scope.universalPay = new efoxPay.default(publicKey, initialData);
            };

            init();
          },
        );
      },
    };
  },
]);
