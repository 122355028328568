import './services.pnotify';
import './services.logger';
import './service.promotion';
import { COUPON_ERROR } from '../constants/promotion';

const CLAIM_RESULT = {
  succeed: 'succeed',
  failed: 'failed',
};

const CLAIM_COUPON_BY_ID_I18N = {
  succeed: 'coupon.congratulation',
  // failed
  [COUPON_ERROR.not_login]: 'coupon.login_or_register',
  [COUPON_ERROR.fulled]: 'coupon.usage_limit',
  [COUPON_ERROR.expired]: 'coupon.is_expired',
  [COUPON_ERROR.not_eligible]: 'coupon.not_eligible',
  [COUPON_ERROR.already_taken]: 'coupon.already_taken',
  [COUPON_ERROR.unfit_first_purchase]: 'coupon.unfit_first_purchase',
};

app.controller('CouponCenterController', [
  '$scope',
  'promotionService',
  'staticResourceHost',
  'logger',
  'pnotifyService',
  '$filter',
  function (
    $scope,
    promotionService,
    staticResourceHost,
    logger,
    pnotifyService,
    $filter,
  ) {
    $scope.staticResourceHost = staticResourceHost;

    $scope.coupons = [];
    $scope.isLoading = false;

    const getCoupons = () => {
      $scope.isLoading = true;

      promotionService
        .getCouponCenterCoupons()
        .then((res) => {
          $scope.coupons = res.data.data.items;
        })
        .catch((error) => {
          logger.error('failed to getCoupons in coupon center', error);
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };
    getCoupons();

    $scope.handleClaimByCodeSucceed = ({ name }) => {
      pnotifyService.notify(
        $filter('translate')('coupon_input.claim_succeed.redirect', {
          couponName: name,
        }),
        { customClass: 'success', icon: 'fa fa-check-circle' },
        () => {
          window.location.href = '/coupons';
        },
      );
    };

    const setCouponClaimed = (targetCoupon) => {
      $scope.coupons = $scope.coupons.map((coupon) => {
        if (coupon._id === targetCoupon._id) {
          return { ...coupon, is_drew: true };
        }
        return coupon;
      });
    };

    $scope.claimCouponById = (coupon) => {
      promotionService
        .claimCouponById(coupon._id)
        .then((res) => {
          const { result, failed_status } = res.data;

          if (result === CLAIM_RESULT.succeed) {
            setCouponClaimed(coupon);
            pnotifyService.notify(
              $filter('translate')(CLAIM_COUPON_BY_ID_I18N.succeed),
              { customClass: 'success', icon: 'fa fa-check-circle' },
            );
          } else {
            pnotifyService.notify(
              $filter('translate')(CLAIM_COUPON_BY_ID_I18N[failed_status]),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
          }
        })
        .catch((error) => {
          logger.error('Error when claiming coupon by id', error);
          pnotifyService.notify(
            $filter('translate')('coupon_input.claim_failed'),
            {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            },
          );
        });
    };
  },
]);
