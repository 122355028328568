app.directive('deliveryFeeNotice', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'E',
      scope: {
        deliveryTitle: '@?',
      },
      template:
        '<div class="delivery-fee-notice" ng-cloak>' +
        '<i class="fa fa-exclamation-circle"></i>' +
        '{{ deliveryTitle }} ' +
        $filter('translate')('delivery_fee.pay.on.delivery') +
        '</div>',
    };
  },
]);
