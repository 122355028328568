const COLLAPSE_STATUS = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
};

const BLOCK_STATUS = {
  DEFAULT: 'default',
  ERROR: 'error',
};

export { COLLAPSE_STATUS, BLOCK_STATUS };
