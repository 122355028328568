import './services.image';
app.directive('imageBannerDisplay', [
  'imageService',
  '$window',
  '$filter',
  function (imageService, $window, $filter) {
    return {
      transclude: false,
      restrict: 'E',
      scope: {
        mobileMedia: '=',
        desktopMedia: '=',
      },
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.image-banner-display.html'),
      link: function (scope, element) {
        function getRightSizeImage() {
          var targetImage =
            element[0].getElementsByClassName('sl-lazy-image')[0];
          var config = { attributes: true };
          var callback = function (mutationsList, observer) {
            for (const mutation of mutationsList) {
              if (mutation.attributeName === 'src') {
                observer.disconnect();
                window.resizeImages(element[0]);
              }
            }
          };
          var observer = new MutationObserver(callback);
          observer.observe(targetImage, config);
        }

        getRightSizeImage();

        var isMobile = $window.innerWidth < 768;

        function switchMediaPlatform() {
          var isMobileValid = scope.mobileMedia;
          scope.media =
            isMobile && isMobileValid ? scope.mobileMedia : scope.desktopMedia;
        }

        switchMediaPlatform();

        scope.getImage = function (media) {
          var imageSize =
            $('image-banner-display .sl-lazy-image')
              .first()
              .attr('data-size') || '600x';
          return imageService.getMediaImageUrl(media, { size: imageSize });
        };

        scope.$on('window.resize', function () {
          if ($window.innerWidth < 768 && !isMobile) {
            isMobile = true;
          } else if ($window.innerWidth >= 768 && isMobile) {
            isMobile = false;
          }
          switchMediaPlatform();
        });

        scope.getAlt = function (media) {
          if (!media) return '';
          return $filter('translateModel')(media.alt_translations);
        };
      },
    };
  },
]);
