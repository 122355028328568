import { COUPON_ERROR } from '../constants/promotion';

app.service('memberGetCouponPopupServices', [
  '$filter',
  '$uibModal',
  '$location',
  'slFeatureService',
  'staticResourceHost',
  function (
    $filter,
    $uibModal,
    $location,
    slFeatureService,
    staticResourceHost,
  ) {
    const {
      result: couponResult,
      failed_status: failedStatus,
      name: promotionName,
    } = $location.search();
    const popupMessageEnumeration = {
      succeed: {
        title: promotionName,
        content: 'coupon.congratulation',
        confirm: 'coupon.view',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_success.png`,
      },
      // failed
      [COUPON_ERROR.not_login]: {
        title: $filter('translate')('coupon.login_to_receive'),
        content: 'coupon.login_or_register',
        confirm: 'coupon.login',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_not_logged_in.png`,
      },
      [COUPON_ERROR.fulled]: {
        title: promotionName,
        content: 'coupon.usage_limit',
        confirm: 'coupon.close',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_fail.png`,
      },
      [COUPON_ERROR.expired]: {
        title: promotionName,
        content: 'coupon.is_expired',
        confirm: 'coupon.close',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_fail.png`,
      },
      [COUPON_ERROR.not_eligible]: {
        title: promotionName,
        content: 'coupon.not_eligible',
        confirm: 'coupon.close',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_fail.png`,
      },
      [COUPON_ERROR.already_taken]: {
        title: promotionName,
        content: 'coupon.already_taken',
        confirm: 'coupon.close',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_fail.png`,
      },
      [COUPON_ERROR.unfit_first_purchase]: {
        title: promotionName,
        content: 'coupon.unfit_first_purchase',
        confirm: 'coupon.close',
        statusImage: `${staticResourceHost}web/v1/img/get_coupon_fail.png`,
      },
    };

    function openPopup({ onConfirm }) {
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/users/templates.dialog.member_get_coupon.html'),
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            const isFailed = couponResult === 'failed';
            const popupMessage = isFailed
              ? popupMessageEnumeration[failedStatus]
              : popupMessageEnumeration[couponResult];
            $scope.couponMessage = {
              ...popupMessage,
              isFailed,
            };
            $scope.confirm = function () {
              $uibModalInstance.close();
              if (typeof onConfirm === 'function') {
                onConfirm();
              }
            };
          },
        ],
        windowClass: 'coupon-get-modal-window',
      });
    }

    function openGetCouponPopup(props) {
      if (!slFeatureService.hasFeature('get_coupon')) {
        return;
      }

      const { type } = props;
      // the login page
      if (type === 'login' && couponResult === 'not_login') {
        openPopup(props);
        return;
      }
      // the coupon page
      if (
        type === 'coupon' &&
        (couponResult === 'succeed' ||
          (couponResult === 'failed' &&
            [
              COUPON_ERROR.fulled,
              COUPON_ERROR.expired,
              COUPON_ERROR.not_eligible,
              COUPON_ERROR.already_taken,
              COUPON_ERROR.unfit_first_purchase,
            ].includes(failedStatus)))
      ) {
        openPopup(props);
        return;
      }
    }

    return { openGetCouponPopup };
  },
]);
