app.directive 'applePayBtn', [
  '$window'
  '$q'
  '$http'
  'cart'
  'mainConfig'
  '$filter'
(
  $window
  $q
  $http
  cart
  mainConfig
  $filter
  )->
  loadScript = (u) ->
    d = document
    t = 'script'
    o = d.createElement(t)
    s = d.getElementsByTagName(t)[0]
    o.src = '//' + u;
    deferred = $q.defer()
    o.addEventListener 'load'
      ,(e) ->
        deferred.resolve()
        return
      ,false
    s.parentNode.insertBefore o, s
    deferred.promise
  return {
    templateUrl: require('../../../../../public/themes/v1/default/views/template.apple-pay.html')
    restrict: 'E'
    scope: {
      payment: "="
      onSuccess: "="
      beforePayment: "="
      afterPayment: "="
      loading: "="
      isDisabled: '='
    }
    link: (scope) ->
      loadScript('js.stripe.com/v2/').then ->
        $window.Stripe.setPublishableKey(scope.payment.config_data.publishable_key)
        $window.Stripe.applePay.checkAvailability (available) ->
          if available
            scope.$emit('checkout.payments.apple_pay.ready')
            document.getElementById('apple-pay-button').style.display = 'block'
          else
            scope.$emit('checkout.payments.apple_pay.not_supported')
            console.log 'Apple Pay is not available'
      scope.beginApplePay = ->
        return unless scope.beforePayment()
        paymentRequest = 
          countryCode: mainConfig.merchantData.base_country_code
          currencyCode: mainConfig.merchantData.base_currency_code
          total:
            label: mainConfig.merchantData.name
            amount: cart.total.label.split("$")[1].replace(",", "")
          lineItems: buildLineItems()
        session = $window.Stripe.applePay.buildSession(paymentRequest, (result, completion) ->
          createCharge(result.token.id).then (charge) ->
            completion(true)
            if scope.onSuccess
              scope.onSuccess(charge)
          , ->
            completion(false)
          if scope.afterPayment
              scope.afterPayment()
        , (error) ->
          scope.afterPayment(error.message)
          if scope.afterPayment
              scope.afterPayment()
        )
        session.begin()
      createCharge = (token, completion) ->
        $http.post 'api/payments/' + scope.payment._id + '/charge', {
          token: token
          cents: cart.total.cents
          currency: mainConfig.merchantData.base_currency_code
          description: mainConfig.merchantData.name
        }
        # http://andyshora.com/promises-angularjs-explained-as-cartoon.html
        .then (response) ->
          if typeof response.data != 'string'
            response.data
          else
            # invalid response
            $q.reject response.data
        , (response) ->
          # something went wrong
          $q.reject response.data
      buildLineItems = () ->

        _.chain [
          {
            type: 'final'
            label: $filter('translate') 'orders.fields.subtotal'
            amount: cart.subtotal.label.split("$")[1]
          }
          {
            type: 'final',
            label: $filter('translate') 'orders.fields.delivery_fee'
            amount: cart.delivery_fee.label.split("$")[1]
          }
          {
            type: 'final'
            label: $filter('translate') 'orders.fields.payment_fee'
            amount: cart.payment_fee.label.split("$")[1]
          }
          {
            type: 'final'
            label: $filter('translate') 'orders.fields.order_discount'
            amount: cart.discount.label.split("$")[1]
          }
        ]
        .filter (item) -> 
          item.amount
        .map (item) ->
          item.amount = item.amount.replace(",", "")
          item
        .value()
      return
  }
]
