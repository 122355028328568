import './services.pnotify';
import './services.coupons.popup';
import './service.promotion';
import { onTabActive } from '../features/member-center';
import {
  getGeneralCouponTagTypes,
  getPlatformCouponTagType,
} from '../../utils/promotion';
import {
  COUPON_TAG_TYPE,
  DISCOUNT_ON,
  COUPON_SCOPE,
} from '../constants/promotion';

app.controller('CouponsController', [
  '$scope',
  'promotionService',
  '$filter',
  '$element',
  '$location',
  '$uibModal',
  'memberGetCouponPopupServices',
  'slFeatureService',
  'pnotifyService',
  'staticResourceHost',
  function (
    $scope,
    promotionService,
    $filter,
    $element,
    $location,
    $uibModal,
    memberGetCouponPopupServices,
    slFeatureService,
    pnotifyService,
    staticResourceHost,
  ) {
    $scope.staticResourceHost = staticResourceHost;
    $scope.NO_COUPON_I18N = {
      [COUPON_SCOPE.comingSoon]: 'coupon.no_comingSoon_coupon',
      [COUPON_SCOPE.valid]: 'coupon.no_valid_coupon',
      [COUPON_SCOPE.invalid]: 'coupon.no_invalid_coupon',
    };

    $scope.hasCouponV2 = slFeatureService.hasFeature('coupon_v2');
    $scope.DISCOUNT_ON = DISCOUNT_ON;
    $scope.COUPON_SCOPE = COUPON_SCOPE;
    $scope.couponScopes = [
      COUPON_SCOPE.comingSoon,
      COUPON_SCOPE.valid,
      COUPON_SCOPE.invalid,
    ];

    const PAGE_SIZE = 24;
    $scope.pagination = {
      page: 1,
      maxPage: 0,
    };
    $scope.allCoupons = {
      [COUPON_SCOPE.comingSoon]: null,
      [COUPON_SCOPE.valid]: null,
      [COUPON_SCOPE.invalid]: null,
    };
    $scope.allMaxPage = {
      [COUPON_SCOPE.comingSoon]: 0,
      [COUPON_SCOPE.valid]: 0,
      [COUPON_SCOPE.invalid]: 0,
    };
    $scope.state = {
      loadingItems: false,
      couponScope: COUPON_SCOPE.valid,
    };

    const rawOffset = new Date().getTimezoneOffset() / -60;
    $scope.timezoneOffset =
      rawOffset < 0 ? rawOffset : '+' + Math.abs(rawOffset);

    memberGetCouponPopupServices.openGetCouponPopup({
      type: 'coupon',
      onConfirm: () => {
        const searches = $location.search();
        const couponResult = searches['result'];
        const couponScope = searches['coupon_status'] || 'valid';
        if (couponResult === 'succeed') {
          $scope.setCouponScope(couponScope);
        }
      },
    });

    const timeFormat = 'YYYY/MM/DD HH:mm';
    const viTimeFormat = 'DD/MM/YYYY HH:mm';
    function getCoupons(couponScope) {
      $scope.state.loadingItems = true;
      return promotionService
        .getCoupons(couponScope)
        .then(function (res) {
          $scope.allMaxPage[couponScope] = Math.ceil(
            res.data.data.items.length / PAGE_SIZE,
          );
          $scope.allCoupons[couponScope] = _.map(
            res.data.data.items,
            function (item) {
              return Object.assign(item, {
                formattedTime: dayjs(item.end_at).format(
                  $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                    ? viTimeFormat
                    : timeFormat,
                ),
                formattedStartTime: dayjs(item.start_at).format(
                  $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                    ? viTimeFormat
                    : timeFormat,
                ),
              });
            },
          );
        })
        .finally(function () {
          $scope.state.loadingItems = false;
        });
      // TODO: Add catch error handle
    }

    $scope.setCouponScope = function (couponScope) {
      $scope.state.couponScope = couponScope;
      $scope.pagination.page = 1;

      if ($scope.allCoupons[couponScope] === null) {
        getCoupons(couponScope).then(
          () => ($scope.pagination.maxPage = $scope.allMaxPage[couponScope]),
        );
      } else {
        $scope.pagination.maxPage = $scope.allMaxPage[couponScope];
      }
    };
    $scope.setCouponScope($scope.state.couponScope);

    $scope.getCurrentCoupons = () => {
      return (
        $scope.allCoupons[$scope.state.couponScope]?.slice(
          0,
          $scope.pagination.page * PAGE_SIZE,
        ) ?? []
      );
    };

    $scope.loadMore = function () {
      $scope.pagination.page++;
    };

    $scope.platformCouponTagTypeI18nMap = {
      [COUPON_TAG_TYPE.platformOnlineStore]: 'coupon.online_store_only',
      [COUPON_TAG_TYPE.platformRetailStore]: 'coupon.retail_store_only',
      [COUPON_TAG_TYPE.platformSelectedRetailStore]:
        'coupon.selected_retail_store_only',
      [COUPON_TAG_TYPE.platformOnlineAndSelectedRetailStore]:
        'coupon.online_and_selected_retail_store_only',
    };
    $scope.getPlatformCouponTagType = getPlatformCouponTagType;
    $scope.getShouldPlatformTagShowMore = (couponTagType) => {
      return [
        COUPON_TAG_TYPE.platformSelectedRetailStore,
        COUPON_TAG_TYPE.platformOnlineAndSelectedRetailStore,
      ].includes(couponTagType);
    };

    $scope.getGeneralCouponTags = (coupon) => {
      const couponTagTypeTextMap = {
        [COUPON_TAG_TYPE.firstPurchaseOnly]: $filter('translate')(
          'coupon.first_purchase_only',
        ),
        [COUPON_TAG_TYPE.oneUserMaxUseCount]:
          $filter('translate')('coupon.one_time'),
        [COUPON_TAG_TYPE.firstShopper]: $filter('translate')(
          'coupon.first_shoppers',
          { number: coupon.max_use_count },
        ),
        [COUPON_TAG_TYPE.reachLimit]:
          $filter('translate')('coupon.reach_limit'),
        [COUPON_TAG_TYPE.expired]: $filter('translate')('coupon.expired'),
      };

      return getGeneralCouponTagTypes(coupon)
        .filter((couponTagType) => couponTagType !== COUPON_TAG_TYPE.used)
        .map((couponTagType) => couponTagTypeTextMap[couponTagType]);
    };

    $scope.showSelectedRetail = function (coupon, couponTagType) {
      if (!$scope.getShouldPlatformTagShowMore(couponTagType)) {
        return;
      }

      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/users/templates.dialog.coupon_selected_retail_store.html'),
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($modalScope, $uibModalInstance) {
            $modalScope.availableChannels = coupon.available_channels
              .map((channel) => $filter('translateModel')(channel.name))
              .join(', ');
            $modalScope.confirm = function () {
              $uibModalInstance.close();
            };
          },
        ],
      });
    };

    $scope.copyCode = (coupon) => {
      navigator.clipboard.writeText(coupon.codes[0]).then(() => {
        pnotifyService.notify(
          $filter('translate')('coupon_v2.copy_code.success'),
          { customClass: 'success', icon: 'fa fa-check-circle' },
        );
      });
    };

    $scope.handleClaimByCodeSucceed = ({ name, coupon_status }) => {
      const couponScope = coupon_status || COUPON_SCOPE.valid;
      pnotifyService.notify(
        $filter('translate')('coupon_input.claim_succeed', {
          couponName: name,
        }),
        { customClass: 'success', icon: 'fa fa-check-circle' },
        () => {
          window.location.href = '/coupons';
        },
      );
      getCoupons(couponScope).then(() => $scope.setCouponScope(couponScope));
    };

    onTabActive($element, () => {
      history.replaceState(null, null, `coupons${location.search}`);
    });
  },
]);
