import './services.checkout.js.coffee'
import './services.merchant'
import './multi_checkout/services.multi-checkout'

import regex from '../constants/regex';

app.directive("checkoutInvoiceForm", [
  '$compile'
  '$http'
  '$filter'
  'checkoutService'
  'merchantService'
  'mainConfig'
  'staticResourceHost'
  'multiCheckoutService'
  'repayInvoiceInfo'
  (
    $compile
    $http
    $filter
    checkoutService
    merchantService
    mainConfig
    staticResourceHost
    multiCheckoutService
    repayInvoiceInfo
  ) ->
    {
      restrict: 'A'
      scope: {
        isLoading: '='
        countries: '='
        isStorePickup: '='
      }
      compile: (tElement) ->
        tElementInAngularFormat = angular.element(tElement.html())
        {
          post: (scope, element) ->
            getInitInvoiceAddressType = () ->
              if repayInvoiceInfo.invoiceAddressType
                return repayInvoiceInfo.invoiceAddressType
              if scope.isStorePickup || multiCheckoutService.isEnabled()
                if mainConfig.currentUser && mainConfig.currentUser.delivery_addresses.length
                then return 'select-from-saved'
                else return 'new'
              return 'same-as-order'

            setDefaultSavedAddress = () ->
              if scope.state.invoiceAddressType == 'select-from-saved' && !repayInvoiceInfo.invoiceMailingAddress && scope.savedAddress
                scope.state.mailingAddress = scope.savedAddress[0]

            element.html($compile(tElementInAngularFormat)(scope))
            scope.regex = regex;
            scope.districtData = {}
            scope.state = Object.assign(
              {},
              {
                address: repayInvoiceInfo.repayMailingAddress
                country: repayInvoiceInfo.repayMailingCountry,
                district: repayInvoiceInfo.invoiceDistrict,
                invoiceAddressType: getInitInvoiceAddressType(),
                mailingAddress: repayInvoiceInfo.invoiceMailingAddress,
                region: repayInvoiceInfo.invoiceRegion,
              }
            )

            unwatch = scope.$watch('countries.length', (length) ->
              if length
                unwatch()
                scope.state.country = _.findWhere(scope.countries, { code: scope.state.country.code })

                if mainConfig.currentUser
                  scope.savedAddress = mainConfig.currentUser.delivery_addresses.map((addr) ->
                    return _.findWhere(scope.countries, { code: addr.country }).name +
                      (addr.postcode || '') +
                      (addr.state || '') +
                      (addr.city || '') +
                      (addr.address_2 || '') +
                      (addr.address_1 || '')
                  )
                  setDefaultSavedAddress()
            )

            scope.$watch('state.country.code', (newCountryCode, oldCountryCode) ->
              if newCountryCode != oldCountryCode
                scope.state.region = undefined
                scope.state.district = undefined
                scope.state.address = undefined

              if ['TW', 'HK'].indexOf(newCountryCode) != -1 && !scope.districtData[newCountryCode]
                url = staticResourceHost + 'web/v1/translations/districts_' + newCountryCode.toLowerCase() + '.json';
                $.getJSON(url, (data) ->
                  scope.districtData[newCountryCode] = {
                    region: [],
                    district: []
                  }

                  data.forEach((item) ->
                    scope.districtData[newCountryCode][item.type].push(item.data)
                  )

                  if scope.state.region
                    scope.state.region = scope.districtData[newCountryCode].region.find((obj) ->
                      obj.key == scope.state.region.key
                    )
                  if scope.state.district
                    scope.state.district = scope.districtData[newCountryCode].district.find((obj) ->
                      # postcode not unique
                      obj.en == scope.state.district.en
                    )
                  scope.$digest();
                )
            )

            scope.$watch('state.region', (newObj, oldObj) ->
              return if !newObj || !oldObj

              if newObj.key != oldObj.key
                scope.state.district = undefined
                scope.state.address = undefined
            )

            scope.$watch('state.invoiceAddressType', (newType, oldType) ->
              setDefaultSavedAddress()
            )

            scope.buildNewAddress = (state) ->
              country = _.findWhere(scope.countries, { code: state.country.code })
              return if !country

              if ['TW', 'HK'].indexOf(state.country.code) != -1
                country.name +
                  state.district.postcode +
                  $filter('translateModel')(state.region) +
                  $filter('translateModel')(state.district).split(' ')[1] +
                  state.address
              else
                country.name + state.address

            scope.calculateMailingAddressMaxLength = () ->
              return 100 - (scope.state.country.name +
                $filter('translateModel')(scope.state.region) +
                $filter('translateModel')(scope.state.district)
              ).length;

            reloadForm = () ->
              return if scope.isLoading

              scope.isLoading = true
              $ ".invoice-form .loading-icon"
                .show()
              $ ".invoice-form"
                .find "input,select"
                .prop 'disabled', true
              formData = _.object(
                _.map $(".invoice-form").find("input,select"), (field) ->
                  [_.last(field.name.match /([a-zA-Z0-9\_\-]+)/g), field.value]
              )

              checkoutService.requestPartial("checkout", "invoice_form", formData)
                .then (res) ->
                  element.html($compile(res.data)(scope))
                  $ ".invoice-form"
                    .find "input,select"
                    .prop 'disabled', false
                  $(".invoice-form .loading-icon").hide()
                  scope.isLoading = false
                .catch (error) ->
                  # console.error("Unable to load invoice form")
                  # scope.isLoading = false
            element.on "change", "select#invoice-type, select#carrier-type", ((event) -> reloadForm())
            element.on "destroy", (() -> element.off()) # Unbind events
        }
    }
])
