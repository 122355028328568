import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('dynamicDeliveryFeeInfo', [
  '$filter',
  '$rootScope',
  '$timeout',
  function ($filter, $rootScope, $timeout) {
    return {
      restrict: 'E',
      template:
        '<div class="dynamic-delivery-hint" ng-if="showDynamicDeliveryFeeInfo" ng-cloak>' +
        '{{infoText}}' +
        '</div>',
      scope: {},
      link: function (scope) {
        var generateDefaultText = function () {
          scope.infoText = $filter('translate')(
            'dynamic.delivery.fee.no.state.info',
          );
        };

        $rootScope.$on('delivery.fee.isUpdated', function () {
          $timeout(function () {
            scope.infoText = $filter('translate')(
              'dynamic.delivery.fee.calculate.info',
              {
                totalDeliveryFee: $('#delivery-fee-label').val(),
              },
            );
            scope.showDynamicDeliveryFeeInfo = true;
          }, 100);
        });

        $rootScope.$on(
          CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE,
          function () {
            generateDefaultText();
            scope.showDynamicDeliveryFeeInfo = true;
          },
        );

        $rootScope.$on('delivery.fee.isReady', function () {
          scope.showDynamicDeliveryFeeInfo = false;
        });

        scope.showDynamicDeliveryFeeInfo = true;
        generateDefaultText();
      },
    };
  },
]);
