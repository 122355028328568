import { parseUserSetting } from '../../utils/userSetting';

app.service('settingService', [
  '$http',
  'mainConfig',
  function ($http, mainConfig) {
    const getUserSetting = () =>
      $http({
        method: 'GET',
        url: `/api/merchants/${mainConfig.merchantId}/user_setting`,
      }).then((res) => parseUserSetting(res.data ?? {}));

    const getUserSettingFromMainConfig = () =>
      parseUserSetting(mainConfig.merchantData?.user_setting?.data ?? {});

    return { getUserSetting, getUserSettingFromMainConfig };
  },
]);
