// @Todo: should remove after checkout/order page revamp completed
app.directive('scmBreadcrumbs', [
  function () {
    const rightDoubledOutlinedIcon = (color) => `
      <svg
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill=${color}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8574 17.4862C12.6379 17.4862 12.4185 17.4022 12.2514 17.235C11.9162 16.8999 11.9162 16.3581 12.2514 16.023L15.9312 12.3432L12.2514 8.66335C11.9162 8.3282 11.9162 7.78647 12.2514 7.45132C12.5865 7.11616 13.1282 7.11616 13.4634 7.45132L17.7492 11.7372C18.0844 12.0723 18.0844 12.614 17.7492 12.9492L13.4634 17.235C13.2963 17.4022 13.0768 17.4862 12.8574 17.4862Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.05733 17.4862C7.8379 17.4862 7.61846 17.4022 7.45132 17.235C7.11616 16.8999 7.11616 16.3581 7.45132 16.023L11.1311 12.3432L7.45132 8.66335C7.11616 8.3282 7.11616 7.78647 7.45132 7.45132C7.78647 7.11616 8.3282 7.11616 8.66335 7.45132L12.9492 11.7372C13.2843 12.0723 13.2843 12.614 12.9492 12.9492L8.66335 17.235C8.4962 17.4022 8.27677 17.4862 8.05733 17.4862Z"
        />
        <mask
          id="mask0_2047_9363"
          // style="mask-type:luminance"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="7"
          y="7"
          width="7"
          height="11"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.05733 17.4862C7.8379 17.4862 7.61846 17.4022 7.45132 17.235C7.11616 16.8999 7.11616 16.3581 7.45132 16.023L11.1311 12.3432L7.45132 8.66335C7.11616 8.3282 7.11616 7.78647 7.45132 7.45132C7.78647 7.11616 8.3282 7.11616 8.66335 7.45132L12.9492 11.7372C13.2843 12.0723 13.2843 12.614 12.9492 12.9492L8.66335 17.235C8.4962 17.4022 8.27677 17.4862 8.05733 17.4862Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2047_9363)" />
      </svg>`;

    const componentTemplate = `
      <div class="scm-breadcrumb-container">
        <a ng-if="url" class="visited" ng-href="{{ url }}">
        <span ng-class="{ 'active': getActiveIndex(0) }">{{ step1 }}</span>
        </a>
        <span ng-class="{ 'active': getActiveIndex(0) }" ng-if="!url">{{ step1 }}</span>
        <span class="scm-breadcrumb__icon" ng-bind-html="rightDoubledOutlinedIcon(1)"></span>
        <span ng-class="{ 'active': getActiveIndex(1) }">{{ step2 }}</span>
        <span class="scm-breadcrumb__icon" ng-bind-html="rightDoubledOutlinedIcon(2)"></span>
        <span ng-class="{ 'active': getActiveIndex(2) }">{{ step3 }}</span>
      <div>
  `;

    return {
      restrict: 'E',
      replace: true,
      template: componentTemplate,
      scope: {
        activeIndex: '=',
        url: '@',
        step1: '@',
        step2: '@',
        step3: '@',
      },
      link: function (scope) {
        scope.getActiveIndex = (stepIndex) => scope.activeIndex >= stepIndex;
        scope.rightDoubledOutlinedIcon = (stepIndex) => {
          const color = scope.activeIndex >= stepIndex ? '#474F5E' : '#B8BECC';
          return rightDoubledOutlinedIcon(color);
        };
      },
    };
  },
]);
