export const TCAT_SEVEN_ELEVEN_EMAP_ONLY_REGION_TYPE = {
  tw_tcat_711pickup_roomtemp_emap_only: 'tw_tcat_711pickup_roomtemp_emap_only',
  tw_tcat_711pickup_refrigerated_emap_only:
    'tw_tcat_711pickup_refrigerated_emap_only',
  tw_tcat_711pickup_frozen_emap_only: 'tw_tcat_711pickup_frozen_emap_only',
};

export const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPE = {
  sl_logistics_tcat_cat_store_roomtemp_a:
    'sl_logistics_tcat_cat_store_roomtemp_a',
  sl_logistics_tcat_cat_store_roomtemp_b:
    'sl_logistics_tcat_cat_store_roomtemp_b',
  sl_logistics_tcat_cat_store_roomtemp_c:
    'sl_logistics_tcat_cat_store_roomtemp_c',
  sl_logistics_tcat_cat_store_roomtemp: 'sl_logistics_tcat_cat_store_roomtemp',
};

export const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPE = {
  sl_logistics_tcat_cat_store_refrigerated_a:
    'sl_logistics_tcat_cat_store_refrigerated_a',
  sl_logistics_tcat_cat_store_refrigerated_b:
    'sl_logistics_tcat_cat_store_refrigerated_b',
  sl_logistics_tcat_cat_store_refrigerated_c:
    'sl_logistics_tcat_cat_store_refrigerated_c',
  sl_logistics_tcat_cat_store_refrigerated:
    'sl_logistics_tcat_cat_store_refrigerated',
};

export const SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPE = {
  sl_logistics_tcat_cat_store_frozen_a: 'sl_logistics_tcat_cat_store_frozen_a',
  sl_logistics_tcat_cat_store_frozen_b: 'sl_logistics_tcat_cat_store_frozen_b',
  sl_logistics_tcat_cat_store_frozen_c: 'sl_logistics_tcat_cat_store_frozen_c',
  sl_logistics_tcat_cat_store_frozen: 'sl_logistics_tcat_cat_store_frozen',
};

export const SL_LOGISTICS_TCAT_CAT_STORE_REGION_TYPE = {
  ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_ROOMTEMP_TYPE,
  ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_REFRIGERATED_TYPE,
  ...SL_LOGISTICS_TCAT_SEVEN_ELEVEN_FROZEN_TYPE,
};

export const FAMILY_MART_TYPE = {
  tw_fm_b2c_nopay: 'tw_fm_b2c_nopay',
  tw_fm_b2c_pay: 'tw_fm_b2c_pay',
  tw_fm_c2c_nopay: 'tw_fm_c2c_nopay',
  tw_fm_c2c_pay: 'tw_fm_c2c_pay',
  emap_only_fm: 'emap_only_fm',
};

export const FAMILY_MARK_FREEZE_TYPE = {
  sl_logistics_fmt_freeze_pay: 'sl_logistics_fmt_freeze_pay',
  sl_logistics_fmt_freeze_nopay: 'sl_logistics_fmt_freeze_nopay',
};

export const SEVEN_ELEVEN_TYPE = {
  tw_711_pay: 'tw_711_pay',
  tw_711_nopay: 'tw_711_nopay',
  tw_711_b2c_pay: 'tw_711_b2c_pay',
  tw_711_b2c_nopay: 'tw_711_b2c_nopay',
  emap_only_711: 'emap_only_711',
};

export const EZ_SHIP_TYPE = {
  tw_ezship: 'tw_ezship',
};

export const TW_TCAT_HOME_DELIVERY_TYPE = {
  roomtemp: 'tw_tcat_roomtemp',
  roomtemp_cod: 'tw_tcat_roomtemp_cod',
  refrigerated: 'tw_tcat_refrigerated',
  refrigerated_cod: 'tw_tcat_refrigerated_cod',
  frozen: 'tw_tcat_frozen',
  frozen_cod: 'tw_tcat_frozen_cod',
};

// @TODO: currently not including all region_types. to be complemented.
export const DELIVERY_REGION_TYPE = {
  ...TCAT_SEVEN_ELEVEN_EMAP_ONLY_REGION_TYPE,
  ...SL_LOGISTICS_TCAT_CAT_STORE_REGION_TYPE,
  ...FAMILY_MART_TYPE,
  ...FAMILY_MARK_FREEZE_TYPE,
  ...SEVEN_ELEVEN_TYPE,
};
