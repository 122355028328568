app.directive('formEmailInput', [
  '$filter',
  function ($filter) {
    return {
      restrict: 'E',
      scope: {
        itemId: '@',
        label: '@',
        isRequired: '@',
      },
      require: ['^form'],
      templateUrl: require('../../../../../public/themes/v1/default/views/form.email-input.html'),
      link: function ($scope, element, attrs, ctrls) {
        var formController = ctrls[0];
        $scope.email = undefined;

        $scope.$watch(function () {
          return validate();
        });

        $scope.$watchGroup(
          ['hasError', 'error.email', 'error.required'],
          function () {
            if ($scope.error.email === true) {
              $scope.errorMessage = $filter('translate')(
                'form.validation.email.hint',
                { field_name: $scope.label },
              );
              return;
            }

            if ($scope.error.required === true) {
              $scope.errorMessage = $filter('translate')(
                'form.validation.required',
                { field_name: $scope.label },
              );
              return;
            }
          },
        );

        var validate = function () {
          var fieldObject = formController[$scope.itemId];
          if (fieldObject == undefined) return;

          if (formController.$submitted || formController.submitted) {
            $scope.hasError = fieldObject.$invalid;
          } else {
            $scope.hasError = false;
          }
          $scope.error = fieldObject.$error;
        };
      },
    };
  },
]);
