app.directive('enlargeImage', [
  '$uibModal',
  function ($uibModal) {
    return {
      restrict: 'A',
      scope: {
        ngSrc: '@',
      },
      link: function (scope, element) {
        if (scope.ngSrc) {
          element.on('click', function () {
            var newScope = scope.$new();
            newScope.imageSrc = scope.ngSrc;
            $uibModal.open({
              scope: newScope,
              windowClass: 'enlargeImageModal',
              template: '<img ng-src="{{ imageSrc }}" />',
            });
          });
        }
      },
    };
  },
]);
