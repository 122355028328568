import './services.benchat';
app.controller('LineQuickSignupController', [
  '$scope',
  'benchatService',
  function ($scope, benchatService) {
    $scope.init = function (channel) {
      init(channel);
    };

    var init = function (channel) {
      window.location.href = benchatService.getLineQuickSignupUri(
        channel.platformChannelId,
      );
    };
  },
]);
