import './services.sl-payment';
import { isSupportPaymentMethod } from '@payment/payment-sdk';
import template from '../../../../../public/themes/shared/checkout/templates.shopline_payment_v2_google_pay_button.html';

app.directive('shoplinePaymentV2GooglePayButton', [
  'cart',
  'slPaymentService',
  'staticResourceHost',
  function (cart, slPaymentService, staticResourceHost) {
    return {
      restrict: 'E',
      templateUrl: template,
      scope: {
        onCheckout: '&',
        loading: '=',
      },
      link: function (scope) {
        scope.showGooglePayButton = false;
        scope.staticResourceHost = staticResourceHost;

        scope.onGooglePayButtonClicked = function () {
          scope.onCheckout();
        };

        const initButton = () => {
          const paymentMethod =
            slPaymentService.paymentMethodMap[cart.payment_method];

          isSupportPaymentMethod(paymentMethod).then((usable) => {
            if (!usable) {
              scope.$emit('checkout.payments.google_pay.not_supported');
            } else {
              scope.showGooglePayButton = true;
            }
          });
        };

        initButton();
      },
    };
  },
]);
