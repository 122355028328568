import dayjs from 'dayjs';
import {
  COUPON_TAG_TYPE,
  AVAILABLE_PLATFORM,
  USER_COUPON_STATUS,
} from '../shop/constants/promotion';

export const getPlatformCouponTagType = ({
  available_platforms,
  available_channel_ids,
}) => {
  if (
    available_platforms.length === 1 &&
    available_platforms[0] === AVAILABLE_PLATFORM.ec
  ) {
    return COUPON_TAG_TYPE.platformOnlineStore;
  }
  if (
    available_platforms.length === 1 &&
    available_platforms[0] === AVAILABLE_PLATFORM.retail &&
    !available_channel_ids?.length
  ) {
    return COUPON_TAG_TYPE.platformRetailStore;
  }
  if (
    available_platforms.length === 1 &&
    available_platforms[0] === AVAILABLE_PLATFORM.retail &&
    available_channel_ids?.length
  ) {
    return COUPON_TAG_TYPE.platformSelectedRetailStore;
  }
  if (available_platforms.length === 2 && available_channel_ids?.length) {
    return COUPON_TAG_TYPE.platformOnlineAndSelectedRetailStore;
  }

  return null;
};

export const getGeneralCouponTagTypes = (coupon) => {
  return [
    ...(coupon.first_purchase_only ? [COUPON_TAG_TYPE.firstPurchaseOnly] : []),
    ...(coupon.user_max_use_count === 1
      ? [COUPON_TAG_TYPE.oneUserMaxUseCount]
      : []),
    ...(coupon.max_use_count ? [COUPON_TAG_TYPE.firstShopper] : []),
    ...(coupon.max_use_count === coupon.use_count
      ? [COUPON_TAG_TYPE.reachLimit]
      : []),
    ...(dayjs(coupon.end_at).isBefore(dayjs())
      ? [COUPON_TAG_TYPE.expired]
      : []),
    ...(coupon.user_coupon_status === USER_COUPON_STATUS.used
      ? [COUPON_TAG_TYPE.used]
      : []),
  ];
};
