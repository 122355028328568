app.service('cancelRequestService', [
  '$q',
  function ($q) {
    const MANUAL_ABORT_XHR_STATUS = 'abort';
    const cancelPromiseMap = {};
    const createCancelPromise = (key) => {
      const canceller = $q.defer();
      cancelPromiseMap[key] = {
        cancel: () => canceller.resolve(),
      };
      return canceller.promise;
    };
    const cancelRequest = (key) => {
      cancelPromiseMap[key]?.cancel();
      delete cancelPromiseMap[key];
    };

    return {
      MANUAL_ABORT_XHR_STATUS,
      createCancelPromise,
      cancelRequest,
    };
  },
]);
