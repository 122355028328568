app.controller('MemberCenterController', [
  '$scope',
  '$rootScope',
  '$timeout',
  function ($scope, $rootScope, $timeout) {
    $scope.scrollToTop = function () {
      if ($(window).width() < 768) {
        $rootScope.scrollTo();
      }
    };
    $timeout(function () {
      var memberCenterNavTabs = angular
        .element('.member-content-center')
        .find('.nav-tabs');
      var tabsetArrowLeft = angular.element('.tabset-arrow-left');
      var tabsetArrowRight = angular.element('.tabset-arrow-right');
      var maxScrollWidth =
        memberCenterNavTabs[0].scrollWidth - memberCenterNavTabs.width();

      if (maxScrollWidth) {
        memberCenterNavTabs.on('scroll', function () {
          var scrollLeft = $(this).scrollLeft();
          if (scrollLeft <= 10) {
            tabsetArrowLeft.hide();
            tabsetArrowRight.show();
          } else if (scrollLeft >= maxScrollWidth - 10) {
            tabsetArrowLeft.show();
            tabsetArrowRight.hide();
          } else {
            tabsetArrowLeft.hide();
            tabsetArrowRight.hide();
          }
        });
      } else {
        tabsetArrowLeft.hide();
        tabsetArrowRight.hide();
      }
    }, 0);
  },
]);
