app.directive('applePayWithNewebPayButton', [
  '$filter',
  'mainConfig',
  'cart',
  '$http',
  function ($filter, mainConfig, cart, $http) {
    return {
      restrict: 'E',
      scope: '=',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.apple_pay_with_newebpay_button.html'),
      link: function (scope) {
        scope.applePayLadda = Ladda.create(
          angular.element('.apple-pay-button')[0],
        );

        const buildLineItems = function () {
          return [
            {
              type: 'final',
              label: $filter('translate')('orders.fields.subtotal'),
              amount: cart.subtotal.dollars,
            },
            {
              type: 'final',
              label: $filter('translate')('orders.fields.delivery_fee'),
              amount: cart.delivery_fee.dollars,
            },
            {
              type: 'final',
              label: $filter('translate')('orders.fields.payment_fee'),
              amount: cart.payment_fee.dollars,
            },
            {
              type: 'final',
              label: $filter('translate')('orders.fields.order_discount'),
              amount: cart.discount.dollars,
            },
          ].filter((item) => item.amount);
        };

        const scrollToInvalidField = function () {
          return angular.element('html, body').animate(
            {
              scrollTop:
                angular
                  .element(
                    '.form-group.has-error,.form-group-address.has-error',
                  )
                  .first()
                  .offset().top - 100,
            },
            'slow',
          );
        };

        scope.onApplePayButtonClicked = function () {
          if (!window.ApplePaySession) return;
          setTimeout(function () {
            // workaround for calling $digest in validateForm()
            const isValidForm = scope.validateForm();

            if (!isValidForm) {
              return scrollToInvalidField();
            }
            scope.applePayLadda.start();
            // Define ApplePayPaymentRequest
            const request = {
              merchantCapabilities: ['supports3DS', 'supportsEMV'],
              countryCode: mainConfig.merchantData.base_country_code,
              currencyCode: mainConfig.merchantData.base_currency_code,
              supportedNetworks: [
                'visa',
                'masterCard',
                'privateLabel',
                'jcb',
                'interac',
                'discover',
                'chinaUnionPay',
              ],
              total: {
                label: mainConfig.merchantData.name,
                type: 'final',
                amount: cart.total.dollars,
              },
              lineItems: buildLineItems(),
            };

            // Create ApplePaySession
            const session = new ApplePaySession(2, request);

            const validateMerchant = function () {
              const endpoint = 'api/v1/apple_pay/payment_session';
              return $http({
                method: 'POST',
                url: endpoint,
              }).then(function (res) {
                return res.data;
              });
            };

            session.onvalidatemerchant = async () => {
              const merchantSession = await validateMerchant();
              session.completeMerchantValidation(
                JSON.parse(merchantSession.data),
              );
            };

            session.onpaymentmethodselected = () => {
              // Define ApplePayPaymentMethodUpdate based on the selected payment method.
              // No updates or errors are needed, pass an empty object.
              const update = {
                newTotal: {
                  label: mainConfig.merchantData.name,
                  type: 'final',
                  amount: cart.total.dollars,
                },
              };

              session.completePaymentMethodSelection(update);
            };

            session.onshippingmethodselected = () => {
              // Define ApplePayShippingMethodUpdate based on the selected shipping method.
              // No updates or errors are needed, pass an empty object.
              const update = {};
              session.completeShippingMethodSelection(update);
            };

            session.onshippingcontactselected = () => {
              // Define ApplePayShippingContactUpdate based on the selected shipping contact.
              const update = {};
              session.completeShippingContactSelection(update);
            };
            session.onpaymentauthorized = (event) => {
              const token = event.payment.token.paymentData;
              scope.handleApplePayWithNewebpay(token);

              const result = {
                status: ApplePaySession.STATUS_SUCCESS,
              };

              session.completePayment(result);
            };
            session.oncancel = () => {
              scope.applePayLadda.stop();
            };
            session.begin();
          });
        };
      },
    };
  },
]);
