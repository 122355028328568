import './services.benchat';
import { isString } from 'lodash-es';
import { PLATFORM } from '../../constants/benchat';

app.directive('whatsappMessengerCheckbox', [
  '$q',
  'mainConfig',
  'benchatService',
  function ($q, mainConfig, benchatService) {
    return {
      restrict: 'E',
      template: `
      <div class="messenger-checkbox">
        <input
          type="hidden"
          name="benchatFields[subscriptions][whatsapp]"
          value="{{ benchatSubscriptions }}"
        />
        <input
          type="checkbox"
          ng-model="isChecked"
          ng-disabled="isLoading || !phone"
          ng-change="onCheckboxChange()"
        />
        <span class="message">
          {{ 'whatsapp_messenger_checkbox.subscribe' | translate }}
        </span>
      </div>
      `,
      scope: {
        phone: '<?',
        customerId: '@',
        topicStr: '@topics',
      },
      link: function ($scope) {
        const { customerId, topicStr = '' } = $scope;
        const topics = isString(topicStr)
          ? topicStr.split(',').map((s) => s.replace(/\s/g, ''))
          : [];
        $scope.benchatSubscriptions = [];
        $scope.isChecked = false;
        $scope.isLoading = true;

        const getSubscriptions = () =>
          benchatService
            .getSubscriptions(customerId, topics, PLATFORM.WHATSAPP)
            .then((result) => {
              const subscriptions = result.data?.subscriptions ?? [];
              $scope.benchatSubscriptions = subscriptions.filter(
                (subscription) => subscription.isActive,
              );

              $scope.isChecked =
                $scope.benchatSubscriptions.length === topics.length;
              $scope.isLoading = false;
            });
        getSubscriptions();

        const deactivateSubscriptions = () =>
          $q.all(
            $scope.benchatSubscriptions.map((subscription) =>
              benchatService.deactivateSubscription(subscription._id),
            ),
          );

        const activateSubscriptions = () =>
          benchatService
            .activateSubscriptionRevamp(PLATFORM.WHATSAPP.toLowerCase())
            .catch((error) => {
              // TODO: handle error
              console.error(error);
            });

        $scope.onCheckboxChange = () => {
          $scope.isLoading = true;
          const toggleSubscriptions = $scope.isChecked
            ? activateSubscriptions
            : deactivateSubscriptions;
          toggleSubscriptions().then(getSubscriptions);
        };
      },
    };
  },
]);
