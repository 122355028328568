import {
  COLLAPSE_STATUS,
  BLOCK_STATUS,
} from '../../constants/components/collapse-block-status';
import { CHECKOUT_EVENTS } from '../../constants/angular-events';

app.directive('collapseBlock', [
  '$timeout',
  ($timeout) => {
    return {
      restrict: 'E',
      scope: {
        blockId: '@?',
        collapseStatus: '@?',
        blockStatus: '@?',
        onChange: '&?',
      },
      transclude: {
        header: '?collapseHeader',
        body: 'collapseBody',
      },
      templateUrl: require('../../../../../../public/themes/shared/components/templates.collapse-block.html'),
      link: ($scope, element) => {
        $scope.inited = false;
        $scope.blockId = $scope.blockId || _.uniqueId('collapse-block-');
        $scope.blockStatus = $scope.blockStatus || BLOCK_STATUS.DEFAULT;
        $scope.collapseStatus =
          $scope.collapseStatus || COLLAPSE_STATUS.EXPANDED;
        $scope.contentHeight =
          $scope.collapseStatus === COLLAPSE_STATUS.COLLAPSED ? 0 : 'none';

        const bodyWrapper = element.find('.collapse-block__body')[0];
        const bodyContent = element.find('[ng-transclude="body"]')[0];

        $scope.showCollapse = () =>
          $scope.collapseStatus === COLLAPSE_STATUS.EXPANDED;

        $scope.showError = () => $scope.blockStatus === BLOCK_STATUS.ERROR;

        $scope.toggleCollapse = ($event) => {
          $scope.collapseStatus = $scope.showCollapse()
            ? COLLAPSE_STATUS.COLLAPSED
            : COLLAPSE_STATUS.EXPANDED;

          const value = {
            blockId: $scope.blockId,
            collapseStatus: $scope.collapseStatus,
            event: $event,
          };

          $scope?.onChange?.({ value });
        };

        $scope.$watch('collapseStatus', (newValue, oldValue) => {
          if (newValue === oldValue) {
            return;
          }

          const { width, height } = bodyContent.getBoundingClientRect();

          // block is hidden by parent element
          if (width === 0 && height === 0) {
            return;
          }

          // set contentHeight to expand size before transition
          if (!$scope.showCollapse()) {
            $scope.contentHeight = `${height}px`;
          }

          $timeout(() => {
            $scope.contentHeight = $scope.showCollapse()
              ? `${bodyContent.getBoundingClientRect().height}px`
              : 0;
          });
        });

        bodyWrapper.addEventListener('transitionend', () => {
          if ($scope.showCollapse()) {
            $scope.contentHeight = 'none';
            $scope.$digest();
          }
        });

        $scope.$on(
          CHECKOUT_EVENTS.CART.COLLAPSE_BLOCK.TOGGLE_STATUS,
          (_event, payload) => {
            if ($scope.blockId !== payload.blockId) {
              return;
            }

            $scope.blockStatus = payload?.blockStatus || $scope.blockStatus;
            $scope.collapseStatus =
              payload?.collapseStatus || $scope.collapseStatus;
          },
        );

        $timeout(() => ($scope.inited = true));
      },
    };
  },
]);
